import styled from 'vue3-styled-components';

const mainFooterStyled = styled('div')`
  margin-bottom: 128px;
  margin-top: 32px;
  .copy_right_text {
    text-align: center;
    color: #d3d5dd;
    font-family: Spoqa Han Sans Neo;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.3px;
  }
`;

export default mainFooterStyled;
