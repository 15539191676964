import axios from '@/axios';

const getAlarmList = param => {
  return axios.get('/alarm/list', param);
};
const deleteAlarmList = () => {
  return axios.get('/alarm/delete');
};

const getAlarmCount = () => {
  return axios.get('/alarm/count');
};
export { getAlarmList, deleteAlarmList, getAlarmCount };
