import axios from '@/axios';

export default {
  //미팅 신고
  meetingPostDeclaration(param) {
    return axios.post('/community/meeting/report', param);
  },
  //미팅 차단
  meetingPostBlock(param) {
    return axios.post('/community/meeting/block', param);
  },
  //미팅 채팅 요청
  requestAChatMeeting(param) {
    return axios.post('/meeting/target/request', param);
  },
  //미팅 채팅 요청 상세 보기
  requestMeetingContent(param) {
    return axios.post('/meeting/view', param);
  },

  //미팅  커뮤 검색
  meetingPrivateCommunitySearch(param) {
    return axios.post('/community/private/user/list', param);
  },
  meetingOpenCommunitySearch(param) {
    return axios.post('/community/open/user/list', param);
  },

  //미팅 수정
  editMeetingCommunity(param) {
    return axios.post('/community/post/meeting/modify', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },
  //미지정 미팅 등록

  requestForUnspecifiedMeeting(param) {
    return axios.post('/meeting/untarget/request', param);
  },
  //미팅 삭제
  deleteMeetingCommunity(param) {
    return axios.post('/community/post/meeting/delete', param);
  },

  // 해외 국가 조회
  loadOverseas() {
    return axios.get('/community/country');
  },
  //해외 도시 조회
  loadOverseasDetails(param) {
    return axios.post('/community/country/city', param);
  },
  //국내 도시 조회
  loadDomestic() {
    return axios.get('/community/domestic/city');
  },
  //국내 군구 조회
  loadDomesticCitys(param) {
    return axios.post('/community/domestic/district', param);
  },

  //미팅 추가
  meetingAdd(param) {
    return axios.post('/community/post/meeting/upload', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },

  // 커뮤니티 글 등록
  addCommunity(param) {
    return axios.post('/community/post/upload', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },
  // 커뮤니티 글 삭제
  deleteCommunity(param) {
    return axios.post('/community/post/delete', param);
  },
  // 커뮤니티 글 수정
  editCommunity(param) {
    return axios.post('/community/post/modify', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },

  //커뮤니티 게시물 차단
  postBlock(param) {
    return axios.post('/community/block', param);
  },

  //커뮤니티 게시물 신고
  privateReportPost(param) {
    return axios.post('/community/report', param);
  },
  //댓글 차단
  privateInterception(param) {
    return axios.post('/community/comment/block', param);
  },

  //댓글 신고
  privateReportComment(param) {
    return axios.post('/community/comment/report', param);
  },
  communityListApi() {
    return axios.get('/community/open/external');
  },
  communityAddListApi() {
    return axios.get('/community/open/internal');
  },

  //나의 커뮤니티 리스트
  myCommunityList(param) {
    return axios.get('/community/user', { params: param });
  },

  //메인 커뮤니티 리스트
  mainCommunityList(param) {
    return axios.post('/community/all/list', param);
  },
  voteRequest(param) {
    return axios.post('/community/vote', param);
  },

  // 커뮤니티 리스트
  openCommunityReference(param) {
    return axios.post('/community/open/list', param);
  },
  privateCommunityReference(param) {
    return axios.post('/community/private/list', param);
  },

  // 커뮤니티 글 상세
  openCommunityDetail(param) {
    return axios.post('/community/open/detail', param);
  },
  privateCommunityDetail(param) {
    return axios.post('/community/private/detail', param);
  },

  // 커뮤니티 글 등록
  addCommentRequest(param) {
    return axios.post('/community/open/comment/upload', param);
  },
  addCommentPrivateRequest(param) {
    return axios.post('/community/private/comment/upload', param);
  },

  //댓글 좋아요
  commentLikeRequest(param) {
    return axios.post('/community/comment/vote', param);
  },

  //댓글 수정
  commentEdit(param) {
    return axios.post('/community/private/comment/modify', param);
  },
  commentOpenEdit(param) {
    return axios.post('/community/open/comment/modify', param);
  },

  //댓글 삭제
  privateDelComment(param) {
    return axios.post('/community/private/comment/delete', param);
  },
  openDelComment(param) {
    return axios.post('/community/open/comment/delete', param);
  },

  //오픈 커뮤니티 추가
  openCommJoin(param) {
    return axios.post('/community/open/join', param);
  },
  //커뮤니티 게시물 스크랩
  saveCommPostScrap(param) {
    return axios.post('/community/post/scrap', param);
  },
  //커뮤니티 게시물 스크랩 삭제
  deleteCommPostScrap(param) {
    return axios.post('/community/post/unscrap', param);
  },
};
