import { acceptHMRUpdate, defineStore } from 'pinia';

export const useCalendarStore = defineStore('useCalendar', {
  state: () => {
    return {
      isSelectType: '',
      selectStartData: '',
      selectEndDate: '',
      originStartDate: '',
      originEndData: '',
      selectStartTime: '',
      originStartTime: '',
      selectEndTime: '',
      originEndTime: '',
    };
  },
  actions: {
    setStartTime(data) {
      this.selectStartTime = data;
    },
    setEndTime(data) {
      this.selectEndTime = data;
    },
    setOriginStartTime(data) {
      this.originStartTime = data;
    },
    setOriginEndTime(data) {
      this.originEndTime = data;
    },
    setSelectType(data) {
      this.isSelectType = data;
    },
    setSelectStartData(data) {
      this.selectStartData = data;
    },
    setSelectEndDate(data) {
      this.selectEndDate = data;
    },
    setOriginStartDate(data) {
      this.originStartDate = data;
    },
    setOriginEndDate(data) {
      this.originEndData = data;
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCalendarStore, import.meta.hot));
