import communityApi from '@/api/community/communityApi';

export class CommunityApiRequest {
  // 미팅 게시물 신고
  static async meetingPostDeclaration(param) {
    try {
      const data = await communityApi.meetingPostDeclaration(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  // 미팅 게시물 차단
  static async meetingPostBlockReqeust(param) {
    try {
      const data = await communityApi.meetingPostBlock(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 지정 미팅 신청 [채팅]
  static async chatMeetingAddRequest(param) {
    try {
      const data = await communityApi.requestAChatMeeting(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  // 대상 있음 프라이빗 커뮤니티 회원 조회
  static async meetingPrivateCommunitySearchRequest(param) {
    try {
      const data = await communityApi.meetingPrivateCommunitySearch(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 대상 있음 오픈 커뮤니티 회원 조회
  static async meetingOpenCommunitySearchRequest(param) {
    try {
      const data = await communityApi.meetingOpenCommunitySearch(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  // 미팅 게식물 삭제
  static async deleteMeetingCommunityReuqest(param) {
    try {
      const data = await communityApi.deleteMeetingCommunity(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 미팅 커뮤니티 수정
  static async editMeetingCommunityRequest(param) {
    try {
      const data = await communityApi.editMeetingCommunity(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 미지정 미팅 신청 (게시물 상세에서 미팅신청)
  static async forUnspecifiedMeetingRequest(param) {
    try {
      const data = await communityApi.requestForUnspecifiedMeeting(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  //미틴 게시글 삭제
  static async deletedMeetingCommunityRequest(param) {
    try {
      const data = await communityApi.deleteCommunity(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  static async loadOverseasDetailsRequest(param) {
    try {
      const data = await communityApi.loadOverseasDetails(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  static async loadOverseasRequest() {
    try {
      const data = await communityApi.loadOverseas();
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 미팅 신청
  static async addMeetingRequest(param) {
    try {
      const date = await communityApi.meetingAdd(param);
      return date;
    } catch (err) {
      throw err;
    }
  }

  // 국내 도시 군
  static async loadDomesticCityesRequest(param) {
    try {
      const data = await communityApi.loadDomesticCitys(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  // 국내 도시 조회
  static async loadDomesticRequest() {
    try {
      const data = await communityApi.loadDomestic();
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 해외 도시
  static async loadOverseasDetails(param) {
    try {
      const data = await communityApi.loadOverseasDetails(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  // 해외 국가
  static async loadOverseas() {
    try {
      const data = await communityApi.loadOverseas();
      return data;
    } catch (err) {
      throw err;
    }
  }

  static async deletedCommunityRequest(param) {
    try {
      const data = await communityApi.deleteCommunity(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  static async editCommunityRequest(param) {
    try {
      const data = await communityApi.editCommunity(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  /**
   *  커뮤니티 추가 api
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async addCommunityList() {
    try {
      const data = await communityApi.communityAddListApi();
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async addCommunityRequest(param) {
    try {
      const data = await communityApi.addCommunity(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async postBlockRequest(param) {
    try {
      const data = await communityApi.postBlock(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:8) err', err);
      throw err;
    }
  }

  static async privateReportPostRequest(param) {
    try {
      const data = await communityApi.privateReportPost(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async privateInterceptionRequest(param) {
    try {
      const data = await communityApi.privateInterception(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:8) err', err);
      throw err;
    }
  }

  static async privateReportCommentRequest(param) {
    try {
      const data = await communityApi.privateReportComment(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async openDelCommentRequest(param) {
    try {
      const data = await communityApi.openDelComment(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  static async privateDelCommentRequest(param) {
    try {
      const data = await communityApi.privateDelComment(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async commentEditOpenRequest(param) {
    try {
      const data = await communityApi.commentOpenEdit(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async commentEditRequest(param) {
    try {
      const data = await communityApi.commentEdit(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async commentLikeRequestApi(param) {
    try {
      const data = await communityApi.commentLikeRequest(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:8) err', err);
      throw err;
    }
  }
  static async privateCommentAddRequest(param) {
    try {
      const data = await communityApi.addCommentPrivateRequest(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:8) err', err);
      throw err;
    }
  }
  /**
   *  프라이빗 게시글 상세
   * @param param
   * @returns {Promise<*>}
   */
  static async privateCommunityInof(param) {
    try {
      const data = await communityApi.privateCommunityDetail(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:8) err', err);
      throw err;
    }
  }

  static async addCommentRequestApi(param) {
    try {
      const data = await communityApi.addCommentRequest(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }

  /**
   *     "pc_info_id": 2, // 프라이빗 커뮤니티 고유번호
   *     "offset": 1, // 페이지 수 (1 부터 시작)
   *     "upvote": false, // upvote 여부 (true: 활성화, false: 비활성화)
   *     "meeting": false, // 미팅글 여부 (true: 활성화, false: 비활성화)
   *     "order_by": "all" // 조회 옵션 (전체순: all, 댓글순: comment, 조회순: view)
   * @param param
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async openCommunityReferenceRequest(param) {
    try {
      const data = await communityApi.openCommunityReference(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async privateCommunityReferenceRequest(param) {
    try {
      const data = await communityApi.privateCommunityReference(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async voteRequestApi(param) {
    try {
      const data = await communityApi.voteRequest(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async communityListRequest() {
    try {
      const data = await communityApi.communityListApi();
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:6) err', err);
      throw err;
    }
  }
  static async myCommunityListReuqest(param) {
    try {
      const data = await communityApi.myCommunityList(param);
      console.log('=>(community)', data);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:17) err', err);
      throw err;
    }
  }

  /**
   * 메인 커뮤니티 리스트
   * @param param
   * @offset 0...10
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async mainCommunityListRequest(param) {
    try {
      console.log('=>(communityApiRequest.js:32) param', param);
      const data = await communityApi.mainCommunityList(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:26) err', err);
      throw err;
    }
  }

  static async openCommunityDetailRequest(param) {
    try {
      const data = await communityApi.openCommunityDetail(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:70) err', err);
      throw err;
    }
  }

  //커뮤니티 게시물 스크랩
  static async saveCommPostScrap(param) {
    try {
      const data = await communityApi.saveCommPostScrap(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:70) err', err);
      throw err;
    }
  }
  //커뮤니티 게시물 스크랩 삭제
  static async deleteCommPostScrap(param) {
    try {
      const data = await communityApi.deleteCommPostScrap(param);
      return data;
    } catch (err) {
      console.log('=>(communityApiRequest.js:70) err', err);
      throw err;
    }
  }
}
