import axios from 'axios';

export default {
  getNewRoomList(param) {
    return axios.get(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels?limit=100`, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
  /**
   *
   * @param channel_url
   * @param message_ts
   * @param message_id
   */
  getRoomMessageList(param) {
    axios.get(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${param.channel_url}/messages/total_count`, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
  searchUsers(param) {
    axios.get('https://api-{application_id}.sendbird.com/v3/users?user_ids=128', {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
  // 채널 나가기
  exitGroupChannel(param) {
    const channel_url = param;
    axios.put(
      `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${channel_url}/leave`,
      {},
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      },
    );
  },
  getChannelUpdate(param) {
    const channel_url = param; // ensure that 'param' contains your channel url
    return axios.put(
      `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${channel_url}`,
      {},
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      },
    );
  },
  groupChannelCreated(param) {
    return axios.post('https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels', param, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
  groupChannelInformation(param) {
    return axios.get(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/sdk/group_channels/${param.channelUrl}/show_member=true&show_read_receipt=true&show_delivery_receipt=true`);
  },
  groupChannelLeave(param) {
    return axios.put(
      `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${param.channel_url}/leave`,
      { user_ids: param.user_ids },
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        },
      },
    );
  },
  groupChannelAutoAccept(param) {
    return axios.put(
      'https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users/gusduswk11/channel_invitation_preference',
      { auto_accept: true },
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        },
      },
    );
  },
  groupChannelList(param) {
    return axios.get(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels`, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
      },
    });
  },
  joinChannel(param) {
    return axios.put(
      `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${param.channelUrl}/join`,
      { user_id: param.user_id },
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      },
    );
  },
  channelMemberRequest(param) {
    return axios.get(`GET https://api-{application_id}.sendbird.com/v3/group_channels/${param}/members`);
  },
  updateGroupChannel(param) {
    return axios.put(
      `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${param.url}`,
      {
        is_public: param.isPublic,
        isDistinct: true,
      },
      {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      },
    );
  },
  getMessageRequest(param, data) {
    const queryParams = new URLSearchParams(data).toString();
    return axios.get(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/group_channels/${param.channelUrl}/messages?${queryParams}`);
  },
  createdUser(param) {
    return axios.post('https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users', param, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
  deleteUser(userId) {
    return axios.delete(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users/${userId}`, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },

  sendMessage(param) {
    console.log('=>(sendbirdApiList.js:64) param', param);
    return axios.post(`https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/${param.channel_type}/${param.channel_url}/messages`, param.data, {
      headers: {
        'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
        'Content-Type': 'application/json',
      },
    });
  },
};
