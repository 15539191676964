<script setup>
  import { reactive } from 'vue';
  import { CommunityApiRequest as communityApiRequest, CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { useCommnetListStore } from '@/stores/useCommnetListStore';
  import SharePopup from '@Components/popup/SharePopup.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { useMeta } from 'vue-meta';

  const props = defineProps({
    data: Object,
  });
  const emits = defineEmits(['locationDetail', 'handleSharePopup', 'handleScrapManage']);
  const useComment = useCommnetListStore();
  const usePopup = usePopupStore();
  const state = reactive({
    upVote: false,
    downVote: false,
    communityData: null,
    scrapInfo: null,
    isScrap: false,
    activeIdx: '',
    shareDatas: {},
  });

  console.log('=>(FunctionComponetVue.vue:9) props.data', props.data);

  if (props.data) {
    props.data.scrap_cnt === 1 ? (state.isScrap = true) : (state.isScrap = false);
    props.data.my_up_vote === 1 ? (state.upVote = true) : (state.upVote = false);
    props.data.my_down_vote === 1 ? (state.downVote = true) : (state.downVote = false);
    state.communityData = props.data;
  }

  const handleLocationDetail = () => {
    emits('locationDetail');
  };
  /**
   * handleLikeAndNotFuc 좋아요 싫어요 함수
   * @param type
   *
   * props와 useComment로부터 오는 데이터
   *
   * requestData 객체는 커뮤니티의 타입 (open 또는 private), 커뮤니티의 고유 식별자 (post_info_id), 투표에 대한 두 필드 (up_vote와 down_vote)를 포함
   * 만약 type이 LIKE라면, upVote 필드의 값을 토글링하고, 이 새 값으로 requestData를 업데이트하며 down_vote를 false로 설정함 그 다음,
   * communityType에 따라 post_info_id를 수정함. 만약 이전에 downVote가 등록됐다면 down_vote이 감소.
   * 저장하기 위한 요청을 CommunityApiRequest.voteRequestApi(requestData)로 전송. 요청이 성공하면 downVote의 값을 토글링하고 up_vote의 횟수를 업데이트.
   * 마찬가지로, type이 NOT일 경우엔 싫어요 처리.
   */
  const handleLikeAndNotFuc = type => {
    console.log('=>(FunctionComponetVue.vue:22)props.data ', props.data);
    console.log('=>(FunctionComponetVue.vue:26) useComment.communityInfo', useComment.communityInfo);
    console.log(type);
    console.log(useComment.communityType);
    let requestData = {};
    if (useComment.communityType) {
      requestData = {
        type: useComment.communityType === 'PRIVATE' ? 'private' : 'open', // 오픈: open, 프라이빗: private
        post_info_id: useComment.communityInfo.post_info_id, // 커뮤니티 고유번호
        up_vote: '', // 좋아요 Boolean
        down_vote: '', // 싫어요 Boolean
      };
    } else {
      // 좋아요 싫어요
      requestData = {
        type: props.data.type, // 오픈: open, 프라이빗: private
        post_info_id: props.data.type === 'open' ? props.data.post_info_id : props.data.post_info_id, // 커뮤니티 고유번호
        up_vote: '', // 좋아요 Boolean
        down_vote: '', // 싫어요 Boolean
      };
    }

    if (type === 'LIKE') {
      state.upVote = !state.upVote;
      console.log('=>(FunctionComponetVue.vue:46) state.upVote', state.upVote);
      requestData.up_vote = state.upVote;
      requestData.down_vote = false;
      if (useComment.communityType === 'OPEN') {
        requestData.post_info_id = props.data.hasOwnProperty('post_info_id') ? props.data.post_info_id : props.data.post_info_id;
      } else {
        requestData.post_info_id = props.data.hasOwnProperty('post_info_id') ? props.data.post_info_id : props.data.post_info_id;
      }

      if (state.downVote) {
        state.communityData.down_vote = props.data.down_vote > 0 ? props.data.down_vote - 1 : 0;
      }
      CommunityApiRequest.voteRequestApi(requestData).then(res => {
        if (res.success) {
          state.downVote = false;
          if (state.upVote) {
            state.communityData.up_vote = state.communityData.up_vote + 1;
          } else {
            state.communityData.up_vote = state.communityData.up_vote - 1;
          }
        }
      });
    } else if (type === 'NOT') {
      state.downVote = !state.downVote;
      requestData.up_vote = false;
      if (useComment.communityType === 'OPEN') {
        requestData.post_info_id = props.data.hasOwnProperty('post_info_id') ? props.data.post_info_id : props.data.post_info_id;
      } else {
        requestData.post_info_id = props.data.hasOwnProperty('post_info_id') ? props.data.post_info_id : props.data.post_info_id;
      }
      requestData.down_vote = state.downVote;

      if (state.upVote) {
        state.communityData.up_vote = props.data.up_vote > 0 ? props.data.up_vote - 1 : 0;
      }
      CommunityApiRequest.voteRequestApi(requestData).then(res => {
        console.log('=>(FunctionComponetVue.vue:25) res', res);
        if (res.success) {
          state.upVote = false;
          if (state.downVote) {
            state.communityData.down_vote = state.communityData.down_vote + 1;
          } else {
            state.communityData.down_vote = state.communityData.down_vote - 1;
          }
        }
      });
    }
  };

  //스크랩 처리함수
  const handlePostScrap = (post, scrap) => {
    console.log('스크랩 처리함수');
    console.log('post', post);
    console.log(props.data, 'props.data');
    state.isScrap = !state.isScrap;
    if (state.isScrap) {
      state.communityData.scrap_cnt = props.data.scrap_cnt - 1;
    } else {
      state.communityData.scrap_cnt = props.data.scrap_cnt + 1;
    }
    const param = {
      c_post_info_id: post,
    };
    //스크랩 했을때
    if (scrap) {
      communityApiRequest.saveCommPostScrap(param).then(res => {
        if (res.code === 200) {
          tostAlert('스크랩 되었습니다');
        }
      });
    } else {
      //스크랩 해제

      communityApiRequest.deleteCommPostScrap(param).then(res => {
        if (res.code === 200) {
          tostAlert('스크랩 해제되었습니다.');
          emits('handleScrapManage', post);
        }
      });
    }
  };

  //공유하기
  const handleSharePopup = comm => {
    console.log(comm, 'comm');
    //커뮤니티 오픈/ 프라이빗 차이
    if (comm.type === 'open') {
      state.shareDatas = {
        url: `${import.meta.env.VITE_PUBLIC_BASE_URL}/detail/openContent/${comm.comm_info_id}/${comm.post_info_id}?kind=app`,
      };
    } else {
      state.shareDatas = {
        url: `${import.meta.env.VITE_PUBLIC_BASE_URL}/detail/privateContent/${comm.comm_info_id}/${comm.post_info_id}?kind=app`,
      };
    }
    usePopup.setSnsDate(state.shareDatas);
    usePopup.popups.isSharePopup = true;
  };
</script>

<template>
  <div class="function_component_wrap">
    <div class="left_con">
      <button class="like_btn up_like" type="button" @click="handleLikeAndNotFuc('LIKE')">
        <img v-if="!state.upVote" alt="" src="@/assets/img/like_off.svg" />
        <img v-else alt="" src="@/assets/img/like_on.svg" />
        <span :class="{ up: state.upVote }" class="count">{{ Number(state.communityData.up_vote) }}</span>
      </button>
      <button class="like_btn down_like" type="button" @click="handleLikeAndNotFuc('NOT')">
        <img v-if="!state.downVote" alt="" src="@/assets/img/un_like_off.svg" />
        <img v-else alt="" src="@/assets/img/un_like_on.svg" />
        <span :class="{ down: state.downVote }" class="count">{{ Number(state.communityData.down_vote) }}</span>
      </button>
      <button class="chat_btn" type="button" @click="handleLocationDetail">
        <img alt="" src="@/assets/img/chat_icon.svg" />
        <span class="count">{{ useComment.commentList.length > 0 ? useComment.commentList.length : props.data.comment_cnt }}</span>
      </button>
      <button class="check_btn" type="button">
        <img alt="" src="@/assets/img/eye_icon.svg" />
        <span class="count">{{ props.data.view_cnt }}</span>
      </button>
    </div>
    <div class="right_con">
      <button class="share_btn" type="button" @click="handleSharePopup(props.data)">
        <img alt="" src="@/assets/img/share_icon.svg" />
      </button>
      <button type="button">
        <img v-if="!state.isScrap" alt="" src="@/assets/img/subscribe_off.svg" @click="handlePostScrap(props.data.post_info_id, true)" />
        <img v-else alt="" src="@/assets/img/subscribe_on.svg" @click="handlePostScrap(props.data.post_info_id, false)" />
      </button>
    </div>
  </div>
  <!-- 공유하기 팝업 -->
  <SharePopup @close="usePopup.popups.isSharePopup = false" />
</template>

<style lang="scss" scoped>
  .function_component_wrap {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;

    img {
      vertical-align: middle;
    }

    .left_con {
      height: 100%;

      button {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .like_btn {
        height: 24px;

        .count {
          color: #727683;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.36px;

          &.up {
            color: #ff4747;
          }

          &.down {
            color: #2a98e9;
          }
        }
      }

      .chat_btn {
        color: #727683;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.36px;
      }

      .check_btn {
        .count {
          margin-left: 2px;
          color: #727683;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.36px;
        }
      }
    }

    .right_con {
      height: 100%;

      .share_btn {
        margin-right: 16px;
      }
    }
  }
</style>
