import axios from 'axios';
import { voteApiRequest } from '@/api/active/voteApiRequest';
import { useUserInfoStore } from '@/stores/useUserInfoStore';
import { usePopupStore } from '@/stores/usePopupStore';
import { useVoteStore } from '@/stores/useVoteStore';
import { useLoadingStore } from '@/stores/useLoadingStore';
import liveShotApi from '@/api/active/liveShotApi';
import { useRouter } from 'vue-router';
import { useAlarmStore } from '@/stores/useAlarmStore';

/**
 * myLocationRequest 위치 좌표 호출 함수
 * 내위치가 움직일때마다 호출하여 google gecode 를 사용하여 내위치 주소를 가져옴 차후 미팅 등록시
 * 미팅 위치와 매칭 하여 해당 주소에 있는 사람만 해당 미팅 게시물이 보이게끔 작업 해야함
 */
const getLocationMobile = () => {
  if (localStorage.getItem('version') !== '1.0.1') {
    return new Promise((resolve, reject) => {
      window.get_position = function (position_latitude, position_longitude) {
        try {
          localStorage.setItem('position_latitude', position_latitude);
          localStorage.setItem('position_longitude', position_longitude);
          resolve(`${position_latitude},${position_longitude}`);
        } catch (error) {
          reject(error);
        }
      };

      setTimeout(() => {
        window.webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            action: 'getlocation',
            callback: 'get_position',
            confirm: 0,
          }),
        );
      }, 3000);
    });
  } else {
    return new Promise((resolve, reject) => {
      window.get_position = function (position_latitude, position_longitude) {
        try {
          localStorage.setItem('position_latitude', position_latitude);
          localStorage.setItem('position_longitude', position_longitude);
          resolve(`${position_latitude},${position_longitude}`);
        } catch (error) {
          reject(error);
        }
      };

      setTimeout(() => {
        window.webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            action: 'getlocation',
            callback: 'get_position',
            confirm: 0,
          }),
        );
      }, 3000);
    });
  }
};
export const myLocationRequest = async () => {
  const getLocationPromise = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true, //더 정확한 위치 정보
        maximumAge: 0, //항상 최신 위치 정보를 요청
        timeout: 5000, //위치 정보를 얻기 위해 기다리는 최대 시간
      });
    });

  let latlng;
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.cordova_iab) {
    latlng = await getLocationMobile();
  } else {
    const position = await getLocationPromise();
    latlng = `${position.coords.latitude},${position.coords.longitude}`;
  }
  const useUserInfo = useUserInfoStore();
  useUserInfo.setLatlng(latlng);
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${import.meta.env.VITE_GOOGLE_MAP_KEY}`);

    // 국내 구분을 위해 나라찾기
    let realTimeLocation;
    realTimeLocation = response.data.results[3].address_components.find(country => {
      return country.types.includes('country');
    });

    let countryComponent = null; //도/시/나라
    let localityComponent = null; //군/구/도시

    for (const country of response.data.results) {
      //대한민국 일때 도시군구

      console.log(country.address_components);
      if (realTimeLocation.short_name === 'KR') {
        countryComponent = country.address_components.find(component => component.types.includes('administrative_area_level_1'));
        localityComponent =
          country.address_components.find(component => component.types.includes('locality')) || country.address_components.find(component => component.types.includes('sublocality_level_1'));
      } else {
        //해외인 경우 나라,도시만
        countryComponent = country.address_components.find(component => {
          return component.types.includes('country');
        });
        localityComponent = country.address_components.find(component => {
          return component.types.includes('locality');
        });
      }

      if (countryComponent && localityComponent) {
        break; // 조건에 맞는 요소를 찾았으므로 루프 중단
      }
    }
    // 위에서 알아낸 문자열들 합쳐주기
    let locationFullName = {};
    if (countryComponent && localityComponent) {
      console.log(countryComponent, '위치정보 :::위치정보 ');
      console.log(localityComponent, '위치정보 :::위치정보 ');

      locationFullName = {
        country: countryComponent.long_name,
        city: localityComponent.long_name,
      };

      console.log(locationFullName, 'locationFullName');
      return locationFullName;
    } else {
      console.log('Country or locality component not found');
    }
  } catch (error) {
    console.log(error);
  }
};
//위치 기반 투표

export const locationCommActivity = () => {
  console.log('locationCommActivity');
  const userInfo = useUserInfoStore();
  const usePopup = usePopupStore();
  const useVote = useVoteStore();
  const loading = useLoadingStore();
  const useAlarm = useAlarmStore();
  const memberInfo = useUserInfoStore();

  loading.setUpdateIsLoadData(false);
  myLocationRequest()
    .then(locationInfo => {
      // 실시간 위치 체크 API
      useAlarm.setMyLocation(locationInfo);

      liveShotApi
        .checkLiveLocation({
          lat: memberInfo.latlng.latitude,
          long: memberInfo.latlng.longitude,
        })
        .then(res => {
          console.log(res, 'checkLiveLocation');
        });
      voteApiRequest.checkRealTimeLocation(locationInfo).then(res => {});
      loading.setUpdateIsLoadData(true);
    })
    .catch(error => {
      // 오류 처리
      loading.setUpdateIsLoadData(true); // auth 경로인 경우 로딩 종료
      console.error('위치 정보를 가져오는 데 실패했습니다:', error);
    });
};

export const formatTime = time => {
  const insertDate = new Date(time);
  const currentDate = new Date();

  const timeDiff = currentDate.getTime() - insertDate.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  console.log(time, 'time');
  if (years > 0) {
    return `${years}년 전`;
  } else if (months > 0) {
    return `${months}달 전`;
  } else if (days > 0) {
    return `${days}일 전`;
  } else if (hours > 0) {
    return `${hours}시간 전`;
  } else if (minutes > 0) {
    return `${minutes}분 전`;
  } else {
    return `${seconds}초 전`;
  }
};

/**
 *
 * @param inputDate {String}
 * @param formatType
 * remove false 일때는 4자리 년수 + '-' 추가 , true 일때는 2자리년수 + ',' 추가
 * @return 날짜 포맷 수정
 */
export const formatDate = (inputDate, formatType = 'yyyy-mm-dd') => {
  let dateObj;
  if (typeof inputDate === 'string') {
    let normalizedDate = inputDate.replace(/\./g, '-');
    if (/^\d{2}-\d{2}-\d{2}$/.test(normalizedDate)) {
      normalizedDate = '20' + normalizedDate;
    }
    dateObj = new Date(normalizedDate);
  } else {
    dateObj = new Date(inputDate);
  }
  let year = dateObj.getFullYear().toString();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  switch (formatType) {
    case 'yyyy-mm-dd':
      return `${year}-${month}-${day}`;
    case 'yy.mm.dd':
      return `${year.slice(2)}.${month}.${day}`;
    case 'yyyy.mm.dd':
      return `${year}.${month}.${day}`;

    default:
      return `${year}-${month}-${day}`;
  }
};

// 날짜

export const formatDayNight = (dateObj, type = 'default') => {
  const date = new Date(dateObj);

  const yearFormat = type === 'default' ? 'numeric' : '2-digit';

  const options = {
    year: yearFormat,
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric', // 'numeric' 옵션은 시간 앞의 0을 포함하지 않습니다.
    minute: '2-digit',
    hour12: true,
  };

  let formattedDate = date.toLocaleString('ko-KR', options);

  // 결과에서 콜론(:) 다음에 나오는 초(ss) 부분을 제거
  formattedDate = formattedDate.replace(/:\d\d /, ' ');

  // 정규 표현식을 사용하여 시간 앞에 있는 선행하는 0을 제거
  formattedDate = formattedDate.replace(/(오전|오후) 0/, '$1 ');

  return formattedDate;
};

//로그아웃함수

export const useAuth = () => {
  const userInfoStore = useUserInfoStore();
  const router = useRouter();

  const logout = msg => {
    userInfoStore.reset();
    router
      .push('/')
      .then(() => {
        tostAlert(msg);
      })
      .catch(error => {
        console.error('오류가 발생했습니다.:', error);
      });
  };

  return {
    logout,
  };
};
