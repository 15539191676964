<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import { useRouterLinkStore } from '@/stores/useRouterLinkStore';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import { onMounted, reactive, watch } from 'vue';
  import { AuthApiRequest } from '@/api/authApiRequest';
  import { returnToken } from '@/utill/jwtToken';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { useChatCommunitySelectStore } from '@/stores/useChatCommunitySelectStore';
  import { useCommunitySearchFilterStore } from '@/stores/useCommunitySearchFilterStore';
  import { usePostStore } from '@/stores/usePostStore';
  import { useVoteStore } from '@/stores/useVoteStore';
  import { useMeetingStore } from '@/stores/useMeetingStore';
  import { sendbirdApi } from '@/api/sendbirdApi';

  const chatUpdate = useChatCommunitySelectStore();
  const router = useRouter();
  const route = useRoute();
  const emit = defineEmits(['centerClick']);
  const routerActive = useRouterLinkStore();
  const selectTab = useAddCommunityStore();
  const usePost = usePostStore();
  const useMeeting = useMeetingStore();
  const useVote = useVoteStore();
  const useCommunitySearchFilter = useCommunitySearchFilterStore();
  const userInfo = useUserInfoStore();
  const state = reactive({
    isActive: false,
    isNewChat: false,
    isUnread: false,
  });
  const handleClick = type => {
    switch (type) {
      case 'HOME':
        useCommunitySearchFilter.resetMainFilter();
        routerActive.setUpdateRouterActive('HOME');
        router.push('/');
        state.isActive = false;
        break;
      case 'TICKET':
        routerActive.setUpdateRouterActive('TICKET');
        router.push('/vote/step_one');
        state.isActive = false;
        break;
      case 'CHAT':
        routerActive.setUpdateRouterActive('CHAT');
        router.push('/chat/home');
        state.isActive = false;
        break;
      case 'MY':
        routerActive.setUpdateRouterActive('MY');
        router.push('/my');
        state.isActive = false;
        switch (type) {
          case 'HOME':
            useCommunitySearchFilter.resetMainFilter();
            routerActive.setUpdateRouterActive('HOME');
            router.push('/');
            break;
          case 'TICKET':
            routerActive.setUpdateRouterActive('TICKET');
            router.push('/vote/step_one');
            break;
          case 'CHAT':
            routerActive.setUpdateRouterActive('CHAT');
            router.push('/chat/home');
            break;
          case 'MY':
            routerActive.setUpdateRouterActive('MY');
            router.push('/my');
            break;
          case 'ADD_COMMUNITY':
            // Toggle the active state for ADD_COMMUNITY
            state.isActive = !state.isActive;
            break;
          default:
            // Handle any other undefined cases if necessary
            break;
        }

        // For all cases except 'ADD_COMMUNITY', set isActive to false
        if (type !== 'ADD_COMMUNITY') {
          state.isActive = false;
        }

        // Control the overflow style of the body based on isActive state
        document.body.style.overflow = state.isActive ? 'hidden' : '';
        switch (type) {
          case 'HOME':
            useCommunitySearchFilter.resetMainFilter();
            routerActive.setUpdateRouterActive('HOME');
            router.push('/');
            break;
          case 'TICKET':
            routerActive.setUpdateRouterActive('TICKET');
            router.push('/vote/step_one');
            break;
          case 'CHAT':
            routerActive.setUpdateRouterActive('CHAT');
            router.push('/chat/home');
            break;
          case 'MY':
            routerActive.setUpdateRouterActive('MY');
            router.push('/my');
            break;
          case 'ADD_COMMUNITY':
            state.isActive = !state.isActive;
            break;
          default:
            break;
        }
        if (type !== 'ADD_COMMUNITY') {
          state.isActive = false;
        }

        document.body.style.overflow = state.isActive ? 'hidden' : '';

        break;
      case 'ADD_COMMUNITY':
        state.isActive = !state.isActive;

        break;
    }

    if (state.isActive) {
      document.querySelectorAll('body')[0].style.overflow = 'hidden';
    } else {
      document.querySelectorAll('body')[0].style.overflow = '';
    }
    AuthApiRequest.checkAvailablePoints().then(res => {
      console.log(res, '~~~~~resres');
      const info = returnToken(res.data.access_token);
      userInfo.setMemberToken(res.data.access_token);
      console.log('=>(join.vue:52) info', info);
      userInfo.setMemberInfoUpdate(info);
    });

    usePost.reset();
    useMeeting.resetMeetingFormData();
    useVote.clearLocalstorage();
  };
  const handleBgClick = () => {
    state.isActive = false;
    if (state.isActive) {
      document.querySelectorAll('body')[0].style.overflow = 'hidden';
    } else {
      document.querySelectorAll('body')[0].style.overflow = 'unset';
    }
  };

  const handleCenterBtnClick = type => {
    switch (type) {
      case 'ADD':
        selectTab.reset();
        selectTab.setSelectTab('COMMUNITY_ADD');
        routerActive.setUpdateRouterActive('ADD_COMMUNITY');
        router.push('/add/AddCommunity');
        break;
      case 'MEETING':
        selectTab.reset();
        selectTab.setSelectTab('MEETING_ADD');
        routerActive.setUpdateRouterActive('ADD_COMMUNITY');
        router.push('/add/AddCommunity');
        break;
      case 'LIVECAM':
        selectTab.reset();
        selectTab.setSelectTab('LIVECAM');
        routerActive.setUpdateRouterActive('ADD_COMMUNITY');
        router.push('/add/AddressVue/live/area');
        break;
    }
    usePost.reset();
    useMeeting.resetMeetingFormData();
    useVote.clearLocalstorage();
    if (type !== 'ADD_COMMUNITY') {
      state.isActive = false;
    }
    if (state.isActive) {
      document.querySelectorAll('body')[0].style.overflow = 'hidden';
    } else {
      document.querySelectorAll('body')[0].style.overflow = 'unset';
    }
  };
  watch(
    () => state.isActive,
    newValue => {
      if (!newValue) {
        setTimeout(() => {
          state.hidden = true;
        }, 600);
      }
    },
  );
  watch(
    () => chatUpdate.isChannelUpdate,
    newValue => {
      if (newValue) {
        state.isNewChat = newValue;
      }
    },
  );

  onMounted(async () => {
    const count = await sendbirdApi.getUnreadMessageCount();
    state.isUnread = count > 0;
  });
</script>

<template>
  <div id="navigator_wrap" class="navigator_wrap">
    <div class="navigator_inner">
      <div class="left_navi">
        <div class="navi_btn" @click="handleClick('HOME')">
          <img v-if="route.path === '/'" alt="" src="@/assets/img/navigater/home_on.svg" />
          <img v-else alt="" src="@/assets/img/navigater/home_off.svg" />
        </div>
        <div class="navi_btn" @click="handleClick('TICKET')">
          <img v-if="route.path === '/vote/step_one'" alt="" src="@/assets/img/navigater/ticket_on.svg" />
          <img v-else alt="" src="@/assets/img/navigater/ticket_off.svg" />
        </div>
      </div>
      <div class="center">
        <button class="center_btn" type="button" @click="handleClick('ADD_COMMUNITY')">
          <img alt="" class="icons" src="../../assets/img/navigater/navigator_icon.png" />
        </button>
        <div class="triple_btn_wrap">
          <button :class="{ active: state.isActive }" class="triple_btn triple_btn_1" type="button" @click="handleCenterBtnClick('ADD')">
            <div class="bg_cover">
              <img alt="" class="icon" src="@/assets/img/navigater/center_icon_1.png" />
            </div>
            <span class="label">게시글</span>
          </button>
          <button :class="{ active: state.isActive }" class="triple_btn triple_btn_2" type="button" @click="handleCenterBtnClick('MEETING')">
            <div class="bg_cover">
              <img alt="" class="icon" src="@/assets/img/navigater/center_icon_2.png" />
            </div>
            <span class="label">미팅</span>
          </button>
          <button :class="{ active: state.isActive }" class="triple_btn triple_btn_3" type="button" @click="handleCenterBtnClick('LIVECAM')">
            <div class="bg_cover">
              <img alt="" class="icon" src="@/assets/img/navigater/center_icon_3.png" />
            </div>
            <span class="label">라이브샷</span>
          </button>
        </div>
      </div>
      <div class="right_navi">
        <div class="navi_btn" @click="handleClick('CHAT')">
          <div class="chat_btn">
            <img v-if="route.path === '/chat/home'" alt="" src="@/assets/img/navigater/chat_on.svg" />
            <img v-else alt="" src="@/assets/img/navigater/chat_off.svg" />
            <span v-if="state.isUnread" class="new_chat_bage"></span>
          </div>
        </div>
        <div class="navi_btn" @click="handleClick('MY')">
          <img v-if="route.path === '/my'" alt="" src="@/assets/img/navigater/user_on.svg" />
          <img v-else alt="" src="@/assets/img/navigater/user_off.svg" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="state.isActive" class="center_bg" @click="handleBgClick"></div>
</template>

<style lang="scss" scoped>
  .center_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }

  .navigator_wrap {
    position: fixed;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    height: 64px;
    width: 100%;
    padding: 0 12px;
    filter: drop-shadow(0px 1px 40px rgba(144, 152, 195, 0.24));
    max-width: 500px;
    z-index: 100;

    .navigator_inner {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .left_navi {
        height: 100%;
        background: #fff;
        border-radius: 24px 30px 0 24px;
        width: calc((100% - 44px) / 2);
        flex-basis: calc((100% - 44px) / 2);
        margin-right: 20px;
      }

      .center {
        position: absolute;
        width: 54px;
        flex-basis: 54px;
        height: 100%;
        border-bottom: solid 40px #fff;
        left: 50%;
        transform: translateX(-50%);

        .triple_btn_wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          .triple_btn {
            position: absolute;
            transition: left 0.6s cubic-bezier(0, 0.55, 0.45, 1), top 0.6s cubic-bezier(0, 0.55, 0.45, 1);

            .bg_cover {
              position: relative;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: #fff;

              .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 28px;
                height: 28px;
              }
            }

            .label {
              display: inline-block;
              margin-top: 8px;
              color: #fff;
              text-align: center;
              font-family: Spoqa Han Sans Neo;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: -0.36px;
            }

            &.triple_btn_1,
            &.triple_btn_2,
            &.triple_btn_3 {
              top: 0;
              left: 0;
              transform: scale(0);
            }

            &.triple_btn_1.active {
              top: -96px;
              left: -64px;
              transform: scale(1);
            }

            &.triple_btn_2.active {
              top: -120px;
              transform: scale(1);
            }

            &.triple_btn_3.active {
              top: -96px;
              left: 64px;
              transform: scale(1);
            }
          }
        }

        .center_btn {
          position: absolute;
          top: 1px;
          left: 0;
          border-radius: 50%;

          .icons {
            position: relative;
            z-index: 10;
            width: 100%;
            filter: drop-shadow(0px 1px 40px rgba(144, 152, 195, 0.24));
            border-radius: 50%;
          }
        }
      }

      .right_navi {
        margin-left: 20px;
        height: 100%;
        background: #fff;
        border-radius: 30px 24px 24px 0;
        width: calc((100% - 44px) / 2);
        flex-basis: calc((100% - 44px) / 2);
      }

      .left_navi,
      .right_navi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .navi_btn {
          position: relative;
          width: 24px;
          height: 24px;
          flex-basis: calc(100% / 2);
          display: flex;
          justify-content: center;
          text-align: center;

          .new_chat_bage {
            position: absolute;
            top: -1px;
            right: -1px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #2a98e9;
          }
        }
      }
    }
  }
  .chat_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    right: 0;
  }
</style>
