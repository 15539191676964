import { acceptHMRUpdate, defineStore } from 'pinia'
import {sendbirdApi} from "@/api/sendbirdApi";

export const useSendBirdStore = defineStore('useSendBird', {
    state: () => {
        return {
            isConnectState: sendbirdApi.connectedState(),
            isSnsType: null,
        }
    },
    actions: {

    },
    getters: {},
    persist: false,
})

if (import.meta.hot)
    import.meta.hot.accept(acceptHMRUpdate(useSendBirdStore, import.meta.hot))
