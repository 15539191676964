import { acceptHMRUpdate, defineStore } from 'pinia';

export const useMessageStore = defineStore('useMessage', {
  state: () => {
    return {
      sendbird: null,
      messageList: [],
      lastMessageData: null,
    };
  },
  actions: {
    setSendBirdBody(data) {
      this.sendbird = data;
    },
    setMessageList(data) {
      console.log('=>(useMessageStore.js:13) data', data);
      console.log('data.messageType', data.messageType);
      this.messageList.push(data);
    },
    resetMessage() {
      this.messageList = [];
    },
    setLastMessageData(data) {
      this.lastMessageData = data;
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useMessageStore, import.meta.hot));
