import { acceptHMRUpdate, defineStore } from 'pinia';
import communityApi from '@/api/community/communityApi';

/**
 * 커뮤니티 관련 store
 * @type {StoreDefinition<"useAddCommunity", {communitySelect: {id: null, type: null, value: null}, selectTab: string, meetingArea: null, selectArea: {gun: null, si: null, gu: null}, gubun: null}, {}, {setUpdateArea(*, *, *): void, setUpdateGubun(*): void, reset(): void, restCommunitySelect(): void, setCommunitySelect(*, *, *): void, setMeetingArea(*): void, setSelectTab(*): void}>}
 */
export const useAddCommunityStore = defineStore('useAddCommunity', {
  state: () => {
    return {
      commId: null,
      gubun: null,
      selectTab: '',
      communitySelect: {
        type: null,
        value: null,
        id: null,
      },
      meetingArea: null,
      selectArea: {
        si: null,
        gun: null,
        gu: null,
      },
    };
  },
  actions: {
    setCommId(data) {
      this.commId = data;
    },
    setUpdateGubun(data) {
      this.gubun = data;
    },
    setSelectTab(data) {
      this.selectTab = data;
    },
    setCommunitySelect(type, value, id) {
      console.log('selectTab.communitySelect', type, value, id);
      this.communitySelect.type = type;
      this.communitySelect.value = value;
      this.communitySelect.id = id;
    },
    setMeetingArea(data) {
      this.meetingArea = data;
    },
    setUpdateArea(si, gun, gu) {
      this.selectArea.si = si;
      this.selectArea.gun = gun;
      this.selectArea.gu = gu;
    },
    //오픈 커뮤니티 가입 요청
    setOpenCommJoin(param) {
      communityApi
        .openCommJoin(param)
        .then(res => {
          if (res.code === 200) {
            tostAlert('오픈 커뮤니티가 추가되었습니다.');
          }
        })
        .catch(error => {
          // 네트워크 오류 또는 기타 오류 처리
        });
    },

    resetArea() {
      this.selectArea.si = null;
      this.selectArea.gun = null;
      this.selectArea.gu = null;
    },
    restCommunitySelect() {
      console.log('reset reset');
      this.communitySelect.type = null;
      this.communitySelect.value = null;
      this.communitySelect.id = null;
    },
    reset() {
      console.log('reset reset');
      // this.selectTab = 'COMMUNITY_ADD';
      this.communitySelect.type = null;
      this.communitySelect.value = null;
      this.meetingArea = null;
      this.selectArea.si = null;
      this.selectArea.gun = null;
      this.selectArea.gu = null;
    },
    resetCommId() {
      this.commId = null;
    },
  },
  getters: {},
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAddCommunityStore, import.meta.hot));
