import { acceptHMRUpdate, defineStore } from 'pinia';

export const useUpdateStore = defineStore('useUpdate', {
  state: () => {
    return {
      isUpdate: 0,
    };
  },
  getters: {},
  actions: {
    setUpdateCount(data) {
      this.isUpdate = data;
    },
  },
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUpdateStore, import.meta.hot));
