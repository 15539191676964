

import __layout_0 from '/var/jenkins_home/workspace/www.seemple.kr/src/layouts/default.vue'
export const layouts = {
'404': () => import('/var/jenkins_home/workspace/www.seemple.kr/src/layouts/404.vue'),
'TransitionVue': () => import('/var/jenkins_home/workspace/www.seemple.kr/src/layouts/TransitionVue.vue'),
'default': __layout_0,
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
