<script setup>
  import { onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination } from 'swiper/modules';
  import 'swiper/scss/pagination';

  const youTubeUrl = ref(null);
  const props = defineProps({
    data: Object,
  });
  const ImagePopVueTag = ref(null);
  const state = reactive({
    propsData: null,
    frameWidth: 0,
    frameHeight: 0,
    swiperOption: {
      slidesPerView: '1',
      spaceBetween: '5',
    },
    swiper: null,
    modules: [Pagination],
    imageArray: [],
    isImagePop: false,
    isChange: false,
  });

  const handleResize = () => {
    state.frameWidth = document.getElementsByTagName('main')[0].offsetWidth - 32;
    state.frameHeight = (state.frameWidth * 9) / 16;
  };
  const handleOnSwiper = swiper => {
    state.swiper = swiper;
  };
  function extractVideoId(url) {
    console.log('=>(CommunityPictureVue.vue:33) url', url);
    return url;
  }

  const handleImageScale = (images, idx) => {
    console.log('=>(CommunityPictureVue.vue:54) idx', idx);
    console.log('=>(CommunityPictureVue.vue:55) images', images);
    const imageArrays = state.imageArray;
    const imageArray = imageArrays.filter(imageData => {
      if (imageData.sort_num !== imageArrays.at(idx).sort_num) {
        return imageData;
      }
    });
    imageArray.unshift(imageArrays.at(idx));
    console.log('=>(CommunityPictureVue.vue:61) imageArray', imageArray);
    state.isImagePop = true;
    setTimeout(() => {
      ImagePopVueTag.value.handlePopOpenFuc(imageArray);
    });
  };

  onMounted(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    if (props.data) {
      state.propsData = props.data;
      if (state.propsData) {
        youTubeUrl.value = state.propsData.youtube_url;
        state.imageArray = props.data.file_path_list;
      }
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });

  watch(
    () => props.data,
    newPropsDta => {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
      console.log(newPropsDta);
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
      state.isChange = true;
      state.propsData = newPropsDta;
      if (state.propsData) {
        youTubeUrl.value = state.propsData.youtube_url;
        state.imageArray = props.data.file_path_list;
      }
      setTimeout(() => {
        state.isChange = false;
      });
    },
  );
</script>

<template>
  <div class="picture_list_con">
    <div v-if="youTubeUrl && !state.isChange" :style="{ width: state.frameWidth - 18, height: state.frameHeight - 18 }" class="video_cover">
      <iframe
        :height="state.frameHeight"
        :src="`https://www.youtube.com/embed/${youTubeUrl}`"
        :style="{ pointerEvents: state.isImagePop ? 'none' : 'auto' }"
        :width="state.frameWidth"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        frameborder="0"
        title="YouTube video player"
      ></iframe>
    </div>
    <div v-if="state.imageArray && state.imageArray.length > 0 && !state.isChange" class="image_list_wrap">
      <Swiper
        v-if="state.imageArray.length > 0 && !state.isChange"
        :modules="state.modules"
        :pagination="{ clickable: true }"
        class="advertisement_list"
        v-bind="state.swiperOption"
        @swiper="handleOnSwiper"
      >
        <swiper-slide v-for="(images, idx) in state.imageArray" :key="idx" class="slide_item">
          <button type="button" @click="handleImageScale(images, idx)">
            <img :src="images.file_path" alt="" />
          </button>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </Swiper>
    </div>
  </div>
  <ImagePopVue v-if="state.isImagePop" ref="ImagePopVueTag" @closePop="state.isImagePop = false" />
</template>

<style lang="scss" scoped>
  .picture_list_con {
    .video_cover {
      border-radius: 18px;
      overflow: hidden;
      iframe {
        display: block;
        pointer-events: none;
      }
    }
    .image_list_wrap {
      margin-top: 16px;
      height: 328px;
      .advertisement_list {
        height: 100%;
        border-radius: 18px;
        overflow: hidden;
      }
      &:deep {
        .swiper-pagination {
          .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
              background: #fff;
            }
          }
        }
      }

      .slide_item {
        height: 100%;
        button {
          display: inline-block;
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
</style>
