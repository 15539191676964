<script setup>
  import { onMounted, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { usePaymentStore } from '@/stores/usePaymentStore';
  import paymentApi from '@/api/paymentApi';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { paymentApiRequest } from '@/api/paymentApiRequest';
  import { usePopupStore } from '@/stores/usePopupStore';
  import RefundAndSettlementInfo from '@Components/popup/RefundAndSettlementInfo.vue';

  const router = useRouter();
  const route = useRoute();
  const usePayment = usePaymentStore();
  const usePopup = usePopupStore();
  const state = reactive({
    chargeAmountList: [
      {
        amount: 10000,
      },
      {
        amount: 50000,
      },
      {
        amount: 100000,
      },
      {
        amount: 500000,
      },
    ],
    formData: {
      chargeAmount: '',
      agreeCheck: false,
      payment: '',
    },
    paymentWidget: null,
    paymentMethodWidget: null,
    amount: 50000,
    tossPayments: null,
    postType: null,
  });
  const clientKey = import.meta.env.VITE_TOSS_KEY; // 테스트용 클라이언트 키

  const handleCoinInput = () => {
    state.formData.chargeAmount = state.formData.chargeAmount.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const handleAddPoint = coinVal => {
    console.log('=>(chargeVue.vue:28) coinVal', coinVal);
    state.formData.chargeAmount = Number(state.formData.chargeAmount.replace(/,/g, ''));
    const currentAmount = Number(state.formData.chargeAmount) + Number(coinVal);
    state.formData.chargeAmount = Number(currentAmount).toLocaleString();
    console.log('=>(chargeVue.vue:28) coinVal', state.formData.chargeAmount);
  };
  const handleGetPaymentSelect = type => {
    state.formData.payment = type;
  };

  // 토스 결제창 열기
  const requestPayment = (amount, orderId, orderName) => {
    const paymentOptions = {
      amount,
      orderId,
      orderName,
      successUrl: `https://admin.seemple.kr/api/pay/success?type=${state.postType}`, // 성공 리다이렉트 URL
      failUrl: 'https://admin.seemple.kr/api/pay/fail', // 실패 리다이렉트 URL
    };

    if (navigator.userAgent.toLowerCase().indexOf('mobileapp') !== -1) {
      paymentOptions.appScheme = 'seempleapp://';
    }

    state.tossPayments
      .requestPayment(state.formData.payment, paymentOptions)
      .then(res => {
        console.log(res);
        usePayment.isPayment = true;
      })
      .catch(error => {
        if (error.code === 'USER_CANCEL') {
          // 결제 고객이 결제창을 닫았을 때 에러 처리
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          // 유효하지 않은 카드 코드에 대한 에러 처리
        }
      });
  };
  //결제하기 버튼
  const handleCharge = () => {
    const chargeValue = Number(state.formData.chargeAmount.replace(/,/g, ''));
    if (chargeValue < 10000) {
      return tostAlert('최소 충전 금액은 1,0000원 이상부터 가능합니다');
    }
    if (!state.formData.payment) {
      return tostAlert('결제수단을 선태해주세요.');
    }
    if (!state.formData.agreeCheck) {
      return tostAlert('결제 동의에 체크해주세요.');
    }
    const requestData = {
      amount: chargeValue,
      paymentType: state.formData.payment, // "가상계좌"
    };
    paymentApiRequest.cardPaymentCheck(requestData).then(res => {
      if (!res.data) return tostAlert(res.message);
      const amount = parseInt(res.data.amount);
      const orderId = res.data.orderId;
      const orderName = res.data.orderName;
      requestPayment(amount, orderId, orderName);
    });
  };

  // Mounted hook
  onMounted(() => {
    state.tossPayments = TossPayments(clientKey);

    const postTypeMap = {
      '/my/MyScoin': 'mypage',
      '/vote/addVote': 'vote_register',
      '/vote/editVote': 'vote_modify',
      '/chatting/room': 'chatting',
      '/add/AddCommunity': 'meeting_register',
      '/community/MeetingEditVue': 'meeting_modify',
      '/add/AddressVue/live/addLiveShot': 'liveshot_register',
      '/community/EditLiveShot': 'liveshot_modify', // 이중으로 정의된 경로는 주석 처리
    };

    // 라우터의 fullPath를 사용하여 postType 설정
    state.postType = postTypeMap[route.path] || '기본값'; // '기본값'은 해당하는 경로가 없을 경우에 사용될 기본값입니다.
    console.log(route.fullPath, '결제@@@@@@@@@@@@@@@@@@');
    console.log(state.postType, '결제@@@@@@@@@@@@@@@@@@');
  });
</script>

<template>
  <div class="charge_wrap">
    <div class="charge_inner">
      <h3 class="title">충전할 Scoin 금액</h3>
      <div class="charge_coin_inputs">
        <input v-model="state.formData.chargeAmount" class="charge_inputs" placeholder="충전할 Scoin 금액을 입력해 주세요.(1만원 이상)" type="text" @input="handleCoinInput" />
        <div class="quick_charge_amount_list">
          <button v-for="(amount, idx) in state.chargeAmountList" :key="idx" class="amount_tag" type="button" @click="handleAddPoint(amount.amount)">
            +{{ Number(amount.amount).toLocaleString() }}
          </button>
        </div>
      </div>
      <div class="payment_method">
        <h3 class="title">결제 수단</h3>
        <button :class="{ active: state.formData.payment === '카드' }" class="payment_btn card" type="button" @click="handleGetPaymentSelect('카드')">
          <img v-if="state.formData.payment === '카드'" alt="" class="icons" src="@/assets/img/card_icons_on.svg" />
          <img v-else alt="" class="icons" src="@/assets/img/card_icons.svg" />

          신용카드
        </button>
        <button :class="{ active: state.formData.payment === '가상계좌' }" class="payment_btn account" type="button" @click="handleGetPaymentSelect('가상계좌')">
          <img v-if="state.formData.payment === '가상계좌'" alt="" class="icons" src="@/assets/img/Wallet_icons.svg" />
          <img v-else alt="" class="icons" src="@/assets/img/Wallet.off_icons.svg" />
          가상계좌 입금
        </button>
        <ul class="info_text_list">
          <li class="info_text_list_item"><span class="disk_icons">•</span>신용카드 결제금액이 30만원 이상일 경우 (단, 삼성카드는 50만원) ARS, 공인인증서 등 추가 인증이 필요합니다.</li>
          <li class="info_text_list_item"><span class="disk_icons">•</span>카드사별 Scoin 혜택은 각 카드사를 통해 확인해주시기 바랍니다.</li>
        </ul>
      </div>
      <div class="agree_con">
        <div class="default_checkbox">
          <input id="check1" v-model="state.formData.agreeCheck" type="checkbox" />

          <label for="check1">
            <span>
              <span>(필수) Scoin 충전을 위해 결제금액을 최종 확인하였으며, 회원 본인은 결제에 동의합니다.</span>
              <!--              <button class="ml-auto agree_text" type="button" @click="usePopup.popups.isTerms = true">전체보기</button>-->
            </span>
          </label>
        </div>
      </div>
      <div class="refund_box">
        <p>[seemple Scoin결제 환불규정]</p>
        직접 결제하여 충전한 Scoin만 환불할 수 있습니다.<br />
        이용약관에 따라<br />
        ① 신용카드로 Scoin 충전하였으며 <br />② 취소를 희망하는 결제 건의 Scoin 사용 이력이 없고<br />
        ③ 결제 완료일로부터 7일이 지나지 않으면 결제 취소가 가능합니다.<br />
        단, 환불대상 회원이 타회원에게 Scoin지급이 예정되어 있을 경우는 포인트 환불이 불가하며, Scoin 지급 이후 환불이 가능합니다.
      </div>
      <div class="fixed_btn btm_btn">
        <button class="default_btn" type="button" @click="handleCharge">충전하기</button>
      </div>
    </div>
  </div>
  <!--환불 출금 -->
  <RefundAndSettlementInfo v-if="usePopup.popups.isTerms" />
</template>

<style lang="scss" scoped>
  .refund_box {
    margin-top: 24px;
    font: $text-body14-400;
  }

  .agree_text {
    color: $blue-g30;
    font: $text-caption-400;
    letter-spacing: -0.36px;
    flex: 0 0 50px;
    margin-left: 8px;
  }

  .charge_wrap {
    border-top: solid 6px #f3f4f7;
    padding-top: 24px;

    .charge_inner {
      padding: 0 24px 100px;

      .title {
        color: #000;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.18px;
      }

      .charge_coin_inputs {
        margin-top: 22px;

        .charge_inputs {
          padding: 0 4px;
          width: 100%;
          height: 50px;
          border-bottom: solid 1px #f3f4f7;
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.42px;

          &::placeholder {
            color: #9b9ea9;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
          }
        }

        .quick_charge_amount_list {
          margin-top: 8px;

          .amount_tag {
            padding: 6px 8px;
            background: #f3f4f7;
            border-radius: 10px;
            color: #727683;
            text-align: center;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.36px;

            &:not(:last-child) {
              margin-right: 4px;
            }
          }
        }
      }

      .payment_method {
        .title {
          margin-top: 30px;
          margin-bottom: 8px;
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.48px;
        }

        .payment_btn {
          display: inline-block;
          width: 100%;
          height: 56px;
          border-radius: 10px;
          padding-left: 16px;
          text-align: left;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &.card {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            letter-spacing: -0.48px;
            border: solid 1px #f3f4f7;

            &.active {
              background: #f7f3ff;
              color: #6728ff;
            }
          }

          &.account {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            letter-spacing: -0.48px;
            border: solid 1px #f3f4f7;

            &.active {
              background: #f7f3ff;
              color: #6728ff;
            }
          }

          .icons {
            margin-right: 4px;
          }
        }

        .info_text_list {
          padding: 0 4px;

          .info_text_list_item {
            display: flex;
            align-items: flex-start;
            color: #9b9ea9;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.36px;
          }
        }
      }

      .agree_con {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .check_icons {
          display: inline-block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }

        .agree_check {
          display: inline-block;
          width: 100%;
          text-align: left;
          position: relative;

          .text_con {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
          }
        }
      }

      .submit_btn_con {
        margin-top: 56px;
        padding-bottom: 16px;

        .submit_btn {
          display: inline-block;
          height: 50px;
          border-radius: 10px;
          background: #6728ff;
          width: 100%;
          color: #fff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.42px;
        }
      }
    }
  }
</style>
