<script setup>
  import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
  import { Sheet } from 'bottom-sheet-vue3';
  import bottomeSheetStyled from '@/styled/bottomeSheetStyled';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { customLocale } from '@/utill/calendar';
  import { formatDate, formatTime } from '@/utill/common';

  const usePopup = usePopupStore();

  const innerWrapper = ref(null);
  const datepicker = ref(null);

  const props = defineProps({
    noRange: Boolean,
    dateType: String,
    dateSet: Array,
    isInvalid: Boolean,
    dateValue: String,
  });
  const emit = defineEmits(['dateClose']);
  const state = reactive({
    isDateInitialized: false,
    isRangeInitialized: false,
    datePick: { date: '', dateArr: new Array(2).fill(undefined), startDate: '', endDate: '' },
    rangePick: { date: '', dateArr: new Array(2).fill(undefined), startDate: '', endDate: '' },
    date: new Date(),
    mHeight: 0,
  });

  //날짜 포맷
  const setFormat = dates => {
    console.log(dates);

    const year = dates.getFullYear().toString();
    const month = (dates.getMonth() + 1).toString().padStart(2, '0');
    const day = dates.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  //날짜 포맷 함수 이후
  const format = date => {
    console.log(date);
    if (props.noRange) {
      if (props.dateType === 'startDate') {
        state.datePick.startDate = setFormat(date);
      } else if (props.dateType === 'endDate') {
        state.datePick.endDate = setFormat(date);
      }
    } else {
      const values = Object.values(date);
      state.rangePick.startDate = setFormat(values[0]);
      state.rangePick.endDate = setFormat(values[1]);
    }
  };

  //팝업 닫힐때 전송 데이터
  const close = () => {
    let formData = {};
    if (props.noRange) {
      if (props.dateType === 'startDate') {
        formData.start_date = state.datePick.startDate;
        state.datePick.dateArr[0] = state.date;
      } else if (props.dateType === 'endDate') {
        formData.end_date = state.datePick.endDate;
        state.datePick.dateArr[1] = state.date;
      }
      state.datePick.date = state.date;
      emit('dateClose', formData, state.datePick.dateArr, props.noRange);
    } else {
      formData = {
        start_date: state.rangePick.startDate,
        end_date: state.rangePick.endDate,
      };
      state.rangePick.date = state.date;
      emit('dateClose', formData, state.rangePick.date, props.noRange);
    }
  };

  //팝업열릴때 datepicker 도 같이 열리도록함
  watch(
    () => usePopup.popups.isDatePicker,
    async newValue => {
      await nextTick(() => {
        if (newValue) {
          if (datepicker.value && datepicker.value.openMenu) {
            datepicker.value.openMenu();
            console.log(props.dateSet, 'console.log(props.dateSet)');
            // 데이터 픽커 기간 설정 아닐때 선택한 날짜 보여지도록
            if (props.dateSet) {
              if (props.noRange && !state.isDateInitialized) {
                state.date = formatDate(props.dateSet.date, 'yy.mm.dd');
                state.isDateInitialized = true;
                console.log('123,state.date', state.date);
              }
              if (!props.noRange && !state.isRangeInitialized) {
                state.isRangeInitialized = true;
                state.date = [];
                state.date.push(formatDate(props.dateSet.range[0], 'yy.mm.dd'));
                state.date.push(formatDate(props.dateSet.range[1], 'yy.mm.dd'));
                console.log('123,state.date', state.date);
              }
            } else {
              if (props.noRange) {
                if (props.dateType === 'startDate') {
                  state.date = state.datePick.dateArr[0];
                } else {
                  state.date = state.datePick.dateArr[1];
                }
              } else {
                state.date = state.rangePick.date;
              }
            }
            if (props.isInvalid) {
              state.date = null;
            }
            if (innerWrapper.value) {
              state.mHeight = innerWrapper.value.offsetHeight;
            }
          }
        }
      });
    },
  );

  watch(
    () => props.noRange,
    newNoRange => {
      if (newNoRange) {
        if (props.dateType === 'startDate') {
          state.date = state.datePick.dateArr[0];
        } else {
          state.date = state.datePick.dateArr[1];
        }
      } else {
        state.date = state.rangePick.date;
      }
      console.log(state.date, 'state.datstate.dat');
    },
  );
  watch(
    () => props.dateValue,
    newValue => {
      if (newValue) {
        state.date = newValue;
      }
    },
  );
</script>
<template>
  <Sheet v-model:visible="usePopup.popups.isDatePicker" :onlyHeaderSwipe="true" class="custom-bottom-sheet">
    <div class="bottom_sheet_wrap">
      <div class="bottom_sheet_inner">
        <bottomeSheetStyled>
          <h5 class="pop_title">날짜 선택</h5>
          <div ref="innerWrapper" :class="{ scrolly: state.mHeight >= 414 }" class="edit_wrap">
            <VueDatePicker
              ref="datepicker"
              v-model="state.date"
              :enable-time-picker="false"
              :format="format"
              :min-date="new Date()"
              :range="!props.noRange"
              auto-apply
              locale="ko"
              model-type="yy.MM.dd"
              prevent-min-max-navigation
              @closed="close"
            >
              <template #arrow-left>
                <img class="slot-icon" src="@/assets/img/gray_right_iocn.svg" />
              </template>
              <template #year="{ value }">
                {{ value }}
              </template>
              <template #month="{ value }"> .{{ value + 1 < 10 ? String(value + 1).padStart(2, '0') : String(value + 1) }} </template>
              <template #arrow-right>
                <img class="slot-icon" src="@/assets/img/gray_right_iocn.svg" />
              </template>
            </VueDatePicker>
          </div>
        </bottomeSheetStyled>
      </div>
    </div>
  </Sheet>
</template>

<style lang="scss" scoped>
  .edit_wrap {
    &:deep {
      .dp__menu {
        border: none;
      }

      .dp__menu_inner {
        padding: 0;
      }

      .dp__input {
        display: none;
      }

      .dp__month_year_row {
        padding: 14px 4px;
        height: 50px;
      }

      .dp__month_year_wrap {
        justify-content: center;

        .dp__month_year_select {
          width: auto;
          color: $black;
          font: $subtitle1;
          letter-spacing: -0.18px;

          &:nth-of-type(1) {
            order: 2;
          }
        }
      }

      .dp__month_year_row {
        .dp--arrow-btn-nav:nth-of-type(1) {
          rotate: 180deg;
        }
      }

      .dp__inner_nav_disabled:hover,
      .dp__inner_nav:hover,
      .dp__inner_nav_disabled,
      .dp--year-select:hover,
      .dp__month_year_select:hover {
        background: #fff;
      }

      .dp--year-select,
      .dp__month_year_select {
        pointer-events: none;
      }

      .dp--menu-wrapper {
        position: unset;
      }

      .dp__arrow_bottom {
        display: none;
      }

      .dp__calendar_header_item {
        color: $blue-g30;
        font: $text-caption-500;
        letter-spacing: -0.36px;
        height: initial;
        padding: 16px 0;
        width: auto;
      }

      .dp__calendar_header_separator {
        display: none;
      }

      .dp__cell_inner {
        width: 100%;
        height: auto;
        padding: 16px 0;
        font: $text-caption-500;
        letter-spacing: -0.36px;
        max-height: 50px;
        color: $blue-g40;
        border: none;

        &:hover {
          background-color: #fff !important;
        }

        &.dp__cell_disabled,
        &.dp__cell_offset {
          color: $blue-g20;
        }
      }

      .dp__calendar_item {
        flex: 1;
        height: 50px;
        display: flex;
        align-items: center;
      }

      .dp__range_start {
        border-radius: 50em 0 0 50em;

        &:before {
          content: '';
          width: 50%;
          height: 44px;
          position: absolute;
          left: 50%;
          background: $purple-40;
          z-index: -2;
        }
      }

      .dp__date_hover_end:hover,
      .dp__date_hover_start:hover,
      .dp__date_hover:hover,
      .dp__range_between {
        background: $purple-40;
        height: 44px;
      }

      .dp__today {
        border: none;
        color: $purple;
      }

      .dp__range_end {
        border-radius: 0 50em 50em 0;

        &:before {
          content: '';
          width: 50%;
          height: 44px;
          position: absolute;
          right: 50%;
          background: $purple-40;
          z-index: -2;
        }
      }

      .dp__range_start,
      .dp__range_end,
      .dp__active_date {
        color: #fff;
        position: relative;
        z-index: 1;
        background: $white;

        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          border-radius: 50em;

          width: 44px;
          height: 44px;
          display: block;
          background: $purple-deep;
          color: $white;
          font: $text-caption-500;
          letter-spacing: -0.36px;
        }
      }
    }
  }
</style>
