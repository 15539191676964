<script setup>
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { onMounted, reactive } from 'vue';
  import TopHeaderVue from '@Components/common/TopHeaderVue.vue';
  import { useScoinStore } from '@/stores/useScoinStore';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import ChargeVue from '@Components/chargeVue.vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMeta } from 'vue-meta';
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';

  const loading = useLoadingStore();
  const useScoin = useScoinStore();
  const emit = defineEmits(['close']);
  const props = defineProps(['type']);

  console.log(props.type);
  useMeta({ title: 'Scoin 충전' });

  const state = reactive({
    typeText: '',
  });

  const close = () => {
    emit('close');
    console.log('!!!!!');
  };

  onMounted(() => {
    const coinTypes = {
      meeting_register: '미팅',
      meeting_modify: '미팅',
      liveshot_register: '라이브샷',
      liveshot_modify: '라이브샷',
      chatting: '미팅',
      vote_register: '투표',
      vote_modify: '투표',
    };
    state.typeText = coinTypes[props.type];
    console.log(state.typeText, 'state.typeText');
  });
</script>

<template>
  <defaultPopupStyles>
    <TopHeaderVue fuc="true" title="Scoin 충전" @close="close" />
    <div class="charging_wrap container_top_inner">
      <div class="container_inner">
        <ul class="round_color_box coin_box flex-">
          <li>
            <span>{{ state.typeText }} Scoin</span>
            <span>{{ Number(useScoin.insufficientCoin.voteCoin).toLocaleString() }}</span>
          </li>
          <li>
            <span>사용가능 Scoin</span>
            <span>{{ Number(useScoin.availableCoin).toLocaleString() }}</span>
          </li>
          <li class="total">
            <span>부족 Scoin</span>
            <span class="txt-pink"> {{ Number(useScoin.insufficientCoin.failCoin).toLocaleString() }}</span>
          </li>
        </ul>

        <ChargeVue />
      </div>
    </div>
  </defaultPopupStyles>
</template>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/community.scss';

  .header_wrap {
    &:deep {
      .inner {
        .title {
          margin-top: 0;
        }
      }
    }
  }

  .charging_wrap {
    &:deep {
      .charge_wrap {
        border-top: 0;
        padding-top: 32px;

        .charge_inner {
          padding: 0;
        }
        .tit {
        }
      }
    }
  }
</style>
