<script setup>
  import { useMeta } from 'vue-meta';
  import { useRouter } from 'vue-router';
  import HeaderVue from '@Components/common/HeaderVue.vue';
  import UserInfoVue from '@Components/common/UserInfoVue.vue';
  import CommunityVue from '@Components/common/CommunityVue.vue';
  import AdvertisementVue from '@Components/common/AdvertisementVue.vue';
  import ContentVue from '@Components/common/ContentVue.vue';
  import { onBeforeMount, onMounted, reactive } from 'vue';
  import { sendbirdApi } from '@/api/sendbirdApi';
  import { useCommunitySearchFilterStore } from '@/stores/useCommunitySearchFilterStore';
  import { useBottomSheetStore } from '@/stores/useBottomSheetStore';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { useRouterLinkStore } from '@/stores/useRouterLinkStore';
  import MainFooter from '@Components/MainFooter.vue';

  useMeta({
    title: 'Homepage',
    themeColor: '#6728ff',
  });
  const userInfo = useUserInfoStore();
  const loading = useLoadingStore();
  const router = useRouter();
  /**
   * routerActive.setUpdateRouterActive 네비게이션 현재 위치 에 맞게 on 시키는 함수
   * @type {Store<"useRouterLink", {isActive: string}, {}, {setUpdateRouterActive(*): void}>}
   */
  const routerActive = useRouterLinkStore();
  const searchFilter = useCommunitySearchFilterStore();
  const isVisible = useBottomSheetStore();
  const state = reactive({
    isVisible: false,
    banner_kind: 'main',
    urlParam: '',
  });

  onBeforeMount(() => {
    // sendbirdApi.searchUserList(String(userInfo.memberInfo.nickname)).then(res => {
    //   if (res.length <= 0) {
    //     console.log('=>(info.vue:16) res', res);
    //     userInfo.reset();
    //     router.push('/auth/join');
    //   }
    // });
  });

  onMounted(() => {
    const ele = document.getElementById('navigator_wrap');
    ele.style.display = 'unset';
    console.log(userInfo.shareUrl, '123123');
    routerActive.setUpdateRouterActive('HOME');
  });
</script>
<template>
  <div class="index_wrapper">
    <div class="top_con">
      <HeaderVue />
      <!--유저정보-->
      <UserInfoVue />
      <!--내 커뮤니티-->
      <CommunityVue />
    </div>
    <div class="bottom_con">
      <div class="middle_con">
        <!--광고-->
        <AdvertisementVue :banner_kind="state.banner_kind" />
      </div>
      <div class="bottom_content">
        <ContentVue />
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<style lang="scss" scoped>
  .index_wrapper {
    .top_con {
      position: relative;
      padding: 0 0 16px 16px;
      background: #6728ff;
      border-radius: 0px 0px 0px 40px;
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        right: 0;
        width: 24px;
        height: 20px;
        background: #6728ff;
      }
    }

    .bottom_con {
      background: #fff;

      .bottom_content {
        position: relative;
        border-top: solid 6px #f3f4f7;
        background: #ffff;

        &:after {
          content: '';
          position: absolute;
          bottom: -20px;
          right: 0;
          width: 24px;
          height: 20px;
          background: #ffff;
        }
      }
    }
  }

  a {
    text-decoration: underline;
    transition: color 0.3s;
  }

  a:hover {
    color: cadetblue;
  }
</style>
