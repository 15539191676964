import { acceptHMRUpdate, defineStore } from 'pinia';

// interface Props {
/**
 * @description Minimum swipe down pixel count for sheet to close itself
 *
 * @default { 100 }
 */
// threshold: number

/**
 * @description By default sheet listens swipe on screen, if
 *              this prop given it will listen only header.
 *
 * @default { false }
 */
// onlyHeaderSwipe: boolean

/**
 * @description By default sheet stretches itself up over-swipe,
 *              this prop disables it
 *
 * @default { false }
 */
// noStretch: boolean

/**
 * @description If given Sheet won't close itself on click outside
 *
 * @default { false }
 */
// noClickOutside: boolean

/**
 * @description Removes header section, ignores #header slot
 *
 * @default { false }
 */
// noHeader: boolean

/**
 * @description Handles sheet visibility, should be used as `v-model:visible`.
 */
// visible: boolean

export const useBottomSheetStore = defineStore('useBottomSheet', {
  state: () => {
    return {
      isVisible: false,
      isCommentOptions: false,
      isMyComment: false,
    };
  },
  actions: {
    setIsVisible(data) {
      this.isVisible = data;
    },
    setUpdate(data) {
      this.isCommentOptions = data;
    },
    setUpdateIsMyComment(data) {
      this.isMyComment = data;
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useBottomSheetStore, import.meta.hot));
