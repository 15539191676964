import { acceptHMRUpdate, defineStore } from 'pinia';

export const useChannelStore = defineStore('useChannel', {
  state: () => {
    return {
      channelInformation: null,
      userList: [],
      arrangerInfo: [], //채팅주선자
    };
  },
  actions: {
    setChannelInformation(data) {
      this.channelInformation = data;
    },
    setUserList(data) {
      this.userList = data;
    },
    setArrangerInfo(data) {
      this.arrangerInfo = data;
    },
    reset() {
      this.channelInformation = null;
    },
  },
  getters: {},
  persist: {
    strategies: [
      {
        key: 'useChannel',
        storage: localStorage,
        paths: ['channelInformation'],
      },
    ],
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useChannelStore, import.meta.hot));
