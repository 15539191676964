<script setup>
  import { onMounted, reactive } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const props = defineProps({
    title: String,
    data: Object,
  });
  const state = reactive({
    title: props.title,
    isPriv: false,
  });
  onMounted(() => {
    state.isPriv = route.path.includes('private');
  });
</script>

<template>
  <div class="tag_wrap">
    <div class="board_title flex-center-2">
      <img v-if="props.data.type === 'priv' || state.isPriv" alt="" class="icons" src="@/assets/img/community_icon.svg" />
      <span v-if="props.data.type === 'priv' || state.isPriv" class="badge">{{ props.data.category_name }}</span>
      <span>{{ props.data.community_name }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .tag_wrap {
    margin-bottom: 8px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .notice_text {
      color: #5d688a;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.36px;
      margin-left: 2px;
    }
    .bage_con {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icons {
        width: 14px;
      }
      .text {
        padding: 0 4px;
        min-width: 32px;
        height: 16px;
        border-radius: 4px;
        background: #f3f4f7;
        color: #5d688a;
        text-align: center;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.27px;
      }
    }
  }
</style>
