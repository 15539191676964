<script setup>
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';
  import { useVoteStore } from '@/stores/useVoteStore';
  import { computed, onMounted, reactive } from 'vue';
  import { formatDate, formatTime } from '@/utill/common';
  import { voteApiRequest } from '@/api/active/voteApiRequest';
  import { useRoute } from 'vue-router';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import AdvertisementVue from '@Components/common/AdvertisementVue.vue';

  const emit = defineEmits(['close', 'handleVoteSelect']);
  const useVote = useVoteStore();
  const userInfo = useUserInfoStore();
  const route = useRoute();
  const state = reactive({
    isVote: false,
    formData: {},
    activeIdx: null,
    voteName: null,
    banner_kind: 'vote',
  });

  const handleVoteSelect = (idx, vote) => {
    state.activeIdx = state.activeIdx === idx ? '' : idx;
    state.voteName = state.voteName === vote ? '' : vote;
    console.log(state.voteName, state.activeIdx);
    state.formData.vote_info_id = useVote.votePostData.vote_info_id;
    state.formData.vote_item_name = state.voteName;
    emit('handleVoteSelect', state.formData);
  };

  // 백분율 계산을 위한 computed 속성
  const getPercentage = votes => {
    return computed(() => {
      if (useVote.votePostData.activeVote === 0) {
        return '0.00';
      }
      return ((votes / useVote.votePostData.activeVote) * 100).toFixed(2);
    });
  };

  // 그라데이션 스타일을 위한 computed 속성
  const getGradientStyle = vote => {
    const percentage = getPercentage(vote.count); // 백분율 계산

    const bgColor = (useVote.votePostData.vote_item_name && useVote.votePostData.vote_item_name === vote.name) || route.query.status === 'ing' ? '#F7F3FF' : '#F9F9FA';
    console.log(useVote.votePostData, 'useVote.votePostData');
    console.log(percentage.value);
    return computed(() => {
      return {
        background: `linear-gradient(to right, ${bgColor} ${percentage.value}%, white ${percentage.value}%)`,
      };
    });
  };

  const setStateColor = idx => {
    return {
      selected: state.activeIdx === idx || (route.query.status === 'ing' && useVote.isParticipation),
      vote: (useVote.isParticipation && state.activeIdx !== idx) || route.query.status === 'complete',
    };
  };
  onMounted(() => {
    console.log(useVote.votePostData);
    state.voteName = useVote.votePostData.vote_item_name;
    state.activeIdx = useVote.votePostData.vote_items.findIndex(item => item.name === state.voteName);

    console.log(state.activeIdx);
  });
</script>

<template>
  <section class="post_section con">
    <div class="inner top_inner">
      <div class="member_item">
        <!-- 커뮤니티 타이틀-->
        <div class="board_title flex-center-2">
          <img v-if="useVote.votePostData.comm_kind === 'priv'" alt="" class="icons" src="@/assets/img/community_icon.svg" />
          <span v-if="useVote.votePostData.comm_kind === 'priv'" class="badge">{{ useVote.votePostData.category_name }}</span>
          <span>{{ useVote.votePostData.comm_name ? useVote.votePostData.comm_name : useVote.votePostData.name }}</span>
        </div>
        <!-- 유저 정보-->
        <div class="user_pf board">
          <!-- 유저 정보 :: 프로필-->
          <div :class="{ citation: useVote.votePostData.certified || useVote.votePostData.vote_regist_certified !== '일반회원' }" class="pf_img small">
            <img v-if="useVote.votePostData.img_path" :alt="useVote.votePostData.nickname + '이미지'" :src="useVote.votePostData.img_path" />
            <img v-else alt="기본 유저 프로필 사진" src="@/assets/img/ico_my_pf.png" />
          </div>
          <div class="user_top">
            <span class="name">{{ useVote.votePostData.nickname }}</span>
            <div class="flex-center-4 post">
              <span>{{ useVote.votePostData.grade_name || useVote.votePostData.vote_grade_name }}</span
              ><i class="dot"></i><span>{{ formatTime(useVote.votePostData.insert_date) }}</span>
            </div>
          </div>
        </div>
        <!-- 컨텐츠-->
        <div class="post_con">
          <h6 class="title ellipsis1 end">
            <span v-if="route.query.status" :class="useVote.votePostData.vote_statue === '진행중' ? 'txt-purple' : 'txt-g30'">{{
              useVote.votePostData.vote_statue === '진행중' ? '[진행중]' : '[종료]'
            }}</span>
            {{ useVote.votePostData.vote_title }}
          </h6>
        </div>
        <!-- 활동 컨텐츠 -->
        <div class="activity_con">
          <!-- 활동 컨텐츠 :: 투표 배너 -->
          <AdvertisementVue :banner_kind="state.banner_kind" />
          <!-- 활동 컨텐츠 :: 투표 리스트 -->
          <div class="flex-8 column">
            <button
              v-for="(vote, idx) in useVote.votePostData.vote_items"
              :class="setStateColor(idx)"
              :disabled="useVote.isParticipation || useVote.votePostData.user_info_id === userInfo.memberInfo.user_info_id"
              :style="useVote.isParticipation ? getGradientStyle(vote).value : ''"
              class="select_btn"
              type="button"
              @click="handleVoteSelect(idx, vote.name)"
            >
              <span class="ellipsis1">
                {{ vote.name }}
              </span>
              <span v-if="useVote.isParticipation || route.query.status" class="ml-auto count person_icon">{{ useVote.isParticipation || route.path.includes('voteDetail') ? vote.count : '' }}</span>
            </button>
          </div>
          <!-- 활동 컨텐츠 :: 투표 정보 박스 -->
          <ul class="round_color_box coin_box v2">
            <li>
              <span>투표지역</span><span>{{ useVote.votePostData.vote_location }}</span>
            </li>
            <li>
              <span>참여 인원</span><span>{{ useVote.votePostData.activeVote }}/{{ useVote.votePostData.vote_person }}명</span>
            </li>
            <li class="hidden">
              <span>참여 Scoin</span><span class="flex-center-2 coin_badge"><img src="@/assets/img/point_icon.svg" /> {{ useVote.votePostData.vote_point.toLocaleString() }}</span>
            </li>
            <li>
              <span>투표 기간</span><span>{{ formatDate(useVote.votePostData.vote_start_date, 'yy.mm.dd') }} ~ {{ formatDate(useVote.votePostData.vote_end_date, 'yy.mm.dd') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/community.scss';
  .disabled,
  [disabled] {
    opacity: 1 !important;
  }
  .title {
    height: auto;
    display: block;
  }

  .post_section {
    margin-top: 0;

    .member_item {
      border: none;
    }

    &:deep {
      .advertisement_wrap {
        padding: 0;
      }
    }
  }
</style>
