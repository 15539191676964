import { acceptHMRUpdate, defineStore } from 'pinia';

export const useCommnetListStore = defineStore('useCommnetList', {
  state: () => {
    return {
      commentCount: 0,
      communityType: '',
      communityInfo: null,
      commentList: [],
      commentAdd: false,
      commentType: '',
      commentInfo: null,
      commentEdit: false,
      commentEditType: '',
      commentDelType: '',
      isItAParentOrAChild: '',
    };
  },
  actions: {
    setUpdateCommentCount(data) {
      this.commentCount = data;
    },
    setUpdateIsItAParentOrAChild(data) {
      this.isItAParentOrAChild = data;
    },
    setUpdateCommentDelType(data) {
      this.commentDelType = data;
    },
    setUpdateCommentEditType(data) {
      this.commentEditType = data;
    },
    setUpdateCommentEdit(data) {
      this.commentEdit = data;
    },
    setUpdateCommunityType(data) {
      this.communityType = data;
    },
    setUpdateCommentInfo(data) {
      this.commentInfo = data;
    },
    setUpdateCommentType(data) {
      this.commentType = data;
    },
    setCommunityInfoUpdate(data) {
      this.communityInfo = data;
    },
    setCommentListUpdate(data) {
      this.commentList = data;
    },
    setCommentAddUpdate(data) {
      this.commentAdd = data;
    },
    reset() {
      this.commentAdd = false;
      this.commentType = '';
      this.commentEdit = false;
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCommnetListStore, import.meta.hot));
