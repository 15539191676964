<script>
import stepVoteStyled from '@/styled/stepVoteStyled';

export default {
  components: {
    stepVoteStyled,
  },
};
</script>
<script setup>
import {useLoadingStore} from '@/stores/useLoadingStore';
import {onMounted, watch} from 'vue';
import TopHeaderVue from '@Components/common/TopHeaderVue.vue';
import {useRoute, useRouter} from 'vue-router';
import {useVoteStore} from '@/stores/useVoteStore';

const loading = useLoadingStore();
const route = useRoute();
const router = useRouter();
const useVote = useVoteStore();

watch(route, (newRoute, oldRoute) => {
  console.log(`Route changed from ${oldRoute.fullPath} to ${newRoute.fullPath}`);
});
const handleBack = () => {
  router.go(-1);
  // useVote.reset();
  // router.go('/');
};
onMounted(() => {
  loading.setUpdateIsLoadData(true);
  console.log(route);
});
</script>

<template>
  <div class="wrapper">
    <TopHeaderVue title="투표 등록" fuc="true" @close="handleBack"/>
    <div class="step_wrap">
      <stepVoteStyled>
        <router-view></router-view>
      </stepVoteStyled>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step_wrap {
  padding: 0 16px;


}
.wrapper{
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  &:deep .control_wrap{
    max-width: 500px;
    width: 100%;
    left:50%;
    transform: translateX(-50%);
  }
}
</style>
