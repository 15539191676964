// 숫자와 콤마를 제외한 모든 문자를 제거하는 함수
export function removeNonNumeric(input) {
    return input.replace(/[\D,]/g, '');
}

export function removeSpecialChar(str) {
    // 특수문자를 제외하고 문자와 숫자만 허용
    return str.replace(/[^\w\s]/gi, '');
}

export function removeNonNumericDash(input) {
    return input.replace(/[^\d-]/g, '');
}
