import { acceptHMRUpdate, defineStore } from 'pinia';

export const useLoadingStore = defineStore('useLoading', {
  state: () => {
    return {
      isLoadData: false,
    };
  },
  getters: {},
  actions: {
    setUpdateIsLoadData(data) {
      this.isLoadData = data;
    },
    reset() {
      this.id_token = false;
    },
  },
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
