<script setup>
  import { computed, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { usePostStore } from '@/stores/usePostStore';
  import ForbiddenWordPopup from '@Components/popup/ForbiddenWordPopup.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { useLoadingStore } from '@/stores/useLoadingStore';

  const selectTab = useAddCommunityStore();
  const usePost = usePostStore();
  const usePopup = usePopupStore();
  const imageFileInputs = ref();
  const loading = useLoadingStore();
  const router = useRouter();
  const route = useRoute();
  const state = reactive({
    selectCommunity: '',
    isInfoBox: false,
    contentTitle: null,
    contentValue: null,
    contentValueCount: 0,
    hashTag: null,
    hashTagArra: [],
    formData: {
      keywords: [],
      upload_images: [],
    },
    forbiddenWord: '',
    inputsFocus: [
      {
        isActive: false,
      },
      {
        isActive: false,
      },
      {
        isActive: false,
      },
      {
        isActive: false,
      },
    ],
    imageArray: [],
    isCheck_1: false,
    isCheck_2: false,
    isOk: false,
    youTubeUrlValue: '',
    isSelectCommunity: '',
  });

  const handleSetHashTag = () => {
    if (!state.hashTag.includes('#')) {
      tostAlert('# 필수 입력입니다.');
      state.hashTag = state.hashTag.replace(state.hashTag, '');
      return;
    }

    const hashtags = state.hashTag.split('#').filter(tag => tag !== '');
    state.formData.keywords = hashtags;
    if (state.formData.keywords.length > 5) {
      tostAlert('최대 5개 까지 입력 가능 합니다');
      state.formData.keywords.pop();
      return;
    }
    console.log('=>(CommuniyuAddVue.vue:34) state.formData.keywords', state.formData.keywords.length);
  };
  const handleFocus = idx => {
    state.inputsFocus.at(idx).isActive = !state.inputsFocus.at(idx).isActive;
  };
  const handleCheck = idx => {
    if (idx === 0) {
      state.isCheck_1 = !state.isCheck_1;
    } else if (idx === 1) {
      state.isCheck_2 = !state.isCheck_2;
    }
  };
  const handleGetImageFile = () => {
    imageFileInputs.value.click();
  };
  const handleGetFile = () => {
    const files = imageFileInputs.value.files;
    console.log('=>(MeetingAddCommunityVue.vue:44) files', files);

    const readImageFile = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => resolve(event.target.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const displayImageUrls = async () => {
      try {
        for (const file of files) {
          const imageUrl = await readImageFile(file);
          state.imageArray.push({
            files: file,
            imageUrl: imageUrl,
          });
        }
      } catch (error) {
        console.error('Error reading image file:', error);
      }
    };

    displayImageUrls();
  };
  const handleDelImage = idx => {
    state.imageArray.splice(idx, 1);
  };

  // 커뮤니티 추가
  const handleAddCommunity = () => {
    selectTab.setUpdateGubun('ADD_COMMUNITY');
    const saveData = {
      ...state,
    };
    usePost.setPostData(saveData);
    router.push('/add/select');
  };

  function extractVideoID(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch(?:\/|\/?\?(?:\&?v\=))|(?:embed|v|vi|shorts)\/))([^\?&"'>]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  watch(
    () => state.imageArray.length,
    newValue => {
      if (newValue > 10) {
        state.imageArray.pop();
        tostAlert('이미지는 최대 10개 까지 업로드 가능 합니다');
      }
    },
  );
  //유효성체크
  const isFormDataComplete = computed(() => {
    const isComplete = state.formData.title && state.formData.content && state.formData.keywords.length >= 1;

    return isComplete;
  });
  // 게시물 쓰기
  const handleSubmit = () => {
    console.log('=>(CommuniyuAddVue.vue:123) state.formData.title', state.formData.title);
    console.log('=>(CommuniyuAddVue.vue:125) state.formData.content', state.formData.content);
    console.log('=>(CommuniyuAddVue.vue:125) state.formData.keywords', state.formData.keywords);
    console.log('=>(CommuniyuAddVue.vue:126) state.isCheck_1', state.isCheck_1);
    console.log('=>(CommuniyuAddVue.vue:127) state.isCheck_2', state.isCheck_2);
    console.log('=>(CommuniyuAddVue.vue:129) state.formData.youtube_url', state.formData.youtube_url);

    if (!state.formData.title) {
      tostAlert('제목을 입력 해주세요');
      return;
    }
    if (!state.formData.content) {
      tostAlert('내용 을 입력 해주세요');
      return;
    }
    if (state.formData.keywords.length <= 0) {
      tostAlert('1개 이상의 해쉬 태그를 입력 해주세요');
      return;
    }

    if (state.formData.youtube_url) {
      state.formData.youtube_url = extractVideoID(state.formData.youtube_url);
    }
    console.log('=>(CommuniyuAddVue.vue:157) state.imageArray', state.imageArray);
    if (state.imageArray.length > 0) {
      state.formData.upload_images = state.imageArray.map(item => item.files);
    }
    console.log(state.imageArray, 'state.imageArray');
    console.log(state.selectCommunity);
    const requestData = {
      type: state.selectCommunity.type,
      c_info_id: state.selectCommunity.id.c_info_id,
      title: state.formData.title,
      content: state.formData.content,
      youtube_url: state.formData.youtube_url ? state.formData.youtube_url : '',
      keywords: state.formData.keywords,
      upload_images: state.formData.upload_images.length > 0 ? state.formData.upload_images : [],
      ai_use: state.isCheck_1 ? 'true' : 'false',
      status: state.isCheck_2 ? 'true' : 'false',
    };
    loading.setUpdateIsLoadData(false);
    CommunityApiRequest.addCommunityRequest(requestData)
      .then(res => {
        console.log('=>(CommuniyuAddVue.vue:140) res', res);
        if (res.code === 200) {
          tostAlert('게시글 등록을 완료 했습니다.');
          selectTab.restCommunitySelect();
          usePost.reset();
          router.push('/');
        } else if (res.code === -200) {
          state.forbiddenWord = res.data.at(0).banned_word;
          usePopup.popups.isWordPopup = true;
        } else {
          tostAlert(res.message);
        }
      })
      .finally(() => {
        loading.setUpdateIsLoadData(true);
      });
  };

  onMounted(() => {
    document.body.style.overflow = 'unset';

    if (Object.keys(usePost.postFormData).length > 0) {
      Object.assign(state, usePost.postFormData);
    }

    if (selectTab.communitySelect) {
      state.selectCommunity = selectTab.communitySelect;
      if (state.selectCommunity.value && state.selectCommunity.value.hasOwnProperty('community_name')) {
        state.isSelectCommunity = state.selectCommunity.value.community_name;
        console.log(state.selectCommunity.value.community_name);
        console.log(state.isSelectCommunity);
        state.selectCommunity.id = state.selectCommunity.value.c_info_id;
      } else {
        state.isSelectCommunity = state.selectCommunity.value;
      }
    }
  });
</script>

<template>
  <div class="community_add_wrap">
    <div v-if="!state.isInfoBox" class="info_box">
      <button class="close_btn" type="button" @click="state.isInfoBox = true">
        <img alt="" src="@/assets/img/close_icon.svg" />
      </button>
      <p class="info_text">글 작성하기 전에 알려드려요.</p>
      <span class="info_text_2">거래, 명예훼손, 광고/홍보 글은 올리실 수 없어요.</span>
    </div>
    <div class="form_content">
      <div class="form_list">
        <div class="form_list_item">
          <button :class="{ select: state.selectCommunity.value }" class="community_select" type="button" @click="handleAddCommunity">
            <span class="text"> {{ state.isSelectCommunity ? state.isSelectCommunity : '업로드할 커뮤니티 선택' }}</span>
            <img alt="" src="@/assets/img/gray_right_iocn.svg" />
          </button>
        </div>
        <div :class="{ active: state.inputsFocus[0].isActive }" class="default_input inputs">
          <input v-model="state.formData.title" class="title_input" maxlength="60" placeholder="제목을 작성해 주세요. (최대 60자)" type="text" @focus="handleFocus(0)" @focusout="handleFocus(0)" />
        </div>
        <div :class="{ active: state.inputsFocus[1].isActive }" class="default_txtAr q-mt-sm">
          <textarea v-model="state.formData.content" class="text_area_tag" maxlength="800" placeholder="내용을 작성해 주세요." />
        </div>
        <div class="content_count q-mt-sm">
          <p>
            <span class="count">{{ state.formData.content ? state.formData.content.length : '0' }}</span
            >/800
          </p>
        </div>
      </div>
      <div :class="{ active: state.inputsFocus[2].isActive }" class="you_tube_insert inputs default_input">
        <input v-model="state.formData.youtube_url" class="bordered" placeholder="유튜브 URL를 입력해 주세요. (선택)" type="text" @focus="handleFocus(2)" @focusout="handleFocus(2)" />
      </div>
      <div :class="{ active: state.inputsFocus[3].isActive }" class="hash_tag_insert inputs">
        <input v-model="state.hashTag" class="hash_tag_input" placeholder="#해시태그 (최대 5개)" type="text" @focus="handleFocus(3)" @focusout="handleFocus(3)" @input="handleSetHashTag" />
      </div>
      <div v-if="state.formData.keywords.length > 0" class="hash_tag_array">
        <span v-for="(tag, idx) in state.formData.keywords" :key="idx" class="hash_tag">{{ `#${tag}` }}</span>
      </div>
      <div class="image_con">
        <input ref="imageFileInputs" accept=".webp,jpeg,.jpg,.png" multiple style="display: none" type="file" @change="handleGetFile" />
        <button class="image_add_btn" type="button" @click="handleGetImageFile">
          <img alt="" src="@/assets/img/add_image_plus.svg" />
          <span class="image_count_tag"
            ><span class="image_count">{{ state.imageArray.length }}</span
            >/10</span
          >
        </button>
        <div class="image_array_con">
          <div v-for="(imageFile, idx) in state.imageArray" :key="idx" class="image_con">
            <button class="del_btn" type="button" @click="handleDelImage(idx)">
              <img alt="" class="icon" src="@/assets/img/del_icons.svg" />
            </button>
            <img :src="imageFile.imageUrl" alt="" class="add_image" />
          </div>
        </div>
      </div>
      <div class="check_list_con">
        <ul class="check_list">
          <li class="check_list_item" @click="handleCheck(0)">
            <span class="check_icon">
              <img v-if="!state.isCheck_1" alt="" src="@/assets/img/check_icon_off.svg" />
              <img v-if="state.isCheck_1" alt="" src="@/assets/img/check_icon_on.svg" />
            </span>
            <span class="check_text">Seemple AI 답변 받기</span>
          </li>
          <li class="check_list_item" @click="handleCheck(1)">
            <span class="check_icon">
              <img v-if="!state.isCheck_2" alt="" src="@/assets/img/check_icon_off.svg" />
              <img v-if="state.isCheck_2" alt="" src="@/assets/img/check_icon_on.svg" />
            </span>
            <span class="check_text">나만 보기</span>
          </li>
        </ul>
      </div>
      <div class="btm_btn fixed_btn">
        <button :disabled="!isFormDataComplete" class="default_btn" type="button" @click="handleSubmit">확인</button>
      </div>
    </div>
  </div>

  <!-- 금칙어 팝업 -->
  <ForbiddenWordPopup v-if="usePopup.popups.isWordPopup" :forbiddenWord="state.forbiddenWord" @close="usePopup.popups.isWordPopup = false" />
</template>

<style lang="scss" scoped>
  .community_add_wrap {
    padding: 0 16px 100px;

    .info_box {
      margin-bottom: 30px;

      .close_btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;

        img {
          width: 100%;
        }
      }

      position: relative;
      height: 82px;
      border-radius: 10px;
      background: #f7f3ff;
      padding: 16px;

      .info_text {
        color: #6728ff;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.48px;
        margin-bottom: 4px !important;
      }

      .info_text_2 {
        color: #515561;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.42px;
      }
    }

    .form_content {
      .image_con {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        //align-content: center;
        .image_add_btn {
          width: 58px;
          flex-basis: 58px;
          height: 58px;
          margin-right: 6px;
          border-radius: 16px;
          background: #f3f4f7;

          .image_count_tag {
            display: inline-block;
            width: 100%;
            color: #515561;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.3px;

            .image_count {
              color: #6728ff;
              text-align: center;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.3px;
            }
          }
        }

        .image_array_con {
          width: calc(100% - 66px);
          flex-basis: calc(100% - 66px);
          display: flex;
          justify-content: flex-start;
          //align-content: center;
          gap: 6px;
          flex-wrap: wrap;

          .image_con {
            margin-top: 0;
            position: relative;
            width: 58px;
            height: 58px;
            border-radius: 16px;
            border: solid 1px #f3f4f7;
            overflow: hidden;
            &:not(:last-child) {
              margin-right: 6px;
            }

            .add_image {
              width: 100%;
              aspect-ratio: 1/1;
              object-fit: cover;
            }

            .del_btn {
              position: absolute;
              top: 6px;
              right: 6px;

              .icon {
              }
            }
          }
        }
      }

      .you_tube_insert {
        margin-top: 52px;
        border-radius: 10px;

        .you_tube_input {
          padding: 14px;
          border-radius: 10px;
          display: block;
          width: 100%;
          height: 50px;
          border: none;
          appearance: none;
          outline: none;
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.42px;

          &::placeholder {
            color: #9b9ea9;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
          }
        }
      }

      .hash_tag_insert {
        margin-top: 8px;
        border-radius: 10px;
        border: 1px solid #f3f4f7;

        .hash_tag_input {
          padding: 14px;
          border-radius: 10px;
          display: block;
          width: 100%;
          height: 50px;
          border: none;
          appearance: none;
          outline: none;
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.42px;

          &::placeholder {
            color: #9b9ea9;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
          }
        }

        &.inputs {
          &.active {
            border-color: #7740ff;
          }
        }
      }

      .form_list {
        .content_count {
          color: #9b9ea9;
          text-align: right;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.36px;

          .count {
            color: #6728ff;
            text-align: right;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.36px;
          }
        }

        .form_list_item {
          border-bottom: solid 1px #f3f4f7;
          height: 50px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &.text_area {
            height: 228px;

            .text_area_tag {
              display: block;
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              appearance: none;
              resize: none;
            }
          }

          .community_select {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .text {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }

            &.select {
              .text {
                color: #000;
                font-family: Spoqa Han Sans Neo;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.42px;
              }
            }
          }

          .title_input {
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            appearance: none;
            outline: none;
            color: #000eqwe;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;

            &::placeholder {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }
          }
        }
      }

      .hash_tag_array {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;

        .hash_tag {
          margin-top: 8px;
          height: 30px;
          display: inline-block;
          color: #6728ff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.36px;
          padding: 0 8px;
          background: #f7f3ff;
          border-radius: 10px;

          &:not(:first-child) {
            margin-left: 6px;
          }
        }
      }

      .image_content {
        margin-top: 24px;

        .image_add {
          display: flex;
          justify-content: space-between;
          align-content: center;
          flex-wrap: wrap;
          //width: 58px;
          //height: 58px;
          padding: 14px 18px;
          border-radius: 16px;
          border: 1px solid var(--blue-gray10, #f3f4f7);
          background: var(--blue-gray10, #f3f4f7);

          .image_con {
            width: 100%;
          }

          .image_count_con {
            width: 100%;
            text-align: center;

            .image_count {
              color: #6728ff;
              text-align: center;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.3px;
            }

            .total_count {
              color: #515561;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.3px;
            }
          }
        }
      }

      .check_list_con {
        margin-top: 24px;

        .check_list {
        }

        .check_list_item {
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        .check_icon {
          margin-right: 8px;
        }

        .check_text {
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.42px;
        }
      }

      .submit_con {
        margin-top: 16px;
        margin-bottom: 16px;

        .submit_btn {
          display: block;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background: #dcceff;
          color: #fff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.42px;

          &.submit_ready {
            background: #6728ff;
          }
        }
      }
    }
  }
</style>
