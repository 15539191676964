import { acceptHMRUpdate, defineStore } from 'pinia';

export const useMeetingStore = defineStore('useMeeting', {
  state: () => {
    return {
      address: null,
      selectData: {},
      meetingFormData: {}, // 등록 페이지 데이터
      meetingPostData: {}, //상세 페이지 데이터
      meetingApplicant: [], //미팅 선정 리스트
      locationData: null,
      isComplete: false,
    };
  },
  actions: {
    setMeetingFormData(data) {
      this.meetingFormData = data;
    },
    setMeetingPostData(data, participation) {
      this.meetingPostData = data;
    },
    setMeetingApplicant(data) {
      this.meetingApplicant = data;
    },
    seyUpdateAddress(data) {
      this.address = data;
    },
    setRegLocationData(data) {
      this.locationData = data;
    },
    setUpdateSelectData(data) {
      this.selectData = data;
    },
    reset() {
      this.address = null;
      this.selectData = {};
    },
    resetMeetingFormData() {
      this.setMeetingFormData({});
      localStorage.removeItem('useMeeting');
    },
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      {
        storage: window.localStorage,
        paths: ['address '],
      },
    ],
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useMeetingStore, import.meta.hot));
