import { acceptHMRUpdate, defineStore } from 'pinia';

export const useRouterLinkStore = defineStore('useRouterLink', {
  state: () => {
    return {
      isActive: 'HOME',
    };
  },
  actions: {
    setUpdateRouterActive(data) {
      this.isActive = data;
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useRouterLinkStore, import.meta.hot));
