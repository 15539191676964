import styled from 'vue3-styled-components';

const stepVoteStyled = styled('div')`
  .form_wrap {
    margin-top: 40px;
    .input_label_title {
      color: #000;
      font-family: Spoqa Han Sans Neo;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.18px;
    }
  }
  .select_list {
    margin-top: 16px;
    .select {
      position: relative;
      height: 50px;
      border-bottom: solid 1px #f3f4f7;
      color: #9b9ea9;
      font-family: Spoqa Han Sans Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: -0.42px;
      &.select_value {
        color: #000;
      }
      .icons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
      }
    }
  }
  .select_type {
    margin-top: 24px;
    background: #f7f3ff;
    color: #6728ff;
    font-family: Spoqa Han Sans Neo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-left: 16px;
    line-height: 56px;
    letter-spacing: -0.48px;
    border-radius: 10px;
    height: 56px;
  }
  .title {
    margin-top: 24px;
    color: #000;
    font-family: Spoqa Han Sans Neo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.18px;
  }
  .control_wrap {
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 0 16px;
    .next_btn {
      height: 50px;
      width: 100%;
      border-radius: 10px;
      background: #dcceff;
      &.next {
        background: #6728ff;
      }
      color: #fff;
      text-align: center;
      font-family: Spoqa Han Sans Neo;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
    }
  }
  .step_one_wrapper {
    margin-top: 24px;
    .value_list {
      .value_list_item {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        .value_btn {
          display: inline-block;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          border: solid 1px #f3f4f7;
          box-sizing: border-box;
          text-align: left;
          padding-left: 16px;
          color: #000;
          font-family: Spoqa Han Sans Neo;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.48px;
          &.select {
            background: #f7f3ff;
            color: #6728ff;
          }
        }
      }
    }
  }
`;

export default stepVoteStyled;
