import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { createBottomSheet } from 'bottom-sheet-vue3';
import { Loading, Notify, QBtn, QDrawer, QFooter, QHeader, QIcon, QLayout, QPage, QPageContainer, QPageScroller, QPageSticky, QSeparator, QSpace, QToolbar, QToolbarTitle, Quasar } from 'quasar';
import App from './App.vue';
import './assets/reset.css';
import './index.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// import 'bottom-sheet-vue3/style.css'
import 'quasar/dist/quasar.css';
import vue3GoogleLogin from 'vue3-google-login';
import VueScrollPicker from 'vue-scroll-picker';
import SecureLS from 'secure-ls';
import router from './router';
import { createI18n } from 'vue-i18n';
import messages from '@/i18n/';
import VueClipboard from 'vue-clipboard3';
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';
const i18n = createI18n({
  locale: 'ko',
  fallbackLocale: 'en',
  messages,
});
const app = createApp(App);
// 에러 핸들러 함수
const errorHandler = error => {
  console.error('Routing error:', error);
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.reload();
  }
};

// 전역 에러 핸들러 등록
app.config.errorHandler = errorHandler;
app.component('infinite-loading', InfiniteLoading);
app.component('VueDatePicker', VueDatePicker);
const pinia = createPinia();
let ls = new SecureLS({ isCompression: true });
pinia.use(
  createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key),
    },
  }),
);

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(createMetaManager());
app.use(createBottomSheet());
app.use(Quasar, {
  runMode: 'web-client',
  plugins: {
    Loading,
    Notify,
    QLayout,
    QPageContainer,
    QPage,
    QHeader,
    QFooter,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QSpace,
    QSeparator,
    QDrawer,
    QPageSticky,
    QPageScroller,
  },
  animations: 'all',
});
app.use(VueScrollPicker);
app.use(VueClipboard);
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_KEY,
  prompt: false,
  autoLogin: false,
});
window.Kakao.init(import.meta.env.VITE_KAKAO_KEY);
app.mount('#app');
