<script setup>
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';
  import { useVoteStore } from '@/stores/useVoteStore';
  import { computed, onMounted, reactive } from 'vue';
  import { formatDate, formatTime } from '@/utill/common';
  import { voteApiRequest } from '@/api/active/voteApiRequest';
  import VoteDetailComponent from '@Components/manage/VoteDetailComponent.vue';

  const emit = defineEmits(['close']);
  const useVote = useVoteStore();
  const state = reactive({
    isVote: false,
    formData: {},
    activeIdx: null,
    voteName: null,
  });
  const handleClose = () => {
    emit('close');
  };
  const handleVoteSelect = formData => {
    state.formData = formData;
  };

  //투표 전송 후 결과 호출
  const handleVoteResult = () => {
    voteApiRequest.getVoteResult({ vote_info_id: state.formData.vote_info_id }).then(res => {
      if (res.code === 200) {
        useVote.setVotePostData(res.data, true);
      }
      if (res.code === 202) {
        tostAlert(res.message);
      }
    });
  };
  const handleSubmit = () => {
    state.formData.vote_info_id = useVote.votePostData.vote_info_id;
    state.formData.vote_item_name = state.formData.vote_item_name;

    voteApiRequest
      .registerVote(state.formData)
      .then(res => {
        if (res.code === 200) {
          tostAlert('투표 참여 완료했습니다.');
          handleVoteResult();
        }
      })
      .catch(err => {
        if (err.response.data.code === 500) {
          tostAlert(err.response.data.message);
          handleClose();
        }
      });
  };

  onMounted(() => {});
</script>

<template>
  <defaultPopupStyles>
    <PopHeaderVue title="투표하기" @close="handleClose" />
    <VoteDetailComponent @handleVoteSelect="handleVoteSelect" />
    <div class="popup_footer">
      <button v-if="!useVote.isParticipation" :disabled="state.activeIdx === ''" class="default_btn" type="button" @click="handleSubmit">투표하기</button>
      <button v-else class="default_btn" type="button" @click="handleClose">확인</button>
    </div>
  </defaultPopupStyles>
</template>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/community.scss';

  .title {
    height: auto;
    display: block;
  }

  .post_section {
    margin-top: 0;

    .member_item {
      border: none;
    }

    &:deep {
      .advertisement_wrap {
        padding: 0;
      }
    }
  }
</style>
