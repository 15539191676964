import { acceptHMRUpdate, defineStore } from 'pinia';
import userApi from '@/api/user/userApi';
import { AuthApiRequest } from '@/api/authApiRequest';

export const useScoinStore = defineStore('useScoin', {
  state: () => {
    return {
      scoin: '',
      meetingUseCoin: '',
      coinHistory: [],
      availableCoin: [],
      insufficientCoin: [],
    };
  },
  getters: {},
  actions: {
    setMeetingUseCoin(data) {
      this.meetingUseCoin = data;
    },
    setAvailableCoins() {
      AuthApiRequest.checkAvailablePoints().then(res => {
        this.availableCoin = res.data.point;
      });
    },
  },
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useScoinStore, import.meta.hot));
