<script setup></script>

<template>
  <div class="loading_componet_wrap">
    <div class="loader_con">
      <span class="loader"></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .loading_componet_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999999;
  }
  .loader_con {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .loader {
      width: 32px;
      height: 32px;
      border: 6px solid #ebecef;
      border-bottom-color: transparent;
      border-radius: 50%;

      animation: rotation 1s linear infinite;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
