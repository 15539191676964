import axios from '@/axios';

export default {
  //멤버 정보
  fetchUserInfo() {
    return axios.post('/user/member/info');
  },
  // 코인 출전 내역
  scoinHistory(param) {
    return axios.post('/user/scoin/log/list', param);
  },
  // 코인 출전 내역
  scoinWithdrawApply(param) {
    return axios.post('/withdraw/request', param);
  },
  //코인 출금 은행
  withdrawBankList() {
    return axios.get('/withdraw/bank-list');
  },
  //회원 정보업데이트
  updateUserProfile(param) {
    return axios.post('/user/member/update', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },
  //인증회원 신청
  registerCertifiedMember() {
    return axios.get('/user/priv/auth');
  },
};
