import { acceptHMRUpdate, defineStore } from 'pinia';

export const useCommunitySearchFilterStore = defineStore('useCommunitySearchFilter', {
  state: () => {
    return {
      selectSearchFilterValue: '전체',
      filterList: ['전체', '댓글순', '조회순'],
      mainFilter: {
        offset: 1,
        kind: '',
        order_by: 'all',
        user_info_id: '',
      },
      mainFilterKind: '',
      subFilterKind: '',
    };
  },
  actions: {
    setSelectSearchFilterValue(data) {
      this.selectSearchFilterValue = data;
    },
    setMainFilter(data) {
      this.mainFilter = data;
    },
    setMainFilterKind(data) {
      this.mainFilterKind = data;
    },
    setSubFilterKind(data) {
      this.subFilterKind = data;
      console.log('this.subFilterKind', data);
    },
    resetMainFilter() {
      console.log('reset main filter');
      this.mainFilter = {
        offset: 1,
        kind: '',
        order_by: 'all',
        user_info_id: '',
      };
      this.mainFilterKind = '';
      this.subFilterKind = '';
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCommunitySearchFilterStore, import.meta.hot));
