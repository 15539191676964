import { acceptHMRUpdate, defineStore } from 'pinia';

export const useEditCommunityStore = defineStore('useEditCommunity', {
  state: () => {
    return {
      communityInfo: null,
    };
  },
  actions: {
    setUpdateCommunityInfo(data) {
      console.log(data, 'setUpdateCommunityInfo');
      this.communityInfo = data;
    },
    reset() {
      this.communityInfo = null;
    },
  },
  getters: {},
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useEditCommunityStore, import.meta.hot));
