import axios from 'axios';
import { useUserInfoStore } from '@/stores/useUserInfoStore';

let tokenValue = null;
let isRefreshing = false;
// axios 인스턴스 생성
const request = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  withCredentials: true,
});
// const request_2 = axios.create({
//     baseURL: import.meta.env.VITE_BASE_URL,
//     withCredentials: true,
// });

request.interceptors.request.use(
  async function (config) {
    const auth = await useUserInfoStore();
    tokenValue = `Bearer ${auth.memberToken}`;
    console.log('=>(index.js:20) tokenValue', tokenValue);
    config.headers.Authorization = tokenValue;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  function (response) {
    console.log(response.data);
    console.log('interceptors', 'interceptors');
    // refresh 토큰 검증 요청
    // if (response.data.code === 301) {
    //   isRefreshing = true;
    //   handleTokenRefresh(response)
    //     .then(res => {
    //       isRefreshing = false;
    //       alert('refresh 토큰 검증 요청');
    //       window.location.href = `${import.meta.env.VITE_APP_SCHEME}/auth/join`;
    //     })
    //     .catch(err => {
    //       console.error(err);
    //       isRefreshing = false;
    //     });
    // }
    //
    // // refresh 토큰 만료 처리
    // if (response.data.code === -300) {
    //   alert('123123');
    //   window.location.href = `${import.meta.env.VITE_APP_SCHEME}/auth/join`;
    // }

    return response.data;
  },
  function (error) {
    console.log('=>(index.js:48) error', error);
    if (error.response.status === 500) {
      console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      console.error('----------------------------------------------------------');
      console.error('**********************************************************');
      console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      console.error('-> error', error.code);
      console.error(`이 부분에 에러가 났습니다 ${error.config.url}`);
      console.error(`request 데이타 ${error.config.data}`);
      console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      console.error('----------------------------------------------------------');
      console.error('**********************************************************');
      console.error('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      return Promise.reject(error);
    } else {
      // Extract the response data from the error object
      return Promise.resolve(error.response.data);
    }
  },
);

function handleTokenRefresh(response) {
  console.log('=>(index.js:64) response', response);
  console.log('=>(index.js:64) response', response.config);
  const apiUrl = `${import.meta.env.VITE_BASE_URL}/auth`;

  // Exclude the 'data' property from the request config
  const { data, ...configWithoutData } = response.config;

  // Create a new axios instance for token refresh
  const refreshRequest = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    withCredentials: true,
  });

  // Token refresh request
  return refreshRequest({ ...configWithoutData, url: apiUrl, method: 'get' }).then(res => {
    const auth = useUserInfoStore();
    auth.setMemberToken(res.data.data.access_token);
    tokenValue = `Bearer ${auth.memberToken}`;

    // 이 부분에서 기본 인증 헤더를 갱신합니다.
    request.defaults.headers.Authorization = `Bearer ${auth.memberToken}`;

    // 원래의 요청을 다시 시도합니다.
    return request(response.config);
  });
}

export default request;
