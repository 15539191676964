<script setup>
  import { nextTick, onMounted, onUnmounted, reactive } from 'vue';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import MeetingSelectAreaDetailInfoVue from '@Components/MeetingSelectAreaDetailInfoVue.vue';
  import { useMeetingStore } from '@/stores/useMeetingStore';
  import OverseasSelectVue from '@Components/OverseasSelectVue.vue';
  import { Loader } from '@googlemaps/js-api-loader';
  import HeaderVue from '@Components/common/HeaderVue.vue';
  import SelectedLocation from '@Components/SelectedLocation.vue';
  import markerIcon from '@/assets/img/ic_marker.png';
  const selectTab = useAddCommunityStore();
  const state = reactive({
    isSelect: false,
    isLocationData: false,
    isSelectTab: null,
    location: '',
    placeDetails: null,
    predictions: [],
  });
  const useMeeting = useMeetingStore();
  let autocomplete;
  let map;
  const showMap = async () => {
    let realTimeLocation;
    realTimeLocation = state.placeDetails.address_components.find(country => {
      console.log(country);
      return country.types.includes('country');
    });
    const location = {
      country: realTimeLocation.long_name,
      city: state.placeDetails.formatted_address,
      lat: state.placeDetails.geometry.location.lat(),
      lng: state.placeDetails.geometry.location.lng(),
    };
    useMeeting.setRegLocationData(location);
    // 지도를 표시할 HTML 요소 선택
    const mapElement = document.getElementById('map');

    // Google Maps 객체 생성
    map = new google.maps.Map(mapElement, {
      center: {
        lat: location.lat,
        lng: location.lng,
      }, // 지도 중심 좌표 설정
      disableDefaultUI: true, // 모든 기본 UI 요소 비활성화 (줌, 위성 버튼 등)
      zoom: 13, // 확대/축소 레벨
    });
    // 마커 아이콘 (SVG 사용, 색상 변경 가능)
    const customMarkerIcon = {
      url: markerIcon, // PNG 이미지 경로
    };
    // 기본 마커 추가 (google.maps.Marker 사용)
    const marker = new google.maps.Marker({
      map: map, // 마커를 표시할 지도
      icon: customMarkerIcon, // 커스텀 마커 아이콘 적용 (PNG)
      position: {
        lat: location.lat,
        lng: location.lng,
      }, // 마커 위치
      title: '대한민국 중심', // 마커 제목
    });

    // 지도와 마커 연결
    marker.setMap(map);

    // 반경(원) 추가 (1km 반경)
    const circle = new google.maps.Circle({
      map: map,
      center: {
        lat: location.lat,
        lng: location.lng,
      }, // 원의 중심 좌표
      radius: 1000, // 반경 1km (1000m)
      strokeColor: '#7740FF', // 테두리 색상
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: '#7740FF', // 원 내부 색상
      strokeDashArray: [5, 5],
      fillOpacity: 0.12,
    });

    // 지도와 원 연결
    circle.setMap(map);
  };
  const handleNext = async () => {
    if (!state.placeDetails) {
      tostAlert('미팅 요청 대상자 지역을 선택해 주세요');
      return false;
    }
    console.log(state.isSelect, state.isLocationData);
    if (state.isSelect && state.isLocationData) {
      useMeeting.isComplete = true;
    } else {
      state.isSelect = true;
      await nextTick();
      await showMap();
    }
  };
  const handleDelete = () => {
    state.location = '';
    const input = document.getElementById('place');
    state.placeDetails = null;
    input.value = '';
  };

  let autocompleteService = null;
  const fetchAutocompletePredictions = () => {
    if (!autocompleteService) {
      autocompleteService = new google.maps.places.AutocompleteService();
    }
    if (state.location.length > 0) {
      autocompleteService.getPlacePredictions(
        {
          input: state.location,
          types: [], // 주소와 관련된 결과만 반환
        },
        (predictionsResult, status) => {
          console.log(predictionsResult, status);
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            state.predictions = predictionsResult; // 예측된 주소 리스트 저장
          } else {
            state.predictions = [];
          }
        },
      );
    } else {
      state.predictions = [];
    }
  };

  const handleSelectLocation = async prediction => {
    state.location = prediction.description; // 선택한 주소를 검색어로 설정
    await nextTick();
    state.predictions = [];
  };

  const changeLocationInput = e => {
    state.location = e.target.value;
  };
  onMounted(async () => {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAP__TEST_KEY,
      version: 'weekly',
      language: 'ko',
    });

    const Places = await loader.importLibrary('places');

    const center = { lat: 36.5, lng: -127.5 };
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };

    const input = document.getElementById('place');

    const options = {
      bounds: defaultBounds, //optional
      types: [], // 주소만 검색
      fields: ['all'], // 필요한 필드만 가져옴
      strictBounds: false, //optional
    };

    // per the Google docs create the new instance of the import above. I named it Places.
    autocomplete = new Places.Autocomplete(input, options);

    console.log('autocomplete', autocomplete); //optional log but will show you the available methods and properties of the new instance of Places.
    //add the place_changed listener to display results when inputs change
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        state.placeDetails = place;
        console.log(state.placeDetails);
        state.isLocationData = true;
      }
    });
  });
  // unMounted 시 Google Maps 리소스 정리
  onUnmounted(() => {
    if (autocomplete) {
      google.maps.event.clearInstanceListeners(autocomplete); // Autocomplete 이벤트 리스너 제거
      autocomplete = null; // Autocomplete 객체 초기화
    }

    // 지도 이벤트 리스너 및 객체 정리
    if (map) {
      google.maps.event.clearInstanceListeners(map); // 지도 이벤트 리스너 제거
      map = null; // 지도 객체 초기화
    }

    // 지도 관련 DOM 요소 정리
    const mapElement = document.getElementById('map');
    if (mapElement) {
      mapElement.innerHTML = ''; // 지도 요소 내부 초기화
    }
  });
</script>

<template>
  <div class="meeting_select_area_wrap">
    <div class="meeting_select_area_inner">
      <h3 class="title">미팅 요청 할 대상자 지역을 설정하세요.</h3>
      <div class="meeting_select_con">
        <label :class="[{ active: state.location }, 'default_search']" for="place"
          ><input id="place" :disabled="state.location !== '' && state.isSelect === true" placeholder="검색어를 입력하세요." type="text" @input="changeLocationInput" /><i
            class="search_btn"
            type="button"
          ></i>
          <button v-if="state.location && !state.isSelect" class="delete_btn" type="button" @click="handleDelete"></button
        ></label>
        <div v-if="state.isSelect && state.isLocationData">
          <div id="map" style="height: 214px; width: 100%"></div>
        </div>
      </div>
      <div class="submit_btn_con">
        <button :disabled="!state.location && !state.isLocationData" class="submit_btn" type="button" @click="handleNext">다음</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #map {
    border-radius: 18px;
    overflow: hidden;
    margin-top: 16px;
  }
  .location_search_list {
    li {
      line-height: 46px;
    }
  }
  .meeting_select_area_wrap {
    height: 100%;
    .meeting_select_area_inner {
      padding: 0 16px;
      .title {
        color: #000;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.18px;
        margin-bottom: 24px;
      }
      .meeting_select_con {
        .meeting_select_btn {
          display: inline-block;
          width: 100%;
          height: 56px;
          border-radius: 10px;
          border: 1px solid #f3f4f7;
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.48px;
          text-align: left;
          padding-left: 16px;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          &.select {
            border: none;
            background: #f7f3ff;
            color: #6728ff;
          }
        }
        .default_search {
          input {
            padding: 0 4px 0 32px;
            &:disabled {
              background: white;
              opacity: 1 !important;
            }
          }

          &.active {
            input {
              padding: 0 32px;
            }
          }
          .search_btn {
            width: 22px;
            height: 22px;
            background: url($icon-g30-search) no-repeat center / contain;
            right: initial;
            left: 4px;
          }
          .delete_btn {
            position: absolute;
            right: 4px;
            width: 16px;
            height: 16px;
            background: url($icon-g30-delete) no-repeat center / contain;
          }
        }
      }
      .submit_btn_con {
        position: fixed;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 500px;
        padding: 0 16px;
        .submit_btn {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background: #6728ff;
          color: #fff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.42px;
        }
      }
    }
  }
</style>
