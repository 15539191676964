<script>
  import { useLoadingStore } from '@/stores/useLoadingStore';

  export default {
    beforeRouteEnter(to, from, next) {
      const loading = useLoadingStore();
      loading.setUpdateIsLoadData(true);
      next();
    },
  };
</script>
<script setup>
  import TopHeaderVue from '@Components/common/TopHeaderVue.vue';
  import { onMounted, reactive, watch } from 'vue';
  import CommuniyuAddVue from '@Components/CommuniyuAddVue.vue';
  import MeetingAddVue from '@Components/MeetingAddVue.vue';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import MeetingSelectAreaVue from '@Components/MeetingSelectAreaVue.vue';
  import MeetingAddCommunityVue from '@Components/MeetingAddCommunityVue.vue';

  import { useRoute, useRouter } from 'vue-router';
  import { usePostStore } from '@/stores/usePostStore';
  import { useMeetingStore } from '@/stores/useMeetingStore';

  const router = useRouter();
  const route = useRoute();
  const usePost = usePostStore();
  const useMeeting = useMeetingStore();
  const selectTab = useAddCommunityStore();
  const state = reactive({
    isTab: selectTab.selectTab ? selectTab.selectTab : 'COMMUNITY_ADD', // 디폴트 COMMUNITY_ADD
  });
  console.log('=>(AddCommunity.vue:26) selectTab.selectTab', selectTab.selectTab);
  console.log('=>(AddCommunity.vue:27) state.isTab', state.isTab);
  const handleTabClick = gubun => {
    state.isTab = gubun;
    if (state.isTab === 'LIVECAM') {
      router.push('/add/AddressVue/live/area');
    } else {
      router.push('/add/AddCommunity');
    }
    selectTab.restCommunitySelect();
    selectTab.setSelectTab(gubun);
    selectTab.reset();
    usePost.reset();
    useMeeting.resetMeetingFormData();
  };
  const handleBack = () => {
    selectTab.reset();
    router.push('/');
  };
  onMounted(() => {
    console.log(route.path, '1231231232131');
  });
</script>

<template>
  <div class="wrapper">
    <TopHeaderVue :title="route.query.type === 'edit' ? '라이브샷 수정' : '게시글 등록'" fuc="true" @close="handleBack" />
    <div class="add_community_wrap top_inner">
      <div class="add_community_inner">
        <div class="tab">
          <button :class="{ active: state.isTab === 'COMMUNITY_ADD' }" class="tab_btn" type="button" @click="handleTabClick('COMMUNITY_ADD')">
            <div class="image_con">
              <img v-if="state.isTab === 'COMMUNITY_ADD'" alt="" src="@/assets/img/add_image_1_on.png" />
              <img v-else alt="" src="@/assets/img/add_image_1_off.png" />
              <span :class="{ active: state.isTab === 'COMMUNITY_ADD' }" class="text">게시글 등록</span>
            </div>
          </button>
          <button :class="{ active: state.isTab === 'MEETING_ADD' }" class="tab_btn" type="button" @click="handleTabClick('MEETING_ADD')">
            <div class="image_con">
              <img v-if="state.isTab === 'MEETING_ADD'" alt="" src="@/assets/img/add_image_2_on.png" />
              <img v-else alt="" src="@/assets/img/add_image_2_off.png" />
              <span :class="{ active: state.isTab === 'MEETING_ADD' }" class="text">미팅 요청</span>
            </div>
          </button>
          <button :class="{ active: state.isTab === 'LIVECAM' }" class="tab_btn" type="button" @click="handleTabClick('LIVECAM')">
            <div class="image_con">
              <img v-if="state.isTab === 'LIVECAM'" alt="" src="@/assets/img/live_cam_icon_on.png" />
              <img v-else alt="" src="@/assets/img/live_cam_icon.png" />
              <span :class="{ active: state.isTab === 'LIVECAM' }" class="text">라이브 샷</span>
            </div>
          </button>
        </div>
        <div class="content">
          <CommuniyuAddVue v-if="state.isTab === 'COMMUNITY_ADD'" />
          <MeetingAddVue v-if="state.isTab === 'MEETING_ADD' && !selectTab.communitySelect.value" />
          <MeetingSelectAreaVue v-if="state.isTab === 'MEETING_ADD' && !useMeeting.isComplete && selectTab.communitySelect.value" />
          <MeetingAddCommunityVue v-if="state.isTab === 'MEETING_ADD' && useMeeting.isComplete && selectTab.communitySelect.value" />
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
  .add_community_wrap {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    .add_community_inner {
      .tab {
        margin-bottom: 32px;
        margin-top: 8px;
        padding: 0 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab_btn {
          width: 104px;
          height: 82px;
          border-radius: 16px;
          border: 1px solid #f0f0f2;
          background: #fff;
          img {
            width: 28px;
            height: 28px;
          }
          .text {
            display: block;
            color: #9b9ea9;
            text-align: center;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.36px;

            &.active {
              color: #6728ff;
            }
          }

          &.active {
            border: 2px solid #6728ff;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }
</style>
