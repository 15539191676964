<script setup>
  import { useScoinStore } from '@/stores/useScoinStore';

  import { onMounted } from 'vue';

  const useScoin = useScoinStore();

  onMounted(() => {
    useScoin.setAvailableCoins();
  });
</script>

<template>
  <p class="point_tag hidden">
    <img alt="" class="point_icons" src="@/assets/img/point_icon.svg" /><span class="info_text">보유 Scoin : {{ Number(useScoin.availableCoin).toLocaleString() }} Scoin</span>
  </p>
  <ul class="dotted_list hidden">
    <li>1명 기준 지급될 Scoin 금액을 10P 단위로 입력하세요.</li>
  </ul>
</template>

<style lang="scss" scoped>
  .point_tag {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 0 4px;

    span {
      color: $purple;
      font: $text-caption-500;
      letter-spacing: -0.36px;
    }
  }
  .dotted_list {
    padding: 0 4px;
  }
</style>
