<script setup>
  import { computed } from 'vue';
  import dayjs from 'dayjs';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import { formatDate } from '@/utill/common';
  import { DialogWrapper } from 'vue3-promise-dialog';

  dayjs.extend(customParseFormat);
  const props = defineProps(['community']);
</script>

<template>
  <div v-if="props.community" class="point_info_wrap">
    <ul v-if="props.community.post_kind === 'liveshot'" class="live_shot_box">
      <li>
        <span>노출 지역</span><span>{{ props.community.country }}/{{ props.community.city }}</span>
      </li>
      <li>
        <span>요청 주소</span><span>{{ props.community.request_addr ? props.community.request_addr : '-' }}</span>
      </li>
    </ul>
    <ul class="point_info_list">
      <li v-if="props.community.liveshot_nickname" class="point_info_item">
        <span class="label">선정자</span
        ><span class="result">
          <span class="result flex-center-4">
            <span v-if="props.community.liveshot_status === '확정완료'" class="badge light">확정완료</span>
            {{ props.community.liveshot_nickname }}</span
          >
        </span>
      </li>
      <li v-if="!props.community.liveshot_nickname" class="point_info_item">
        <span class="label">요청 인원 / 신청 인원</span>
        <span class="result flex-center-4">
          <span v-if="props.community.meeting_close === 1" class="badge light">마감</span>
          {{ props.community.meeting_person_cnt }}명 / {{ props.community.post_kind === 'meeting' ? props.community.meeting_cnt : props.community.liveshot_cnt }}명</span
        >
      </li>
      <li class="point_info_item">
        <span class="label">제공 Scoin</span><span class="result">{{ props.community.per_scoin ? props.community.per_scoin.toLocaleString() : 0 }}</span>
      </li>
      <li class="point_info_item">
        <span class="label">노출 기간</span><span class="result">{{ formatDate(props.community.start_date, 'yy.mm.dd') }}~{{ formatDate(props.community.end_date, 'yy.mm.dd') }}</span>
      </li>
      <li class="point_info_item">
        <span class="label">{{ props.community.post_kind === 'liveshot' ? '신청자' : '' }} 선정 날짜</span><span class="result">{{ formatDate(props.community.meeting_date, 'yy.mm.dd') }}</span>
      </li>
      <li v-if="props.community.post_kind === 'liveshot'" class="point_info_item"><span class="label">라이브샷 전송 기한</span><span class="result">선정 후 6시간 내 사진 전송 </span></li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .point_info_wrap {
    margin-bottom: 12px;

    .point_info_list {
      border-radius: 10px;
      background: #f3f4f7;
      padding: 16px;

      .point_info_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .label {
          color: #000;
          font-family: Spoqa Han Sans Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.36px;
          text-align: left;
        }

        .result {
          color: #6728ff;
          font-family: Spoqa Han Sans Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.36px;
          text-align: right;
        }
      }
    }
  }

  .live_shot_box {
    padding: 0 4px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    li {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }

    span {
      color: $black;
      font: $text-caption-500;
      letter-spacing: -0.36px;

      &:last-child {
        display: block;
        width: calc(100% - 68px);
        font-weight: 400;
      }
    }
  }
</style>
