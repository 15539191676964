<script setup>
  import { ref } from 'vue';

  const isShow = ref(false);
  const emit = defineEmits(['cancel', 'confirm']);
  const props = defineProps({
    comment: {
      type: String,
      default: '타이틀을 입력 해주세요',
    },
    info: {
      type: String,
      default: '',
    },
  });
  const handleCancel = () => {
    emit('cancel', false);
  };

  const handleConfirm = () => {
    console.log('##');
    emit('confirm', true);
  };
  const handleShow = () => {
    isShow.value = !isShow.value;
  };

  defineExpose({
    handleShow,
  });
</script>

<template>
  <div class="wrapper" :class="{ on: isShow }">
    <div class="center_box">
      <h3 class="comment" v-html="props.comment" />
      <p class="sub_title" v-if="props.info" v-html="props.info" />
      <div class="btn_wrap">
        <button type="button" class="btn_ele accept" @click="handleConfirm">확인</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: none;
    &.on {
      display: block;
    }
    .center_box {
      padding: 0 16px 16px 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 16px;
      min-width: 304px;
      width: 100%;
      max-width: calc(100% - 56px);
      min-height: 150px;
      .comment {
        margin-top: 32px;
        margin-bottom: 16px;
        color: #000;
        text-align: center;
        font-family: Spoqa Han Sans Neo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.48px;
      }
      .sub_title {
        margin: 8px 0 16px 0 !important;
        color: #2a2e37;
        text-align: center;
        font-family: Spoqa Han Sans Neo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.42px;
      }
      .btn_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn_ele {
          width: 100%;
          border-radius: 10px;
          height: 46px;
          background: #f3f4f7;
          color: #727683;
          text-align: center;
          font-family: Spoqa Han Sans Neo;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.36px;
          &.accept {
            margin-left: 4px;
            background: #6728ff;
            color: #fff;
            text-align: center;
            font-family: Spoqa Han Sans Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.36px;
          }
        }
      }
    }
  }
</style>
