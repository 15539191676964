<script setup>
  import SearchFilterVue from '@Components/common/SearchFilterVue.vue';
  import { reactive, ref } from 'vue';
  import CommunityListVue from '@Components/CommunityListVue.vue';

  const CommunityListVueEle = ref();
  const state = reactive({
    visible: false,
  });

  /**
   * handleGetSearch 좋아요 높은순 만 보는 검색필터
   * @param search
   */
  const handleGetSearch = search => {
      CommunityListVueEle.value.handleSearchRequest(search);
  };
  /**
   * hadnleSubSearchFilter 자세한 검색 드롭다운 함수
   * @param search
   */
  const handleSubSearchFilter = search => {
    CommunityListVueEle.value.handleSubSearchRequest(search);
  };
</script>

<template>
  <div class="bottom_content_wrap">
    <SearchFilterVue  @search="handleGetSearch" @subSearchFilter="handleSubSearchFilter"  />
    <CommunityListVue ref="CommunityListVueEle" />
  </div>
</template>

<style lang="scss" scoped>
  .bottom_content_wrap {
    background: #fff;
    .content_con {
      background: #fff;
    }
  }
</style>
