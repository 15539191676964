import axios from '@/axios';

export default {
  //프라이빗 커뮤니티 신청
  applyPrivateCommunity(param) {
    return axios.post('/private/apply', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: false,
      },
    });
  },

  //프라이빗 커뮤니티명 중복확인
  checkCommunityName(param) {
    return axios.post('/private/duplication/name', param);
  },

  //프라이빗 카테고리
  createCommunityCategory(param) {
    return axios.post('/private/category', param);
  },

  //관리자 메인 ,운영중단 요청 취소,운영재개 요청
  setOperateMode(param) {
    return axios.post('/private/management', param);
  },

  //운영중단 요청
  updateCommStatus(param) {
    return axios.post('/private/status/change', param);
  },

  //초대하기 메세지 전송
  sendInvitationSms(param) {
    return axios.post('/private/send/sms', param);
  },

  //초대하기 코드 받기
  sendInvitationCode(param) {
    return axios.post('/private/send/code', param);
  },

  // 가입코드확인
  requestInvitationCode(param) {
    return axios.post('/private/send/confirm', param);
  },

  // 가입코드전송
  requestCommJoin(param) {
    return axios.post('/private/code/join', param);
  },

  // 멤버관리 리스트
  getMemberList(param) {
    return axios.post('/private/member', param);
  },

  // 프라이빗 커뮤니티 신고 리스트
  getMemberReportList(param) {
    return axios.post('/private/report', param);
  },

  //프라이빗 커뮤니티 탈퇴 리스트
  getMemberWithdrawList(param) {
    return axios.post('/private/withdrawList', param);
  },

  // 멤버관리 가입승인
  approveMembership(param) {
    return axios.post('/private/approve', param);
  },

  // 프라이빗 커뮤니티 게시물 숨김
  requestCommPostHidden(param) {
    return axios.post('/community/post/hiding', param);
  },
  //프라이빗 커뮤니티 게시물 최상위
  requestCommPostTop(param) {
    return axios.post('/community/post/top', param);
  },

  //커뮤니티 승인 alert
  getApprovalInformation() {
    return axios.post('/private/approveList');
  },
  //커뮤니티 승인 alert삭제
  deleteApprovalInformation(parma) {
    return axios.post('/private/approveDelete', parma);
  },

  //프라이빗 커뮤니티 게시물 최상위
  getBannerList(param) {
    return axios.post('/banner/list', param);
  },
};
