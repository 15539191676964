<script>
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { useSendBirdStore } from '@/stores/useSendBirdStore';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { AuthApiRequest } from '@/api/authApiRequest';

  export default {
    beforeRouteEnter(to, from, next) {
      const userInfo = useUserInfoStore();
      const useSendBird = useSendBirdStore();
      const loading = useLoadingStore();
      console.log('=>(default.vue:13) to.path', to.path);
      console.log('=>(default.vue:13) from.path', from.path);
      console.log('=>(default.vue:13) to.path', to.fullPath);
      userInfo.setPathName({
        from: from.fullPath,
        to: to.fullPath,
      });
      console.log(to.path);
      if (to.path === '/my/service/faq') {
        next();
      }

      if (!to.path.includes('auth')) {
        if (userInfo.memberInfo || userInfo.memberToken) {
          // 로그인 상태
          AuthApiRequest.checkToken().then(res => {
            if (res.code === 200) {
              if (navigator.userAgent.toLowerCase().indexOf('androidapp') !== -1 || navigator.userAgent.toLowerCase().indexOf('iosapp') !== -1) {
              } else {
                if (to.query.kind === 'app') {
                  window.location.href = `${import.meta.env.VITE_APP_SCHEME}${to.fullPath}`;
                }
              }
              next();
            } else {
              next({ path: '/auth/join' });
            }
          });
        } else {
          // 로그아웃 상태
          if (to.query.kind === 'app') {
            window.location.href = `${import.meta.env.VITE_APP_SCHEME}/auth/join?redirect=${to.path}`;
          } else {
            next({ path: '/auth/join' });
          }
        }
      } else {
        //로그인 페이지
        const params = new URLSearchParams(to.fullPath.split('?')[1]);
        const redirectUrl = params.get('redirect');

        if (navigator.userAgent.toLowerCase().indexOf('androidapp') !== -1 || navigator.userAgent.toLowerCase().indexOf('iosapp') !== -1) {
          if (userInfo.memberInfo || userInfo.memberToken) {
            // 로그인 상태
            if (redirectUrl) {
              next({ path: redirectUrl });
            } else {
              next();
            }
          } else {
            if (redirectUrl) {
              userInfo.setShareUrl(redirectUrl);
            }
            next();
          }
        } else {
          next();
        }
      }
    },
  };
</script>
<script setup>
  import { useRoute } from 'vue-router';
  import { computed, onMounted, reactive, ref, watch } from 'vue';
  import { useTranstionStore } from '@/stores/useTranstionStore';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import LoadingComponetVue from '@Components/loadingComponent/LoadingComponetVue.vue';
  import NavigatorVue from '@Components/common/NavigatorVue.vue';
  import { useUpdateStore } from '@/stores/useUpdateStore';
  import CommentAddVue from '@Components/commentAddVue.vue';
  import { useCommnetListStore } from '@/stores/useCommnetListStore';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { lockBodyScroll, unlockBodyScroll } from '@/utill/scroll';
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { voteApiRequest } from '@/api/active/voteApiRequest';
  import VotePopup from '@Components/popup/VotePopup.vue';
  import MeetingPopup from '@Components/popup/MeetingPopup.vue';

  /**
   * updateKey = useUpdateStore()
   * 토큰이 만료시 리플래쉬 토큰을 받은후 api 재요청을 위하여 컴포넌트 리렌더링 을 하기위한 updateKey
   * @type {Store<"useUpdate", {isUpdate: number}, {}, {setUpdateCount(*): void}>}
   */
  const updateKey = useUpdateStore();
  const loading = useLoadingStore();
  const userInfo = useUserInfoStore();
  const transitionName = useTranstionStore();
  const useComment = useCommnetListStore();
  const usePopup = usePopupStore();
  const route = useRoute();
  const userJoinConfirm = ref(null);
  const state = reactive({});

  //팝업 바디스크롤 제어
  watch(
    () => usePopup.isAnyPopupOpen,
    newValue => {
      if (newValue) {
        lockBodyScroll();
      } else {
        unlockBodyScroll();
      }
    },
  );
  watch(
    () => transitionName.transitionName,
    neaValue => {
      console.log('=>(default.vue:14) neaValue', neaValue);
    },
  );

  /**
   * 메인 네비게이션 을 해당 url 에서 숨기는 역할을 하는 함수
   * hiddenRoutes 부분은 딱 그 url 에서만 안보이게끔 하는곳
   * hiddenStartingRoutes 부분은 해당 url 로 시작하는 곳은 네비게이션이 안보이게 하는곳
   * @type {ComputedRef<unknown>}
   */
  const showBottomNavi = computed(() => {
    const showPath = ['/', '/my', '/chat/home'];
    return showPath.includes(route.path) || route.path.startsWith('/my/service/') || route.path.startsWith('/community/employment');
  });
  const shouldHideNavigator = computed(() => {
    const hiddenRoutes = [
      '/add/AddCommunity',
      '/auth/join',
      '/auth/Identity',
      '/auth/formData',
      '/auth/CommunityType',
      '/auth/succesRegister',
      '/my/profileEdit',
      '/my/privateAdd',
      '/my/MyScoin',
      '/detail/',
      '/my/PostManagement',
      '/my/CommentManagement',
      '/chatting/room',
      '/meeting/info',
      '/pay/ChargingPoint',
      '/search/Searchview',
      '/add/select',
      '/add/AddCommunityVue',
      '/community/EditVue',
      '/community/MeetingEditVue',
    ];
    const hiddenStartingRoutes = [
      '/community/no',
      '/community/yes',
      '/vote',
      '/add/AddressVue',
      '/community/ChatCommintySelectVue',
      '/community/EditLiveShot',
      '/alarm',
      '/add/AddCommunity',
      '/setting',
    ];
    return hiddenRoutes.includes(route.path) || hiddenStartingRoutes.some(hiddenRoute => route.path.startsWith(hiddenRoute)) || route.path.startsWith('/my/');
  });

  const handleCenterClick = () => {};
  defineExpose({
    route,
    loading,
  });
  const handleApprovalDenied = () => {
    userJoinConfirm.value.handlePopClose();
    tostAlert('승인거부로 진행되었습니다.');
  };
  const handleApproval = () => {
    userJoinConfirm.value.handlePopClose();
    tostAlert('가입승인이 완료되었습니다.');
  };

  onMounted(() => {
    //TODO 프라이빗 커뮤 앱 푸시로 들어왔을때 열리는 팝업
    // userJoinConfirm.value.handlePopOpen();
  });
</script>
<template>
  <main class="m-w-500 ma">
    <LoadingComponetVue v-if="!loading.isLoadData" />
    <transition name="overlay">
      <div :key="route.name">
        <keep-alive>
          <router-view :key="updateKey.isUpdate" v-slot="{ Component, route }">
            <component :is="Component" :key="route.fullPath" />
          </router-view>
        </keep-alive>
      </div>
    </transition>
    <!--메인 네비게이션-->

    <NavigatorVue v-if="showBottomNavi" @centerClick="handleCenterClick" />
    <!--댓글 다는 컴포넌트-->
    <CommentAddVue v-if="useComment.commentAdd" />

    <!-- 앱 푸시 프라이빗 커뮤 가입 팝업  -->
    <PopUpVue ref="userJoinConfirm">
      <div class="pop_alert_con v2">
        <h2 class="title">
          010-****-1234님이<br />
          [프라이빗이름] 가입요청하셨습니다
        </h2>
      </div>
      <div class="btm_btn">
        <button class="default_btn medium gray" type="button" @click="handleApprovalDenied">승인거부</button>
        <button class="default_btn medium" type="button" @click="handleApproval">가입승인</button>
      </div>
    </PopUpVue>
  </main>

  <!-- 투표하기 팝업 -->
  <VotePopup v-if="usePopup.popups.isVotePopup" @close="usePopup.popups.isVotePopup = false" />
</template>

<style lang="scss" scoped>
  .overlay-enter-active,
  .overlay-leave-active {
    transition: all 0.1s ease-in-out;
  }

  .overlay-enter,
  .overlay-leave-to {
    opacity: 0;
  }
</style>
