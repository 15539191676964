<!--
 패션케이크 ai 개발자가 차후 ai api 넘겨준다고 함 이부분은 ai api 로 받은값을 출력할 부분
 아직 api 받은게 없어서 하드코딩으로 출력 중
 -->
<script setup>
  import { onMounted, reactive } from 'vue';
  import axios from 'axios';

  const props = defineProps(['community']);
  const state = reactive({
    prompt: '',
    isResult: false,
  });

  const callOpenAiApi = async prompt => {
    const apiKey = 'sk-proj-k4DBLI2a1zUQyc8PEVJ8T3BlbkFJF4y9QfINV2qeFppayC6q';
    const url = 'https://api.openai.com/v1/chat/completions';
    const data = {
      model: 'gpt-3.5-turbo', // 사용할 모델 이름
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ],
      max_tokens: 3000,
      temperature: 0.5,
    };

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    };

    try {
      const response = await axios.post(url, data, requestOptions);

      if (response.data.choices && response.data.choices[0].message.content) {
        return response.data.choices[0].message.content;
      } else {
        return 'AI 답변을 준비 중입니다.';
      }
    } catch (err) {
      console.error('Error calling OpenAI API:', err);
      return 'AI 답변을 준비 중입니다.';
    }
  };

  onMounted(async () => {
    state.aiResponse = await callOpenAiApi(props.community.content);
    state.isResult = true;
  });
</script>

<template>
  <div v-if="props.community.ai_use && props.community.ai_use === 'Y'" class="ai_content_wrap">
    <div class="title_con">
      <img alt="" src="@/assets/img/ai_title_image.png" />
    </div>
    <div class="ai_answer_con">{{ state.isResult ? state.aiResponse : '로딩 중...' }}</div>

    <div class="more_btn_con">
      <button class="more_btn" style="display: none" type="button">
        <img alt="" src="@/assets/img/more_icons.png" />
        <span class="text_btn">더보기</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .ai_content_wrap {
    margin-top: 16px;
    border-radius: 10px;
    border: solid 1px #f3f4f7;
    background: #f7f3ff;
    padding: 16px;
    .ai_answer_con {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.39px;
    }
    .more_btn_con {
      .more_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text_btn {
          margin-left: 8px;
          color: #515561;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.36px;
        }
      }
    }
  }
</style>
