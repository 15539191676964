import { acceptHMRUpdate, defineStore } from 'pinia';

export const usePaymentStore = defineStore('usePayment', {
    state: () => {
        return {
            isPayment:false,
            paymentsAddr:null,
        };
    },
    actions: {
        setPayment(isPayment) {
            state.isPayment = isPayment;
        },
        setPaymentsAddr(addr){
            this.paymentsAddr =addr
        }
    },
    persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(usePaymentStore, import.meta.hot));
