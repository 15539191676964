<script setup>
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';
  import { onMounted, reactive, ref, watch } from 'vue';
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { formatDate, formatDayNight, formatTime } from '../../utill/common';
  import manageApi from '@/api/management/manageApi';
  import { useMyOperateStore } from '@/stores/useMyOperateStore';
  import operatorApi from '@/api/operator/operatorApi';
  import { removeNonNumeric } from '@/utill/RegEx';

  const emit = defineEmits(['close']);
  const props = defineProps(['detail']);
  const useMyOperate = useMyOperateStore();
  const confirmPopup = ref();
  const finalConfirmPopup = ref();
  const usePopup = usePopupStore();

  const state = reactive({
    isConfirmed: false,
    phoneNumber: '',
  });
  const handleClose = () => {
    emit('close');
  };

  const handlePhoneNumber = () => {
    state.phoneNumber = removeNonNumeric(state.phoneNumber);
  };
  const handleSubmit = value => {
    if (value) {
      const param = {
        phoneNumber: state.phoneNumber,
        communityName: useMyOperate.myCommInfo.name,
      };
      operatorApi.sendInvitationSms(param).then(res => {
        if (res.code === 200) {
          tostAlert('발송되었습니다.');
        } else {
          tostAlert(res.message);
        }
      });
    }
    usePopup.popups.isInvitationSms = false;
  };
  onMounted(() => {
    if (usePopup.popups.isInvitationSms) {
      confirmPopup.value.handlePopOpen();
    }
  });
  watch(
    () => usePopup.popups.isInvitationSms,
    newValue => {
      console.log(newValue, 'newValue');
      if (newValue) {
        confirmPopup.value.handlePopOpen();
      } else {
        confirmPopup.value.handlePopClose();
      }
    },
  );
</script>

<template>
  <PopUpVue ref="confirmPopup" @click="handleClose">
    <div class="pop_alert_con v2">
      <h2 class="title flex-center-16 column">메시지 전송</h2>
      <div class="confirm_content flex-16 column">
        <ul class="flex-16 column">
          <li class="default_input flex-16 column"><input v-model="state.phoneNumber" class="bordered" maxlength="11" placeholder="휴대폰 번호 입력" type="text" @input="handlePhoneNumber" /></li>
        </ul>
      </div>
    </div>
    <div class="btm_btn">
      <button class="default_btn medium gray" type="button" @click="handleSubmit(false)">취소</button>
      <button class="default_btn medium" type="button" @click="handleSubmit(true)">전송</button>
    </div>
  </PopUpVue>
</template>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/community.scss';

  .title {
    .chk_icon {
      @include mixedIcon($size: 40px, $url: $check-bg-icon);
    }
  }

  .confirm_content {
    padding: 32px 0 16px;

    .meeting_tit {
      font: $text-body14-500;
      color: $purple;
      letter-spacing: -0.42px;
    }

    .meeting_info {
      li {
        padding-top: 16px;
        border-top: 1px solid #e7eaf0;

        span {
          font: $text-body14-400;
          letter-spacing: -0.42px;

          &:first-child {
            color: #515561;
            font: $text-caption-500;
            letter-spacing: -0.36px;
          }
        }
      }
    }
  }
</style>
