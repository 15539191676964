import axios from '@/axios';

export default {
  openChatRoomList(param) {
    return axios.post('/community/open/chat-room/list', param);
  },
  privateChatRoomList(param) {
    return axios.post('/community/private/chat-room/list', param);
  },

  //채널 저장
  chatRoomInfo(param) {
    return axios.post('/community/open/chat-room/save', param);
  },
  chatPrivateUrlSave(param) {
    return axios.post('/community/private/chat-room/save', param);
  },

  //채널 삭제
  deleteOpenChatChannel(param) {
    return axios.post('/community/open/chat-room/delete', param);
  },

  //채팅 사용자 신고 등록
  submitChatReport(param) {
    return axios.post('/community/chat-user/report', param);
  },
  //채팅 사용자 차단 등록
  submitChatBlock(param) {
    return axios.post('/community/chat-user/block', param);
  },
};
