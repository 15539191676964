<script>
  import mainFooterStyled from '@/styled/mainFooterStyled';

  export default {
    components: {
      mainFooterStyled,
    },
  };
</script>
<script setup>
  import MyFooterVue from '@Components/MyFooterVue.vue';
</script>

<template>
  <mainFooterStyled>
    <h3 class="copy_right_text">ⓒ Seemple Corp All rights reserved</h3>
  </mainFooterStyled>
  <MyFooterVue />
</template>
