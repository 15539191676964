import SendbirdChat from '@sendbird/chat';
import { OpenChannelModule } from '@sendbird/chat/openChannel';
import { GroupChannelFilter, GroupChannelHandler, GroupChannelListOrder, GroupChannelModule, MessageFilter, PublicChannelFilter, SuperChannelFilter } from '@sendbird/chat/groupChannel';
import sendbirdApiList from '@/api/sendbirdApiList';
import { useMessageStore } from '@/stores/useMessageStore';
import dayjs from 'dayjs';
import { useChatCommunitySelectStore } from '@/stores/useChatCommunitySelectStore';
import axios from 'axios';
import { useChannelStore } from '@/stores/useChannelStore';

const sb = SendbirdChat.init({
  appId: import.meta.env.VITE_SEND_BIRD_ID,
  localCacheEnabled: true,
  modules: [new OpenChannelModule(), new GroupChannelModule()],
});

export class sendbirdApi {
  static async getNewRoomListRequest(param) {
    try {
      const data = await sendbirdApiList.getNewRoomList(param);
      console.log('=>(sendbirdApi.js:20) data', data);
      return data;
    } catch (err) {
      throw err;
    }
  }

  // 메세지 안읽음 읽음으로 전환
  static async channelStatus(param) {
    try {
      const channel = await sb.groupChannel.getChannel(param);
      await channel.markAsRead();
      const channelsHandler = new GroupChannelHandler({
        onUnreadMemberStatusUpdated: channel => {
          console.log('=>(sendbirdApi.js:229) channel', channel);
        },
      });

      sb.groupChannel.addGroupChannelHandler(param, channelsHandler);
    } catch (err) {
      throw err;
    }
  }
  static async channelListRequest(pram) {
    try {
      console.log('=>(sendbirdApi.js:37) pram', pram);
      const groupChannelFilter = new GroupChannelFilter();
      groupChannelFilter.channelUrlsFilter = pram;
      const request = {
        filter: groupChannelFilter,
        limit: 100,
      };
      const groupChannelCollection = sb.groupChannel.createGroupChannelCollection(request);
      const channels = await groupChannelCollection.loadMore();
      console.log('=>(sendbirdApi.js:40) channels', channels);
      return channels;
    } catch (err) {
      throw err;
    }
  }
  static async searchChannler(param) {
    try {
      const data = await sb.groupChannel.createPublicGroupChannelListQuery();
      return data;
    } catch (err) {
      throw err;
    }
  }
  static async getRoomChatMessageList(param) {
    try {
      const data = await sendbirdApiList.getRoomMessageList(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  /**
   * 채널 나가기
   * @param param
   * @channelUrl 채널 주소
   * @user_ids 나갈 유저 아이디
   * @returns {Promise<*>}
   */
  static async groupChannelLeaveFuc(param) {
    try {
      const data = sendbirdApiList.groupChannelLeave(param);
      return data;
    } catch (err) {
      console.log('=>(sendbirdApi.js:18) err', err);
      throw err;
    }
  }
  static async sendbirdConnet() {
    try {
      const sb = SendbirdChat.init({
        appId: import.meta.env.VITE_SEND_BIRD_ID,
        localCacheEnabled: true,
        modules: [new OpenChannelModule(), new GroupChannelModule()],
      });
      console.log('###############');
      console.log(sb);
      console.log('###############');
    } catch (err) {
      console.log('=>(sendbirdApi.js:17) err', err);
      throw err;
    }
  }
  // 사용자 id 추가
  static async addUser(param) {
    try {
      const data = await sendbirdApiList.createdUser(param);
      return data;
    } catch (err) {
      console.log('=>(sendbirdApi.js:17) err', err);
      throw err;
    }
  }
  // 샌드버드 로그인
  static async loginSendBird(param) {
    try {
      const user = sb.connect(param);
      return user;
    } catch (err) {
      console.log('=>(sendbirdApi.js:25) err', err);
      throw err;
    }
  }
  // 연결 상태 확인
  static connectedState(param) {
    try {
      console.log('Connection State:', sb.connectionState);
      return sb.connectionState;
    } catch (error) {
      console.log('=>(sendbirdApi.js:35) error', error);
      throw error;
    }
  }

  static async reConnectUser(param) {
    try {
      const user = await sb.connect(param);
      console.log('=>(sendbirdApi.js:46) user', user);
      const useChannel = useChannelStore();
      useChannel.setArrangerInfo(user);

      return user;
    } catch (error) {
      console.log('=>(sendbirdApi.js:47) error', error);
      throw error;
    }
  }
  // 채팅방 만들기 이거는 오픈채팅임
  static async createRoom(param) {
    try {
      const openChannelParams = {};
      openChannelParams.name = '테스트';
      openChannelParams.operatorUserIds = ['www'];
      const channel = await sb.openChannel.createChannel(openChannelParams);
      return [channel, false];
    } catch (err) {
      console.log('=>(sendbirdApi.js:37) err', err);
      throw err;
    }
  }
  // 사용자 목록
  static async userList(queryParams) {
    try {
      const query = sb.createApplicationUserListQuery(queryParams);

      const users = await query.next();
      return users;
    } catch (err) {
      console.log('=>(sendbirdApi.js:38) err', err);
      throw err;
    }
  }

  // 사용자 검색
  static async searchUserList(param) {
    try {
      const queryParams = {
        // userIdsFilter: [param],
        limit: 250,
        userIdsFilter: '128',
      };
      const query = sb.createApplicationUserListQuery(queryParams);
      const users = await query.next();
      return users;
    } catch (err) {
      console.log('=>(sendbirdApi.js:92) err', err);
      throw err;
    }
  }

  // 그룹 채널 방 만들기
  /**
   *
   * @param invitedUserIds 대화할 유저 아이디
   * @param name 방이름
   * @param coverUrl 채널 커버
   * @param operatorUserIds
   * @param data
   * @param customType 채널유형
   */
  static async createAGroupChannel(param) {
    try {
      const channelData = await sendbirdApiList.groupChannelCreated(param);
      return channelData.data;
    } catch (error) {
      console.log('=>(sendbirdApi.js:93) error', error);
      throw error;
    }
  }
  static async joinChannel(param) {
    try {
      const data = await sendbirdApiList.joinChannel(param);
      return data.data;
    } catch (err) {
      console.log('=>(sendbirdApi.js:125) err', err);
      throw err;
    }
  }

  /**
   * 메세지 보내기
   * @param param
   * @returns {Promise<*>}
   */
  static async sendMessageRequest(param) {
    try {
      const data = await sendbirdApiList.sendMessage(param);
      return data.data;
    } catch (err) {
      console.log('=>(sendbirdApi.js:153) err', err);
      throw err;
    }
  }

  /**
   * 채널 수락
   * @param param
   * @returns {Promise<*>}
   */
  static async autoAcceptChannel(param) {
    try {
      const data = await sendbirdApiList.groupChannelAutoAccept(param);
      return data.data;
    } catch (err) {
      console.error();
      throw err;
    }
  }
  /**
   * 채널 URL
   * @param param
   * @returns {Promise<void>}
   */
  static async channelMemberRequestApi(param) {
    try {
      const memberList = await sendbirdApiList.channelMemberRequest(param);
    } catch (err) {
      console.log('=>(sendbirdApi.js:153) ');
      throw err;
    }
  }
  static async getMessageApi(param) {
    try {
      const messageStore = useMessageStore();
      const channelsHandler = new GroupChannelHandler({
        onMessagesUpdated: (channel, message) => {
          console.log('=>(sendbirdApi.js:229) channel', channel);
          console.log('=>(sendbirdApi.js:231) message', message);
        },
      });

      sb.groupChannel.addGroupChannelHandler(param.channelUrl, channelsHandler);
      return channelsHandler;
    } catch (err) {
      console.log('=>(sendbirdApi.js:173) err', err);
      throw err;
    }
  }
  static async getFirstMessageList(param) {
    try {
      const sbStore = useMessageStore();
      const channel = await sb.groupChannel.getChannel(param.channelUrl);
      const filter = new MessageFilter();
      const limit = 100;
      const startingPoint = Date.now();
      sbStore.setSendBirdBody(channel);
      const collection = channel.createMessageCollection({
        filter,
        limit,
        startingPoint,
      });

      return collection;
    } catch (err) {
      console.log('=>(sendbirdApi.js:244) err', err);
      throw err;
    }
  }
  static async groupChannelListRequest(param) {
    try {
      const channelList = await sendbirdApiList.groupChannelList();
      return channelList.data;
    } catch (err) {
      console.log('=>(sendbirdApi.js:167) err', err);
      throw err;
    }
  }
  static async userInvitation() {
    try {
      const userIds = ['Sally', 'Harry'];
      // console.log("=>(sendbirdApi.js:134) sb.groupChannel", sb.groupChannel);
      // return
      await channel.inviteWithUserIds(userIds);
    } catch (err) {
      console.log('=>(sendbirdApi.js:134) err', err);
      throw err;
    }
  }
  // 채널 떠나기
  static async channelLeave(param) {
    try {
      const channel = await sb.groupChannel.getChannel(param);
      console.log(channel);
      return channel.leave();
    } catch (err) {
      console.log(err);
    }
  }

  // 채널 입정
  static async channelEnter(channelUrl) {
    try {
      const channel = await sb.groupChannel.getChannel(channelUrl);

      console.log('채널 상세:', channel);
      console.log('채널 상세:', count);
    } catch (err) {
      console.error('채널 검색 또는 메시지 전송 중 오류 발생:', err);
    }
  }

  // 채널 리스트 조회
  static async channelList(params) {
    try {
      const groupChannelFilter = new GroupChannelFilter();
      groupChannelFilter.includeEmpty = true; // Optional.
      if (params && params.hasOwnProperty('isPublic')) {
        // 공개여부 채널 검색 필터링 ALL, PUBLIC, PRIVATE
        switch (params.isPublic) {
          case 'PUBLIC':
            groupChannelFilter.publicChannelFilter = PublicChannelFilter.PUBLIC;
            break;
          case 'PRIVATE':
            groupChannelFilter.publicChannelFilter = PublicChannelFilter.PRIVATE;
            break;
          case 'ALL':
          default:
            groupChannelFilter.publicChannelFilter = PublicChannelFilter.ALL;
            break;
        }

        // 슈퍼 채널 검색 필터링 ALL, SUPER, NON_SUPER
        switch (params.isSuper && params.hasOwnProperty('isSuper')) {
          case 'SUPER':
            groupChannelFilter.superChannelFilter = SuperChannelFilter.SUPER;
            break;
          case 'NON_SUPER':
            groupChannelFilter.superChannelFilter = SuperChannelFilter.NON_SUPER;
            break;
          case 'ALL':
          default:
            groupChannelFilter.superChannelFilter = SuperChannelFilter.ALL;
            break;
        }
      } else {
        groupChannelFilter.publicChannelFilter = PublicChannelFilter.ALL;
        groupChannelFilter.superChannelFilter = SuperChannelFilter.ALL;
      }

      const groupChannelCollection = sb.groupChannel.createGroupChannelCollection({
        filter: groupChannelFilter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        // ...
      });
      if (groupChannelCollection.hasMore) {
        const channels = await groupChannelCollection.loadMore();
        return channels;
      }
    } catch (error) {
      console.log('=>(sendbirdApi.js:86) error', error);
      throw error;
    }
  }

  // 추가 유저 정보 생성 및 업데이트(인증회원,레벨 )
  static async CreateSbUserInfo(userId, param) {
    const apiUrl = `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users/${userId}/metadata`;

    try {
      const response = await axios.post(apiUrl, param, {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error in createMetaData:', error);
      throw error;
    }
  }

  static async CreateUserMetaData(userId, param) {
    const apiUrl = `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users/${userId}/metadata`;

    try {
      const response = await axios.post(apiUrl, param, {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error in createMetaData:', error);
      throw error;
    }
  }

  //메타데이터 업데이트
  static async UpdateUserMetaData(userId, param) {
    const apiUrl = `https://api-4FAB4C4D-AD7F-469F-A590-8EA69E4B1C64.sendbird.com/v3/users/${userId}/metadata`;

    try {
      const response = await axios.put(apiUrl, param, {
        headers: {
          'Api-Token': 'cd38ddeee16a4db5a23d65dc4ff463b8095b2a94',
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error in createMetaData:', error);
      throw error;
    }
  }
  //프로필 업데이트
  static async updateSbUserProfile(params) {
    try {
      const user = await sb.updateCurrentUserInfo(params);
      console.log(user, 'user');
      return user;
    } catch (error) {
      console.error('Error in createMetaData:', error);
      throw error;
    }
  }

  static async getUnreadMessageCount() {
    try {
      const count = await sb.groupChannel.getTotalUnreadChannelCount();
      console.log(count, 'count');
      return count;
    } catch (error) {
      console.error('Error in createMetaData:', error);
      throw error;
    }
  }
  static async createMyGroupChannelListQuery(params) {
    try {
      console.log(params);
      const query = sb.groupChannel.createMyGroupChannelListQuery(params);

      // Only channel B including {'Maya', 'Brett`, `Chelsea`} as a subset is returned in a result list.
      return await query.next();
    } catch (err) {
      throw err;
    }
  }
}
