import { acceptHMRUpdate, defineStore } from 'pinia';
import userApi from '@/api/user/userApi';
import { returnToken } from '@/utill/jwtToken';

export const useUserInfoStore = defineStore('useUserInfo', {
  state: () => {
    return {
      memberRefreshToken: '',
      memberToken: '',
      memberInfo: '',
      shareUrl: '',
      testUrl: '',
      pushId: '',
      latlng: {},
      pathName: {},
    };
  },

  getters: {},
  actions: {
    setTestUrl(url) {
      this.testUrl = url;
    },
    setShareUrl(url) {
      // "=" 기준으로 URL 문자열을 분할하여 배열 생성
      this.shareUrl = url;
    },
    setPathName(name) {
      this.pathName = name;
    },
    setPushId(id) {
      this.pushId = id;
    },
    saveUserInfo(userInfoData) {
      this.userInfo = userInfoData;
    },
    setMemberInfoUpdate() {
      // this.memberInfo = data;
      userApi.fetchUserInfo().then(res => {
        if (res.code === 200) {
          console.log(res.data, 'res.data');
          this.memberInfo = res.data.at(0);
        }
      });
    },
    setMemberToken(data) {
      this.memberToken = data;

      this.setReturnToken();
    },
    setReturnToken() {
      const info = returnToken(this.memberToken);
      localStorage.setItem('push_id', info.push_id);
    },
    reset() {
      this.memberRefreshToken = '';
      this.memberToken = '';
      this.memberInfo = '';
      this.shareUrl = '';
    },
    resetShareUrl() {
      this.shareUrl = '';
    },
    setLatlng(position) {
      const LatlngArr = position.split(',');
      this.latlng = {
        latitude: parseFloat(LatlngArr[0]),
        longitude: parseFloat(LatlngArr[1]),
      };
      console.log(this.latlng);
    },
  },
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.hot));
