<script setup>
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import { usePopupStore } from '@/stores/usePopupStore';

  const usePopup = usePopupStore();
</script>

<template>
  <defaultPopupStyles>
    <PopHeaderVue title="이용약관" @close="usePopup.popups.isTermsOfUse = false" />
    <section class="container_inner form_section">
      <div class="in_inner">
        <p>Seemple 이용약관</p>
        <p>2024.03.03. Ver.01</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 1 장</p>
        <p>제 1 조 (목적)</p>
        <p>
          주식회사 클로토 (이하 &lsquo;회사&rsquo;)가 제공하는 Seemple(이하 &lsquo;서비스&rsquo;)을 이용해 주셔서 감사합니다. 회사는 여러분이 서비스를 좀 더 편리하게 이용할 수 있도록 안내 드립니다.
        </p>
        <p>본 약관은 여러분이Seemple 서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있습니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 2 조 (약관의 효력 및 변경)</p>
        <p>①본 약관의 내용은 Seemple 서비스의 화면에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 여러분 모두에게 그 효력이 발생합니다.</p>
        <p>
          ②회사는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 15일 전부터 여러분에게 서비스 공지사항에서 공지 또는
          통지하는 것을 원칙으로 하며, 피치 못하게 여러분에게 불리한 내용으로 변경할 경우에는 그 시행일자 30일 전부터 Seemple계정에 등록된 이메일 주소로 이메일(이메일주소가 없는 경우 서비스 내
          전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 여러분이 등록한 휴대폰번호로 SNS 메시지 또는 문자메시지 발송하는 방법 등으로 개별적으로 알려 드립니다.
        </p>
        <p>
          ③회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 여러분의
          의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 여러분이 개정약관에 동의하지 않을 경우 여러분은 이용계약을 해지할 수 있습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 3 조 (약관 외 준칙)</p>
        <p>본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 회사가 정한 개별 서비스의 이용약관, 운영정책 및 규칙 등(이하 &lsquo;세부지침&rsquo;)의 규정에 따릅니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 4 조 (용어의 정의)</p>
        <p>①본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <p>1.Seemple계정: 회사의 서비스를 하나의 로그인계정과 비밀번호로 회원 인증, 회원정보 변경, 회원 가입 및 탈퇴 등을 관리할 수 있도록 회사가 정한 로그인계정 정책을 말합니다.</p>
        <p>2.회원: Seemple계정이 적용된 개별 서비스를 이용하는 자를 말합니다.</p>
        <p>3.인증회원: 회사와 제휴 관계를 맺고 프라이빗 커뮤니티의 운영 또는 개설 권한을 부여받아 개별적으로 커뮤니티를 운영자를 자를 말합니다.</p>
        <p>4.개별 서비스: Seemple내의 다양한 기능단위 서비스로서 &lsquo;투표&rsquo;,&lsquo;미팅&rsquo;,&lsquo;라이브샷&rsquo; 등 향후 Seemple에서 제공하는 다양한 Q&amp;A기능적 서비스를 뜻합니다</p>
        <p>
          5.Seemple계정 정보 : Seemple서비스를 이용하기 위해 회사가 정한 필수 내지 선택 입력 정보로서 Seemple 웹사이트 또는 개별 서비스 내 Seemple계정 설정 화면을 통해 정보 확인, 변경 처리 등을 관리할
          수 있는 회원정보 항목을 말합니다.
        </p>
        <p>6. AI 학습 정보 : Seemple내 서비스를 이용하며 발생된 이용자의 행위 정보이며 Seemple내의 다양한 기능을 사용하며 발생되고 수집되어지는 정보입니다.</p>
        <p>7. 충전 : Seemple내의 기능을 사용하기 위하여 이용금액을 충전하는 행위입니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 2 장 Seemple계정 이용계약</p>
        <p>제 5 조 (일반 이용자의 계약의 성립)</p>
        <p>①Seemple계정 이용 신청은 개별 서비스 또는 Seemple 웹사이트 및 모바일 회원가입 화면에서 여러분이 Seemple계정 정보에 일정 정보를 입력하는 방식으로 이루어집니다.</p>
        <p>②Seemple계정 이용계약은 여러분이 본 약관의 내용에 동의한 후 본 조 제1항에서 정한 이용신청을 하면 회사가 입력된 일정 정보를 인증한 후 가입을 승낙함으로써 체결됩니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 6 조 (인증 이용자의 계약의 성립)</p>
        <p>
          ①Seemple 인증회원 이용 신청은 개별 서비스 또는 Seemple 웹사이트 및 모바일 회원가입 화면에서 여러분이 Seemple인증회원 가입 필요 서류 입력 및 &nbsp;정보에 일정 정보를 입력하는 방식으로
          이루어집니다.
        </p>
        <p>
          ②Seemple인증회원 &nbsp;이용계약은 여러분이 본 약관의 내용에 동의한 후 본 조 제1항에서 정한 이용신청을 하면 회사가 인증회원 신청시 첨부한 자료 및 &nbsp;입력된 일정 정보를 인증한 후 가입을
          승낙함으로써 체결됩니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 7조 (Seemple계정 및 인증회원 이용의 제한)</p>
        <p>
          ①제5조에 따른 가입 신청자에게 회사는 원칙적으로 Seemple 이용을 승낙합니다. 다만, 회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지 승낙을 유보하거나 승낙하지 않을 수 있습니다. 특히,
          여러분이 만 14세 미만인 경우에는 부모님 등 법정대리인의 동의가 있는 경우에만 Seemple계정을 생성할 수 있습니다.
        </p>
        <p>1.회사가 본 약관 또는 세부지침에 의해 여러분의 Seemple계정을 삭제하였던 경우</p>
        <p>2.여러분이 다른 사람의 명의나 이메일 주소 등 개인정보를 이용하여 Seemple계정을 생성하려 한 경우</p>
        <p>3.Seemple계정 생성 시 필요한 정보를 입력하지 않거나 허위의 정보를 입력한 경우</p>
        <p>4.제공 서비스 설비 용량에 현실적인 여유가 없는 경우</p>
        <p>5.서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우</p>
        <p>6.기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우</p>
        <p>7.회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간에 이용계약을 임의로 해지하고 재이용을 신청하는 경우</p>
        <p>8.기타 관련법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우</p>
        <p>
          ②만약, 여러분이 위 조건에 위반하여 Seemple계정을 생성하거나 혹은 인증회원을 신청했다 판명된 때에는 회사는 즉시 여러분의 Seemple계정 이용을 정지시키거나 Seemple계정을 삭제하는 등 적절한
          제한을 할 수 있습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p><o:p>&nbsp;</o:p></p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 3 장 Seemple계정 이용</p>
        <p>제 8 조 (Seemple계정 제공)</p>
        <p>①회사가 개별 서비스와 연동하여 Seemple계정에서 제공하는 서비스(이하 &ldquo;Seemple계정 서비스&rdquo; 또는 &ldquo;서비스&rdquo;) 내용은 아래와 같습니다.</p>
        <p>1.서비스의 이용 : Seemple계정으로 접속 시 Seemple내의 미팅,투표,라이브샷 등의 다양한 기능을 이용하실 수 있습니다.</p>
        <p>2.SSO(Single Sign On): 웹브라우저나 특정 모바일 기기에서 계정 1회 로그인으로 여러분이 이용 중인 개별 서비스간 추가 로그인 없이 자동 접속 서비스를 이용할 수 있습니다.</p>
        <p>3.인증회원 신청 : 프라이빗 커뮤니티의 개설을 원하거나 직접 운영하고자 하는 Seemple계정 이용자가 추가적인 인증을 통해 인증회원으로 변경 가능 합니다.</p>
        <p>4.기타 회사가 제공하는 서비스</p>
        <p>
          ②회사는 더 나은 Seemple 서비스의 제공을 위하여 여러분에게 서비스의 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 서비스화면 내에 표시하거나 여러분의 이메일로
          전송할 수 있습니다. 광고성 정보 전송의 경우에는 사전에 수신에 동의한 경우에만 전송합니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 9 조 (Seemple 서비스의 변경 및 종료)</p>
        <p>①회사는 Seemple서비스를 365일, 24시간 쉬지 않고 제공하기 위하여 최선의 노력을 다합니다. 다만, 아래 각 호의 경우 Seemple 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
        <p>1.Seemple 서비스용 설비의 유지&middot;보수 등을 위한 정기 또는 임시 점검의 경우</p>
        <p>2.정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 Seemple 이용에 지장이 있는 경우</p>
        <p>3.관계사와의 계약 종료, 정부의 명령/규제, 서비스/회원 정책 변경 등 회사의 제반 사정으로 Seemple서비스를 유지할 수 없는 경우</p>
        <p>4.기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</p>
        <p>
          ②전항에 의한 Seemple서비스 중단의 경우에는 미리 제14조에서 정한 방법으로 여러분에게 통지 내지 공지하겠습니다. 이 때 원만한 서비스 및 정책 변경 등을 위하여 서비스 이용 시 재로그인 또는
          추가적인 동의 절차 등이 필요할 수 있습니다. 다만, 회사로서도 예측할 수 없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 서비스가 중단된 경우에는 사전
          통지 내지 공지를 할 수 없습니다. 이러한 경우에도 회사가 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록 노력하되, 2시간 이상 복구가 지연될 시 Seemple서비스 공지사항,
          Seemple고객센터 공지사항 등에 게시하여 알려 드리겠습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 4 장 계약당사자의 의무</p>
        <p>제 10 조 (회원의 의무)</p>
        <p>①여러분이 Seemple 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안 됩니다.</p>
        <p>
          1.이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 Seemple계정 및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나 명의자의 허락 없이 문자메시지(SMS) 인증 등을
          수행하는 행위
        </p>
        <p>2.타인의 명예를 손상시키거나 불이익을 주는 행위</p>
        <p>3.게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위</p>
        <p>4.회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위</p>
        <p>5.공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위</p>
        <p>6.Seemple 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위</p>
        <p>7.Seemple 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위</p>
        <p>
          8.회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의
          추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위
        </p>
        <p>9.타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</p>
        <p>10.다른 회원의 개인정보를 수집, 저장, 공개하는 행위</p>
        <p>11.자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위</p>
        <p>12.윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위</p>
        <p>13.수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위</p>
        <p>14.관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위</p>
        <p>15.회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 E-mail, &nbsp;메시지 등을 발송하는 행위</p>
        <p>
          16.컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나
          E-mail, &nbsp;메시지 등으로 발송하는 행위
        </p>
        <p>17.기타 불법한 행위</p>
        <p>②여러분은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도&middot;증여할 수 없으며, 담보로 제공할 수 없습니다.</p>
        <p>
          ③혹시라도 여러분이 관련 법령, 회사의 모든 약관 또는 정책을 준수하지 않는다면, 회사는 여러분의 위반행위 등을 조사할 수 있고, 여러분의 계정・서비스 이용을 잠시 또는 계속하여 중단하거나,
          재가입에 제한을 둘 수도 있습니다. 또한 여러분이 서비스와 관련된 설비의 오작동이나 시스템의 파괴 및 혼란을 유발하는 등 서비스 제공에 악영향을 미치거나 안정적 운영을 심각하게 방해한 경우,
          회사는 이러한 위험 활동이 확인된 여러분의 계정들에 대하여 이용제한을 할 수 있습니다. 다만, 여러분은 이용제한과 관련하여 조치 결과가 불만족스러울 경우 고객센터를 통해 이의를 제기할 수
          있습니다.
        </p>
        <p>④본 조에서 정한 사항 및 그 밖에 Seemple 서비스의 이용에 관한 자세한 사항은 Seemple 운영정책 등을 참고해 주시기 바랍니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 11 조 (개인정보의 보호)</p>
        <p>
          여러분의 개인정보의 안전한 처리는 회사에게 있어 가장 중요한 일 중 하나입니다. 여러분의 개인정보는 서비스의 원활한 제공을 위하여 여러분이 동의한 목적과 범위 내에서만 이용됩니다. 법령에
          의하거나 여러분이 별도로 동의하지 아니하는 한 회사가 여러분의 개인정보를 제3자에게 제공하는 일은 결코 없으므로, 안심하셔도 좋습니다. 회사가 여러분의 개인정보를 안전하게 처리하기 위하여
          기울이는 노력이나 기타 자세한 사항은 Seemple개인정보처리방침을 참고하여 주십시오.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 12조 (회원에 대한 통지 및 공지)</p>
        <p>
          회사는 여러분과의 의견 교환을 소중하게 생각합니다. 여러분은 언제든지 고객센터에 방문하여 의견을 개진할 수 있습니다. 서비스 이용자 전체에 대한 공지는 칠(7)일 이상 서비스 공지사항란에
          게시함으로써 효력이 발생합니다. 여러분에게 중대한 영향을 미치는 사항의 경우에는 Seemple계정에 등록된 이메일 주소로 이메일(이메일주소가 없는 경우 서비스 내 전자쪽지 발송, 서비스 내 알림
          메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 여러분이 등록한 휴대폰번호로 메시지 또는 문자메시지 발송하는 방법 등으로 개별적으로 알려 드리겠습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제5장 (AI 윤리 규정 관련)</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제13조 (AI 윤리 규정)</p>
        <p>
          회사의 모든 구성원은 AI 윤리를 중요하게 여기며 자체적인 AI 윤리 규정을 준수합니다. AI 윤리 규정은 Seemple의 서비스를 사용할 때 발생할 수 있는 문제를 예방하고 방지하기 위한 목적으로
          제정되었습니다.
        </p>
        <p>
          Seemple은 사용간 Q&amp;A를 진행 할 때 유용한 AI를 지향하고 있습니다. 그 과정에서 올바른 표현 도출과 개인정보 보호를 우선하고 있으며 잘못된 정보와 편향된 정보가 도출되지 않도록 노력하고
          있습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제14조 (Seemple을 사용하는 사용자의 의무)</p>
        <p>
          이용자는 Seemple을 악의적인 목적으로 사용할 수 없습니다. 또한 회원은 악의적인 사용을 통해 ㈜클로토와 Seemple에 대한 평판 위험을 발생시켜서는 아니됩니다. 악의적 사용이란 Seemple을 사용하는
          과정에서 고의적인 입력값을 통해 부당한 차별적 의견 또는 부당한 가치 판단 등 편향적인 결과값을 발생시키는 것이 대표적이며, 그 밖에도 회원이 악의적으로 Seemple을 사용하여 발생시키는 문제를
          포함합니다.
        </p>
        <p>
          회원은 서비스 이용 과정에서 발생한 AI 결과값(이하 &lsquo;AI 결과값&rsquo;)을 SNS 서비스 및 기타 방법에 의해 외부에 공개하며 제3자에게 알리는 경우 출력 데이터에 저작권 침해, 폭력적 표현,
          선정적 표현, 차별적 표현, 기타 부적절하거나 제3자에게 기타 권리침해, 불이익, 손해, 불쾌감을 주는 정보가 포함되어 있지 않음을 반드시 확인해야합니다.
        </p>
        <p>AI 결과값과 관련하여 법적∙윤리적 문제가 발생하거나, 제3자와 일체의 분쟁이 발생하는 경우 이에 대한 모든 책임은 이용자에게 있습니다.</p>
        <p>
          회원은 Seemple을 사용하며 발생한 &lsquo;문제가 되거나 문제가 될 수 있다고 판단한 결과물&rsquo;에 대해서는 발견 즉시(주)클로토에 해당 내용을 알려야 합니다. 이를 통해 이용자는 (주)클로토가
          관련 부분을 개선할 수 있도록, 이에 대해 적극적으로 협조할 의무가 있습니다.
        </p>
        <p>본 규정을 준수하지 않은 회원은 제6장에 따라 별도의 공지 없이 이용이 정지되거나 제한될 수 있으며, 회사는 이에 관한 책임을 부담하지 않습니다.</p>
        <p>회원이 AI 결과값을 외부에 공개하는 과정에서 회사에 피해를 입힌 경우 그에 대한 법률상 책임을 부담합니다.</p>
        <p>회원의 입력값과 결과값 및 Seemple내의 이용기록은 더 나은 서비스 제공을 위한 Seemple의 엔진 개선에 활용될 수 있으며 모델 평가에 사용될 수 있습니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제15조 (AI 윤리 규정의 적용 범위)</p>
        <p>㈜클로토의 &nbsp;AI 윤리 규정은 지속적으로 개정될 수 있으며, 회사는 이를 공지합니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 6 장 이용계약 해지 등</p>
        <p>제 16 조 (이용계약 해지)</p>
        <p>①여러분이 Seemple 이용을 더 이상 원치 않는 때에는 언제든지 서비스 내 제공되는 메뉴를 이용하여 이용계약의 해지 신청을 할 수 있으며, 회사는 법령이 정하는 바에 따라 신속히 처리하겠습니다.</p>
        <p>
          ②회사는 법령에서 정하는 기간 동안 여러분이 Seemple 서비스를 이용하기 위해 계정 로그인 혹은 접속한 기록이 없는 경우 여러분이 등록한 이메일주소, 휴대폰번호로 이메일, 문자메시지 또는
          &nbsp;메시지를 보내는 등 기타 유효한 수단으로 통지 후 여러분의 Seemple계정 정보를 파기하거나 분리 보관할 수 있으며, 이로 인해 Seemple 서비스 이용을 위한 필수적인 정보가 부족할 경우
          이용계약이 해지될 수도 있습니다.
        </p>
        <p>
          ③이용계약이 해지되면 법령 및 개인정보 처리방침에 따라 여러분의 정보를 보유하는 경우를 제외하고는 여러분의 Seemple계정 정보 및 Seemple계정으로 이용하였던 개별 서비스 데이터는 삭제됩니다.
          다만, 여러분이 개별 서비스 내에서 작성한 게시물 등 모든 데이터의 삭제와 관련한 사항은 개별 서비스의 약관에 따릅니다.
        </p>
        <p>④이용계약이 해지된 경우라도 여러분은 다시 회사에 대하여 이용계약의 체결을 신청할 수 있습니다.</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 17 조 (손해배상)</p>
        <p>
          ①회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사는 CP(Contents Provider)가 제공하거나
          회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 여러분의 손해에 대하여는 책임을 부담하지 아니합니다.
        </p>
        <p>
          ②회사는 회사의 과실로 인하여 여러분이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 여러분의 손해를 배상하겠습니다. 다만 회사는 회사의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을
          부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
        </p>
        <p>1.천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해</p>
        <p>2.여러분의 귀책사유로 서비스 이용에 장애가 발생한 경우</p>
        <p>3.서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해</p>
        <p>4.제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해</p>
        <p>5.제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해</p>
        <p>6.제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</p>
        <p>7.전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해</p>
        <p>8.기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</p>
        <p><o:p>&nbsp;</o:p></p>
        <p>제 18 조 (분쟁의 해결)</p>
        <p>
          본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 회사와 여러분 간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지
          않으면 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
        </p>
        <p><o:p>&nbsp;</o:p></p>
        <p>공고일자 : 2024년 03월 03일</p>
        <p>시행일자 : 2024년 04월 03일</p>
        <p><o:p>&nbsp;</o:p></p>
      </div>
    </section>
  </defaultPopupStyles>
</template>

<style lang="scss" scoped>
  * {
    line-height: normal;
    font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans JP', sans-serif !important;
  }
  table {
    width: 100% !important;
  }
</style>
