import axios from '@/axios';

export default {
  //위치기반 투표,라이브샷,미팅
  checkRealTimeLocation(param) {
    return axios.post('/community/location/check/alarm', param);
  },
  //투표 등록
  updateVote(param) {
    return axios.post('/vote/upload', param);
  },

  //투표 상세
  getVoteDetails(param) {
    return axios.post('/vote/detail', param);
  },
  //투표 참여
  registerVote(param) {
    return axios.post('/vote/apply', param);
  },

  //투표 참여 이후 결과
  getVoteResult(param) {
    return axios.post('/vote/viewPage', param);
  },
};
