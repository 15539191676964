import { acceptHMRUpdate, defineStore } from 'pinia';
import { deleteAlarmList, getAlarmCount, getAlarmList } from '@/api/alarm/alarmApi';

export const useAlarmStore = defineStore('useAlarm', {
  state: () => {
    return {
      alarmList: [],
      alarmCount: 0,
      realTimeLocation: {},
      offset: 1,
    };
  },

  getters: {
    nameUppercased: state => state.name.toUpperCase(),
  },

  actions: {
    fetchAlarms() {
      getAlarmList({ offset: this.offset })
        .then(res => {
          if (res.code === 200) {
            this.alarmList = res.data; // 상태 업데이트
          }
        })
        .catch(error => {
          // 네트워크 오류 또는 기타 오류 처리
        })
        .finally(() => {});
    },
    setAlarmCount() {
      getAlarmCount().then(res => {
        if (res.code == 200) {
          this.alarmCount = res.data.count; // 상태 업데이트
        } else {
          tostAlert(res.message);
        }
      });
    },
    deleteAlarms() {
      deleteAlarmList()
        .then(res => {
          if (res.code === 200) {
            this.alarmList = [];
            tostAlert('알림 리스트가 삭제되었습니다.');
          }
        })
        .catch(error => {
          // 네트워크 오류 또는 기타 오류 처리
        });
    },
    setMyLocation(location) {
      this.realTimeLocation = location;
    },

    setFilterList(data) {
      console.log(this.alarmList);
      this.alarmList = this.alarmList.map(alarm => {
        if (alarm.alarm_target_info_id === data.alarm_target_info_id) {
          return {
            ...alarm,
            success: true,
          };
        }
        return alarm;
      });
      console.log(this.alarmList);
    },
  },
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAlarmStore, import.meta.hot));
