<script setup>
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { ref } from 'vue';

  const props = defineProps({
    forbiddenWord: String,
  });
  const emit = defineEmits(['close']);
  const usePopup = usePopupStore();

  const handleClose = () => {
    emit('close');
  };
</script>

<template>
  <div class="pop_wrap">
    <div class="dim" @click="handleClose()"></div>
    <div class="pop_inner">
      <div class="pop_alert_con v2">
        <h2 class="title">[{{ props.forbiddenWord }}]은 금칙어로 등록되어 입력되지 않습니다.</h2>
        <p class="content">해당 금칙어는 삭제해주세요.</p>
      </div>
      <div class="btm_btn">
        <button class="default_btn medium" type="button" @click="handleClose()">확인</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .pop_wrap {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    max-width: 500px;
    z-index: 10000;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    .pop_inner {
      z-index: 102;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 16px;
      background: #fff;
      width: calc(100% - 56px);
      overflow: hidden;
    }
    .dim {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 101;
      max-height: 100vh;
    }
  }
  .title {
    margin-top: 0 !important;
  }
</style>
