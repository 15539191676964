<script setup>
  import PopHeaderVue from '@Components/common/PopHeaderVue.vue';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { onMounted, reactive, ref, watch } from 'vue';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import { useRoute, useRouter } from 'vue-router';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import defaultPopupStyles from '@/styled/defaultPopupStyles';
  import operatorApi from '@/api/operator/operatorApi';
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import { formatTime, locationCommActivity } from '@/utill/common';
  import PointInfoVue from '@Components/PointInfoVue.vue';
  import liveShotApi from '@/api/active/liveShotApi';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import axios from 'axios';
  import { useAlarmStore } from '@/stores/useAlarmStore';
  import { usePopupStore } from '@/stores/usePopupStore';

  const loading = useLoadingStore();
  const emit = defineEmits(['close']);
  const props = defineProps(['detail']);
  const memberInfo = useUserInfoStore();
  const useAlarm = useAlarmStore();
  const usePopup = usePopupStore();
  const confirmPopup = ref();
  const finalConfirmPopup = ref();
  const state = reactive({
    popTitle: '',
    popContent: '',
    isSuccess: false,
  });

  onMounted(() => {});
  const handleClose = () => {
    emit('close');
  };
  // 각도를 라디안으로 변환하는 함수 (화살표 함수)
  const deg2rad = deg => deg * (Math.PI / 180);

  // 두 지점 간의 거리를 계산하는 함수 (화살표 함수, 단위: km)
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // 지구 반지름 (단위: km)
    const dLat = deg2rad(lat2 - lat1); // 위도 차이 (라디안 단위로 변환)
    const dLon = deg2rad(lon2 - lon1); // 경도 차이 (라디안 단위로 변환)
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // 거리 계산 (단위: km)
  };

  // 1km 이내인지 확인하는 함수 (화살표 함수)
  const isWithin1Km = (currentLat, currentLon, targetLat, targetLon) => {
    const distance = getDistanceFromLatLonInKm(currentLat, currentLon, targetLat, targetLon);
    return distance <= 1; // 1km 이내면 true 반환
  };

  //신청하기
  const handleSubmit = value => {
    const today = new Date();
    if (props.detail.user_info_id === memberInfo.memberInfo.user_info_id) {
      return tostAlert('본인 게시물에 신청하실 수 없습니다.');
    }

    if (props.detail.start_date >= today) {
      return tostAlert('신청 기간이 아닙니다.');
    }
    if (value) {
      const isWithinRange = isWithin1Km(memberInfo.latlng.latitude, memberInfo.latlng.longitude, props.detail.latitude, props.detail.longitude);
      if (useAlarm.realTimeLocation.city === undefined) {
        return tostAlert('위치 정보가 지연되고 있습니다. 다시 한 번 시도해주세요. ');
      }
      if (!isWithinRange) {
        confirmPopup.value.handlePopClose();
        return tostAlert('라이브샷 신청이 불가한 지역입니다.');
      }
      confirmPopup.value.handlePopOpen();
      console.log('12312312');
    } else {
      handleClose();
    }
  };
  const handleConfirm = value => {
    if (value) {
      liveShotApi.applyLiveShot({ post_info_id: props.detail.post_info_id }).then(res => {
        if (res.code === 200) {
          finalConfirmPopup.value.handlePopOpen();
        } else {
          tostAlert(res.message);

          handleClose();
        }
      });
    }
    confirmPopup.value.handlePopClose();
  };
  const handleFinalSubmit = () => {
    confirmPopup.value.handlePopClose();
    tostAlert('라이브샷을 신청하셨습니다.');
    handleClose();
  };

  onMounted(async () => {
    // loading.setUpdateIsLoadData(false);
  });
</script>

<template>
  <defaultPopupStyles>
    <PopHeaderVue title="라이브샷 요청" @close="handleClose" />
    <section class="post_section">
      <div class="inner">
        <div class="member_item">
          <div class="board_title flex-center-2">
            <img v-if="props.detail.comm_kind !== 'open'" alt="" class="icons" src="@/assets/img/community_icon.svg" />
            <span v-if="props.detail.comm_kind !== 'open'" class="badge">{{ props.detail.priv_category_name }}</span>
            <span>{{ props.detail.comm_name }}</span>
          </div>
          <div class="user_pf board">
            <div :class="[{ citation: props.detail.certified === '인증회원' }, 'pf_img small']">
              <img v-if="props.detail.img_path" :alt="props.detail.img_path.nickname" :src="props.detail.img_path" />
              <img v-else alt="기본 유저 프로필 사진" src="@/assets/img/ico_my_pf.png" />
            </div>
            <div class="user_top">
              <span class="name">{{ props.detail.nickname }}</span>
              <div class="flex-center-4 post">
                <span>{{ props.detail.grade_name }}</span>
                <span class="dot"></span>
                <span>{{ formatTime(props.detail.insert_date) }}</span>
              </div>
            </div>
          </div>
          <div class="post_con">
            <h6 class="title ellipsis1 end">{{ props.detail.title }}</h6>
            <h6 class="content ellipsis1 end">{{ props.detail.content }}</h6>
          </div>
          <PointInfoVue :community="props.detail" />
          <ul v-if="props.detail.keywords && props.detail.keywords.length > 0" class="tag_list flex-center-4">
            <li v-for="(keyword, idx) in props.detail.keywords" :key="idx" class="keyword">#{{ keyword }}</li>
          </ul>
        </div>
      </div>
    </section>
    <div class="popup_footer">
      <button class="default_btn gray" type="button" @click="handleSubmit(false)">다음 기회에</button>
      <button :class="{ gray: !useAlarm.realTimeLocation.city }" class="default_btn" type="button" @click="handleSubmit(true)">라이브샷 신청하기</button>
    </div>
  </defaultPopupStyles>

  <!-- 확인하고 신청 -->
  <PopUpVue ref="confirmPopup">
    <div class="pop_alert_con v2">
      <h2 class="title">
        라이브샷 내용을 확인하고<br />
        신청하시겠습니까?
      </h2>
    </div>
    <div class="btm_btn">
      <button class="default_btn medium gray" type="button" @click="handleConfirm(false)">취소</button>
      <button class="default_btn medium" type="button" @click="handleConfirm(true)">확인</button>
    </div>
  </PopUpVue>
  <!-- 완료 -->
  <PopUpVue ref="finalConfirmPopup">
    <div class="pop_alert_con v2">
      <h2 class="title">라이브샷 내용을 확인하고 신청하시겠습니까?</h2>
      <p class="content">별도 승인 심사 후 최종선정여부는 알림과 마이페이지에서 확인할 수 있습니다.</p>
    </div>
    <div class="btm_btn">
      <button class="default_btn medium" type="button" @click="handleFinalSubmit(true)">확인</button>
    </div>
  </PopUpVue>
</template>

<style lang="scss" scoped>
  .post_section {
    margin-top: 8px;
    &:deep {
      .point_info_wrap {
        margin-bottom: 0;
      }
    }
    .member_item {
      border-bottom: none;
    }
  }
</style>
