<script setup>
  import { useBottomSheetStore } from '@/stores/useBottomSheetStore';
  import { useCommunitySearchFilterStore } from '@/stores/useCommunitySearchFilterStore';
  import { onMounted, reactive, ref, watch } from 'vue';
  import BottomSheet from '@Components/common/BottomSheet.vue';
  import { useRouter } from 'vue-router';

  const emit = defineEmits(['search']);
  const isVisible = useBottomSheetStore();
  const router = useRouter();
  const useCommunitySearchFilter = useCommunitySearchFilterStore();
  const BottomSheetVal = ref(null);
  const state = reactive({
    selectSearchFilterValue: '전체',
    filterList: ['전체', '댓글순', '조회순'],
    isVisible: false,
    isSearchFilter: {
      mainFilter: [
        {
          name: 'upvote',
          isCheck: false,
        },
        {
          name: 'meeting',
          isCheck: false,
        },
        {
          name: 'liveshot',
          isCheck: false,
        },
      ],
    },
  });
  const handleOpenSearchFilter = () => {
    document.body.style.overflow = 'hidden';
    state.isVisible = true;
    BottomSheetVal.value.handleOpen();
  };
  // 필터 값 변환
  const filterTransform = type => {
    switch (type) {
      case '댓글순':
        return 'comment';
      case '조회순':
        return 'view';
      default:
        return 'all';
    }
  };
  /**
   * handleSetValue 자세한 검색필터 값
   *  전체
   *  댓글순
   *  조회순
   * @param data
   */
  const handleSetValue = data => {
    if (data) {
      state.selectSearchFilterValue = data;
      useCommunitySearchFilter.setSubFilterKind(filterTransform(data));

      //팝업 닫고 , 바디 스크롤 제거
      BottomSheetVal.value.handleClose();
      document.body.style.overflow = 'auto';

      emit('subSearchFilter', useCommunitySearchFilter.subFilterKind);
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  /**
   * handleSearchFilter 검색필터 요청 하는 함수
   * @param type
   */
  const handleSearchFilter = type => {
    state.isSearchFilter.mainFilter = state.isSearchFilter.mainFilter.map(item => ({
      ...item,
      isCheck: item.name === type,
    }));
    useCommunitySearchFilter.setMainFilterKind(type);
    emit('search', useCommunitySearchFilter.mainFilterKind);
  };
  onMounted(() => {});
  watch(
    () => useCommunitySearchFilter.mainFilterKind,
    newValue => {
      if (newValue === '') {
        state.isSearchFilter.mainFilter = state.isSearchFilter.mainFilter.map(item => ({
          ...item,
          isCheck: false,
        }));
      }
    },
  );
  watch(
    () => useCommunitySearchFilter.subFilterKind,
    newValue => {
      if (newValue === '') {
        state.selectSearchFilterValue = '전체';
      }
    },
  );
</script>

<template>
  <div class="search_filter_con">
    <div class="left_con flex-center-8">
      <button :class="{ check: state.isSearchFilter.mainFilter.at(0).isCheck }" class="default_btn white small" type="button" @click="handleSearchFilter('upvote')">🔺 UP VOTE</button>
      <button :class="{ check: state.isSearchFilter.mainFilter.at(1).isCheck }" class="default_btn white small" type="button" @click="handleSearchFilter('meeting')">👀 미팅</button>
      <button :class="{ check: state.isSearchFilter.mainFilter.at(2).isCheck }" class="default_btn white small" type="button" @click="handleSearchFilter('liveshot')">📸 라이브샷</button>
    </div>
    <div v-if="!state.isSearchFilter.mainFilter.at(2).isCheck" class="right_con">
      <button class="search_select" type="button" @click="handleOpenSearchFilter">
        <span class="text">{{ state.selectSearchFilterValue }}</span>
        <img alt="" class="icons" src="@/assets/img/bottom_arrow.svg" />
      </button>
    </div>
  </div>
  <BottomSheet ref="BottomSheetVal" :isVisible="state.isVisible" @changeVisible="handleSetValue">
    <div class="search_filter_wrap" @mousedown.stop @mousemove.stop @mouseup.stop @touchend.stop @touchmove.stop @touchstart.stop>
      <h3 class="title">출력 순서 선택</h3>
      <ul class="search_filter_list">
        <li v-for="(item, idx) in state.filterList" :key="idx" class="search_filter_list_item">
          <button class="filter_btn" type="button" @click="handleSetValue(item)">{{ item }}</button>
        </li>
      </ul>
    </div>
  </BottomSheet>
</template>

<style lang="scss" scoped>
  // 검색필터 css
  .search_filter_wrap {
    padding: 0 24px;

    .title {
      color: #000;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.48px;
      margin-bottom: 26px;
    }

    .search_filter_list {
      .search_filter_list_item {
        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .filter_btn {
          display: block;
          width: 100%;
          color: #000;
          font: $text-body14-400;
          letter-spacing: -0.42px;
          text-align: left;
        }
      }
    }
  }

  .search_filter_con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 0 16px;
    border-bottom: 1px solid $blue-g10;

    .left_con {
      .default_btn {
        display: flex;
        align-items: center;
        gap: 2px;

        &.check {
          border-color: $purple-20;
          background-color: $purple-40;
        }
      }
    }

    .right_con {
      .search_select {
        .text {
          color: #5d688a;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.36px;
        }

        .icons {
          vertical-align: middle;
        }
      }
    }
  }
</style>
