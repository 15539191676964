import axios from '@/axios';

export default {
  getLiveShotDetails(param) {
    return axios.post('/mypage/liveshot/detail', param);
  },
  applyLiveShot(param) {
    return axios.post('/community/liveshot/apply', param);
  },
  checkLiveLocation(param) {
    return axios.post('/search/locationCheck', param);
  },
};
