import { acceptHMRUpdate, defineStore } from 'pinia';
import { deleteAlarmList, getAlarmList } from '@/api/alarm/alarmApi';

export const usePostStore = defineStore('usePost', {
  state: () => {
    return {
      postFormData: {},
    };
  },

  getters: {},

  actions: {
    setPostData(data) {
      console.log('postFormData', data);
      this.postFormData = data;
    },
    reset() {
      this.postFormData = {};
    },
  },
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(usePostStore, import.meta.hot));
