<script setup>
  import { defineAsyncComponent, onMounted, reactive } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import { useRoute, useRouter } from 'vue-router';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { useLoadingStore } from '@/stores/useLoadingStore';

  const router = useRouter();
  const route = useRoute();
  const loading = useLoadingStore();
  const state = reactive({
    swiperOption: {
      slidesPerView: 'auto',
      spaceBetween: 8,
    },
    myCommunityList: null,
  });
  const loadMyCommunityRequest = () => {
    CommunityApiRequest.myCommunityListReuqest().then(res => {
      state.myCommunityList = res.data.my_community_list;
      setTimeout(() => {
        loading.setUpdateIsLoadData(true);
      }, 100);
    });
  };
  const handleOnSwiper = swiper => {
    console.log('-> swiper', swiper);
  };
  const handleCommunityView = async value => {
    console.log('=>(CommunityVue.vue:31) value', value);
    const asyncPageWithOptions = defineAsyncComponent({
      loader: () => import('../../pages/community/employment/[...all].vue'),
      delay: 500,
      timeout: 3000,
      loading: () => import('@Components/loadingComponent/LoadingComponetVue.vue'),
      suspensible: true,
    });
    // router.push(`/community/${value}`);
    await router.push({
      path: `/community/employment/${value.c_info_id}&${value.type}`,
      component: asyncPageWithOptions,
    });
  };
  /**
   * handleAddCommunity 커뮤니티 추가 컴포넌트
   */
  const handleAddCommunity = () => {
    router.push('/add/AddCommunityVue');
  };
  onMounted(() => {
    loadMyCommunityRequest();
  });
</script>

<template>
  <div class="community_wrap">
    <div class="community_inner">
      <div class="signin_community">
        <button class="signin_btn" type="button" @click="handleAddCommunity">
          <img alt="" src="@/assets/img/plus_icon.svg" />
        </button>
        <p class="notice_text">추가하기</p>
      </div>
      <div class="community_list_con">
        <Swiper class="community_list" v-bind="state.swiperOption" @swiper="handleOnSwiper">
          <swiper-slide v-for="(Mycommunity, idx) in state.myCommunityList" :key="idx" class="community_list_item">
            <div class="image_con" @click="handleCommunityView(Mycommunity)">
              <span v-if="Mycommunity.type === 'priv'" class="bage">
                <img alt="" src="@/assets/img/community_icon.svg" />
              </span>
              <div class="image_tag">
                <img v-if="Mycommunity.img_path" :src="Mycommunity.img_path" alt="" />
                <img v-else alt="" src="@/assets/img/community_default_image.svg" />
              </div>
            </div>
            <p class="community_text">{{ Mycommunity.community_name }}</p>
          </swiper-slide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .community_wrap {
    position: relative;
    margin-top: 16px;
    .community_inner {
      display: flex;
      align-items: stretch;

      .signin_community {
        padding-right: 16px;
        border-right: solid 1px #7740ff;
        margin-right: 14px;
        .signin_btn {
          position: relative;
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: #7740ff;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .notice_text {
          margin-top: 8px !important;
          color: #dcceff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.3px;
        }
      }
      .community_list_con {
        height: 84px;
        overflow: hidden;
        .community_list {
          width: 100%;
          .community_list_item {
            text-align: center;
            width: 54px;
            &:not(:last-child) {
              margin-right: 14px;
            }
            .image_con {
              display: inline-block;
              position: relative;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              .bage {
                position: absolute;
                top: 0;
                left: 0;
              }
              .image_tag {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                border: solid 1px #7740ff;
                img {
                  width: 100%;
                  object-fit: cover;
                  aspect-ratio: 1/1;
                }
              }
            }
            .community_text {
              color: #fff;
              text-align: center;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 10px;
              font-style: normal;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.3px;
              text-overflow: ellipsis;
              word-break: keep-all;
              white-space: pre-line;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
</style>
