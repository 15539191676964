<script>
  import bottomeSheetStyled from '@/styled/bottomeSheetStyled';

  export default {
    components: {
      bottomeSheetStyled,
    },
  };
</script>
<script setup>
  import { computed, reactive, ref } from 'vue';
  import BottomSheet from '@Components/common/BottomSheet.vue';
  import { useBottomSheetStore } from '@/stores/useBottomSheetStore';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import DeclarationListVue from '@Components/DeclarationListVue.vue';
  import { useRoute, useRouter } from 'vue-router';
  import ConfirmVue from '@Components/common/ConfirmVue.vue';
  import { useEditCommunityStore } from '@/stores/useEditCommunityStore';
  import SingleCalendarVue from '@Components/SingleCalendarVue.vue';
  import SingleModalVue from '@Components/common/SingleModalVue.vue';
  import { useCommnetListStore } from '@/stores/useCommnetListStore';
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import { PublicChannelFilter } from '@sendbird/chat/groupChannel';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { useMeetingStore } from '@/stores/useMeetingStore';

  const useComment = useCommnetListStore();
  const SingleModalVueRef = ref();
  const communityInfo = useEditCommunityStore();
  const router = useRouter();
  const route = useRoute();
  const handlePopOpen = ref(null);
  const BottomSheetVal = ref(null);
  const ConfirmVueRef = ref(null);
  const confirmPopup = ref();
  const useBottomSheet = useBottomSheetStore();
  const usePopup = usePopupStore();
  const useMeeting = useMeetingStore();

  let modalTitle = ref('댓글이 1개 이상 있는 게시글은 <br/> 삭제할 수 없습니다.');
  const state = reactive({
    isBottomSheet: false,
    isManage: false,
    confirmText: '',
    confirmType: '',
    isPopup: false,
  });
  const props = defineProps({
    data: Object,
    list: Object,
  });
  const emit = defineEmits(['deleteCommunity', 'handleInterceptionSubmit', 'handleManageAction']);
  const userInfo = useUserInfoStore();

  console.log('=>(CommunityUserInfoVue.vue:6) props', props.data);
  console.log(props.data.insert_date);
  const timeDifference = computed(() => {
    const insertDate = new Date(props.data.insert_date);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - insertDate.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years}년 전`;
    } else if (months > 0) {
      return `${months}달 전`;
    } else if (days > 0) {
      return `${days}일 전`;
    } else if (hours > 0) {
      return `${hours}시간 전`;
    } else if (minutes > 0) {
      return `${minutes}분 전`;
    } else {
      return `${seconds}초 전`;
    }
  });
  const handleRouterPushDetailContent = getData => {
    console.log('=>(CommunityUserInfoVue.vue:36) getData', getData);
    console.log('게시글 상세 이동');
  };
  console.log('=>(CommunityUserInfoVue.vue:34) timeDifference', timeDifference);

  const handleOption = () => {
    console.log('=>(CommunityUserInfoVue.vue:61) userInfo.memberInfo', userInfo.memberInfo);
    console.log('=>(CommunityUserInfoVue.vue:62) props.data', props.data);
    if (route.path.includes('management')) {
      state.isBottomSheet = false;
      state.isManage = true;
    } else if (userInfo.memberInfo.user_info_id === props.data.user_info_id) {
      state.isBottomSheet = true;
    }
    BottomSheetVal.value.handleOpen();
    usePopup.popups.isCommonPopup = true;
  };
  const handleCloseOptions = () => {
    usePopup.popups.isCommonPopup = false;
    useBottomSheet.setUpdate(false);
    useBottomSheet.setUpdateIsMyComment(false);
  };
  // 신고 팝업 오픈
  const handleReportPostPopOpen = () => {
    handlePopOpen.value.handlePostPopOpen({ data: props.data, type: 'POST' });
    usePopup.popups.isCommonPopup = false;
    BottomSheetVal.value.handleClose();
  };
  /**
   * 수정삭제
   * @param type {String} edit del block
   */
  const handlePostAction = type => {
    const startDate = new Date(props.data.start_date);
    const date = new Date();
    state.confirmType = type;
    BottomSheetVal.value.handleClose();
    state.isPopup = false;
    if (type !== 'edit' && type !== 'del') {
      state.confirmText = '차단하시겠습니까?';
      ConfirmVueRef.value.handleOpenPop();
      usePopup.popups.isCommonPopup = true;
      return;
    }

    confirmPopup.value.handlePopOpen();
    if (props.data.post_kind !== 'post' && startDate >= date) {
      state.confirmText = type === 'edit' ? '수정하시겠습니까?' : '삭제하시겠습니까?';
      return;
    }

    if (props.data.post_kind === 'post' && props.data.comment_cnt > 0) {
      state.isPopup = true;
      state.confirmText = `댓글이 1개 이상 있는 게시글은 ${type === 'edit' ? '수정' : '삭제'}<br>할 수 없습니다.`;
    } else if (props.data.post_kind !== 'post' && startDate <= date) {
      state.isPopup = true;
      state.confirmText = `노출기간 전에만 ${type === 'edit' ? '수정' : '삭제'}할 수 있습니다.`;
    } else {
      state.confirmText = type === 'edit' ? '수정하시겠습니까?' : '삭제하시겠습니까?';
    }

    // Handle the 'block' case
  };
  //확인 팝업
  const handleConfirm = value => {
    if (value) {
      switch (state.confirmType) {
        case 'edit':
          return handleEditPost();
        case 'del':
          return handleDeletePost();
        default:
          break;
      }
    }
    usePopup.popups.isCommonPopup = false;
    confirmPopup.value.handlePopClose();
  };

  const handleManageAction = type => {
    emit('handleManageAction', type, props.data);
    BottomSheetVal.value.handleClose();
  };
  // 게시물 차단
  const handleInterceptionSubmit = data => {
    if (data) {
      const requestData = {
        type: '', // 오픈, 프라이빗 구분 (open, private)
        block_user_info_id: props.data.user_info_id, // 신고 대상자 회원 고유번호
        c_post_info_id: props.data.post_info_id,
      };

      let communityType = '';
      if (route.params.hasOwnProperty('openContent')) {
        communityType = 'open';
        requestData.type = communityType;
        requestData.c_post_info_id = props.data.post_info_id;
      } else if (route.params.hasOwnProperty('postContent')) {
        communityType = 'private';
        requestData.type = communityType;
        requestData.c_post_info_id = props.data.post_info_id;
      } else {
        requestData.c_post_info_id = props.data.post_info_id;
        requestData.type = props.data.type === 'private' ? 'private' : 'open';
      }
      console.log('=>(CommunityUserInfoVue.vue:116) requestData', requestData);

      if (props.data.kind !== 'meeting') {
        CommunityApiRequest.postBlockRequest(requestData).then(res => {
          if (res.code === 200) {
            tostAlert('차단 되었습니다.');
            ConfirmVueRef.value.handleOpenPop();

            router.push('/');
          } else {
            tostAlert(res.message);
            ConfirmVueRef.value.handleOpenPop();
            usePopup.popups.isCommonPopup = false;
          }
        });
      } else if (props.data.kind === 'meeting') {
        CommunityApiRequest.meetingPostBlockReqeust(requestData).then(res => {
          if (res.code === 200) {
            tostAlert('차단 되었습니다.');
            ConfirmVueRef.value.handleOpenPop();
            usePopup.popups.isCommonPopup = false;
            router.push('/');
          } else {
            tostAlert(res.message);
            ConfirmVueRef.value.handleOpenPop();
            usePopup.popups.isCommonPopup = false;
          }
        });
      }

      emit('handleInterceptionSubmit', props.data.user_info_id);
    } else {
      ConfirmVueRef.value.handleOpenPop();
      usePopup.popups.isCommonPopup = false;
    }
  };
  // 게시물 신고
  const handleReportPost = data => {
    console.log('=>(CommunityUserInfoVue.vue:83) data', data);
    console.log('=>(CommunityUserInfoVue.vue:77) props.data', props.data);
    console.log('=>(DeclarationListVue.vue:54) route.params', route.params);
    const requestData = {
      type: '', // 오픈, 프라이빗 구분 (open, private)
      report_user_info_id: props.data.user_info_id, // 신고 대상자 회원 고유번호
      report_category: data, // 신고 항목
    };

    let communityType = '';
    if (route.params.hasOwnProperty('openContent')) {
      communityType = 'open';
      requestData.type = communityType;
      requestData.type = communityType;
      requestData.c_post_info_id = props.data.post_info_id;
    } else if (route.params.hasOwnProperty('postContent')) {
      communityType = 'private';
      requestData.type = communityType;
      requestData.c_post_info_id = props.data.post_info_id;
    } else {
      requestData.c_post_info_id = props.data.post_info_id;
      requestData.type = props.data.type === 'private' ? 'private' : 'open';
    }

    console.log('=>(CommunityUserInfoVue.vue:101) requestData', requestData);

    if (props.data.post_kind !== 'meeting') {
      CommunityApiRequest.privateReportPostRequest(requestData).then(res => {
        console.log('=>(CommunityUserInfoVue.vue:77) res', res);
        if (res.code === 200) {
          tostAlert('신고 요청 되었습니다.');
        } else {
          tostAlert(res.message);
        }
        handlePopOpen.value.handlePostPopClose();
        usePopup.popups.isCommonPopup = false;
      });
    } else if (props.data.post_kind === 'meeting') {
      CommunityApiRequest.meetingPostDeclaration(requestData).then(res => {
        console.log('=>(CommunityUserInfoVue.vue:77) res', res);
        if (res.code === 200) {
          tostAlert('신고 요청 되었습니다.');
        } else {
          tostAlert(res.message);
        }
        handlePopOpen.value.handlePostPopClose();
        usePopup.popups.isCommonPopup = false;
      });
    }
  };
  // 게시물 수정
  const handleEditPost = () => {
    if (props.data.hasOwnProperty('post_kind') && props.data.post_kind === 'meeting') {
      // 미팅 게시물일때 댓글 존재

      router.push('/community/MeetingEditVue');
    } else if (props.data.post_kind === 'liveshot') {
      router.push('/community/EditLiveShot');
      useMeeting.setMeetingFormData(props.data);
    } else {
      router.push('/community/EditVue');
    }
    communityInfo.setUpdateCommunityInfo(props.data);
    usePopup.popups.isCommonPopup = false;
    confirmPopup.value.handlePopClose();
  };
  // 게시물 삭제
  const handleDeletePost = () => {
    let getId = '';
    let filteredArray = '';
    let requestData = null;
    if (props.list) {
      getId = props.list.filter(item => item.type === props.data.type && item.post_info_id === props.data.post_info_id);
      filteredArray = props.list.filter(item => !(item.type === props.data.type && item.post_info_id === props.data.post_info_id));
      console.log(getId, 'getId');
      console.log(filteredArray, 'filteredArray');
      requestData = {
        type: getId.at(0).type,
        c_post_info_id: getId.at(0).post_info_id,
      };
    } else {
      requestData = {
        type: route.params.hasOwnProperty('openContent') ? 'open' : 'private',
        c_post_info_id: route.params.hasOwnProperty('openContent') ? props.data.post_info_id : props.data.post_info_id,
      };
    }

    if (props.data.post_kind === 'meeting') {
      // 미팅 게시물일시
      const requestData = {
        type: props.data.type,
        c_post_info_id: props.data.post_info_id,
      };
      CommunityApiRequest.deleteMeetingCommunityReuqest(requestData).then(res => {
        if (res.code === 200 && res.success) {
          tostAlert('삭제되었습니다.');

          emit('deleteCommunity', filteredArray);
          BottomSheetVal.value.handleClose();
        }
      });
    } else {
      // 게시물일떄
      CommunityApiRequest.deletedCommunityRequest(requestData).then(res => {
        if (res.code === 200) {
          emit('deleteCommunity', filteredArray);
          tostAlert('삭제되었습니다.');
          BottomSheetVal.value.handleClose();
        }
      });
    }
    if (route.path.includes('Content')) {
      router.go(-1);
    }
    usePopup.popups.isCommonPopup = false;
    confirmPopup.value.handlePopClose();
  };
  console.log('=>(CommunityUserInfoVue.vue:170) props.data', props.data);
</script>

<template>
  <div class="user_info_wrap">
    <div class="left_con" @click="handleRouterPushDetailContent(props.data)">
      <div class="user_profile_con">
        <img v-if="props.data.user_img_path ? props.data.user_img_path : props.data.img_path" :src="props.data.user_img_path ? props.data.user_img_path : props.data.img_path" alt="" />
        <img v-else alt="" src="@/assets/img/user_default_icon.svg" />
        <span v-if="props.data.certified === '인증회원'" class="user_bage"></span>
      </div>
      <div class="user_info">
        <span class="user_name">{{ props.data.nickname }}</span>
      </div>
      <div class="update_time">{{ props.data.grade_name }} &middot; {{ timeDifference }}</div>
    </div>

    <div v-if="props.data.meeting_close === 0" class="right_con">
      <button type="button" @click="handleOption">
        <img alt="" src="@/assets/img/option_icon.svg" />
      </button>
    </div>
  </div>
  <!-- 하단 팝업 -->
  <BottomSheet ref="BottomSheetVal" class="category_con" @changeVisible="handleCloseOptions">
    <bottomeSheetStyled>
      <div v-if="state.isBottomSheet" class="edit_wrap">
        <ul class="edit_list">
          <li class="edit_item">
            <button class="option_btn" type="button" @click="handlePostAction('edit')">수정</button>
          </li>
          <li class="edit_item">
            <button class="option_btn" type="button" @click="handlePostAction('del')">삭제</button>
          </li>
        </ul>
      </div>
      <div v-else-if="state.isManage">
        <div class="edit_wrap">
          <ul class="edit_list">
            <li class="edit_item">
              <button class="option_btn" type="button" @click="handleManageAction('expose')">상위 노출 요청</button>
            </li>
            <li class="edit_item">
              <button class="option_btn" type="button" @click="handleManageAction('hidden')">숨김</button>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div class="edit_wrap">
          <ul class="edit_list">
            <li class="edit_item">
              <button class="option_btn" type="button" @click="handlePostAction('block')">차단</button>
            </li>
            <li class="edit_item">
              <button class="option_btn" type="button" @click="handleReportPostPopOpen">신고</button>
            </li>
          </ul>
        </div>
      </div>
    </bottomeSheetStyled>
  </BottomSheet>
  <DeclarationListVue ref="handlePopOpen" @reportPost="handleReportPost" />
  <ConfirmVue ref="ConfirmVueRef" content="차단하시겠습니까?" @result="handleInterceptionSubmit" />
  <!--  차단하기 확인 팝업 -->
  <PopUpVue ref="confirmPopup">
    <div class="pop_alert_con v2">
      <h2 class="title" v-html="state.confirmText"></h2>
    </div>
    <div class="btm_btn">
      <button v-if="state.isPopup" class="default_btn medium" type="button" @click="handleConfirm(false)">확인</button>
      <button v-else class="default_btn medium gray" type="button" @click="handleConfirm(false)">취소</button>
      <button v-if="!state.isPopup" class="default_btn medium" type="button" @click="handleConfirm(true)">
        {{ state.confirmType === 'edit' ? '수정하기' : '삭제하기' }}
      </button>
    </div>
  </PopUpVue>
  <SingleModalVue ref="SingleModalVueRef" :comment="modalTitle" @confirm="SingleModalVueRef.handleShow()" />
</template>

<style lang="scss" scoped>
  .user_info_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_con {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .user_profile_con {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 4px;
        position: relative;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .user_bage {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
          width: 8px;
          height: 8px;
          background: url($certif-icon) no-repeat center / contain;
        }
      }

      .user_info {
        margin-right: 4px;

        .user_name {
          color: #000;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.42px;
          margin-right: 2px;
          position: relative;
        }
      }

      .update_time {
        position: relative;
        top: 1px;
        color: #727683;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.3px;
      }
    }
  }
</style>
