<script setup>
  import { useRouter } from 'vue-router';

  const props = defineProps({
    title: String,
    path: String,
    fuc: String,
    alarm: Boolean,
  });
  const emit = defineEmits(['close', 'handleDeleteAlarm']);
  const router = useRouter();
  const handleBack = () => {
    console.log('=>(TopHeaderVue.vue:13) props.fuc', props.fuc);
    if (props.fuc) {
      emit('close');
    } else {
      props.path ? router.push(`${path}`) : router.go(-1);
      emit('close');
    }
  };
  const handleAlarmDelete = () => {
    emit('handleDeleteAlarm');
  };
</script>

<template>
  <div class="header_wrap">
    <div class="inner">
      <button class="back_btn" type="button" @click="handleBack">
        <img alt="" class="icon" src="@/assets/img/header/back_icon.svg" />
      </button>
      <h3 class="title">{{ props.title }}</h3>
      <button v-if="props.alarm" class="alarm_del" type="button" @click="handleAlarmDelete">모두삭제</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header_wrap {
    height: 60px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    background-color: $white;
    z-index: 2;

    .inner {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      .back_btn {
        width: 24px;
        height: 24px;
      }

      .alarm_del {
        color: $blue-g40;
        font: $text-body14-400;
        letter-spacing: -0.42px;
      }

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font: $subtitle3;
        color: $black;
        line-height: 60px;
        letter-spacing: -0.48px;
      }
    }
  }
</style>
