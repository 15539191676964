<script>
  import commentAddStyled from '@/styled/commentAddStyled';

  export default {
    components: {
      commentAddStyled,
    },
  };
</script>
<script setup>
  import TopHeaderVue from '@Components/common/TopHeaderVue.vue';
  import { useCommnetListStore } from '@/stores/useCommnetListStore';
  import { onMounted, onUnmounted, reactive } from 'vue';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  const useComment = useCommnetListStore();
  const state = reactive({
    isCommentAdd: false,
    commentContent: '',
    title: useComment.commentType === 'REPL' ? '답글 등록' : useComment.commentType === 'EDIT' ? '댓글 수정' : '댓글 등록',
  });
  const emit = defineEmits(['update']);

  const handleClose = () => {
    useComment.setCommentAddUpdate(false);
  };
  const handleCommnetAdd = () => {
    state.commentContent ? (state.isCommentAdd = true) : (state.isCommentAdd = false);
  };
  const handleSubMit = () => {
    //TODO 댓글 수정
    if (useComment.commentType === 'EDIT') {
      console.log('=>(commentAddVue.vue:45) useComment.', useComment.communityType);
      if (useComment.communityType === 'PRIVATE') {
        const reqeustData = {
          pc_comment_info_id: Number(useComment.commentInfo.commentInfo.comment_info_id), // 프라이빗 커뮤니티 댓글 고유번호
          pc_post_info_id: Number(useComment.communityInfo.post_info_id), // 프라이빗 커뮤니티 게시물 고유번호
          content: state.commentContent, // 댓글/답글 수정 내용
        };
        CommunityApiRequest.commentEditRequest(reqeustData).then(res => {
          if (useComment.commentEditType === 'comment_rep') {
            const updateList = useComment.commentList.map(item => {
              const updatedReplies = item.replies.map(subItem => {
                if (subItem.pc_comment_info_id === res.data.at(0).comment_info_id) {
                  return res.data.at(0);
                } else {
                  return subItem;
                }
              });

              return { ...item, replies: updatedReplies };
            });
            useComment.setCommentListUpdate(updateList);
            useComment.setCommentAddUpdate(false);
          } else {
            console.log(res.data.at(0));
            const commentList = useComment.commentList.map(item => {
              if (item.pc_comment_info_id === res.data.at(0).pc_comment_info_id) {
                console.log(item);
                res.data.at(0).replies = [...item.replies];
                return res.data.at(0);
              } else {
                return item;
              }
            });
            // console.log('=>(commentAddVue.vue:70) commentList', commentList);
            useComment.setCommentListUpdate(commentList);
            useComment.setCommentAddUpdate(false);
          }
          useComment.reset();
          // console.log('=>(commentAddVue.vue:78) res', res.data.at(0));
          emit('update');
        });
      } else if (useComment.communityType === 'OPEN') {
        console.log('=>(commentAddVue.vue:80) useComment.commentInfo.commentInfo', useComment.commentInfo.commentInfo);
        console.log('=>(commentAddVue.vue:81) useComment.communityInfo', useComment.communityInfo);
        const reqeustData = {
          oc_comment_info_id: Number(useComment.commentInfo.commentInfo.comment_info_id), // 오픈 커뮤니티 댓글 고유번호
          oc_post_info_id: Number(useComment.communityInfo.post_info_id), // 오픈 커뮤니티 게시물 고유번호
          content: state.commentContent, // 댓글/답글 수정 내용
        };
        CommunityApiRequest.commentEditOpenRequest(reqeustData).then(res => {
          if (useComment.commentEditType === 'comment_rep') {
            const updateList = useComment.commentList.map(item => {
              const updatedReplies = item.replies.map(subItem => {
                if (subItem.comment_info_id === res.data.at(0).comment_info_id) {
                  return res.data.at(0);
                } else {
                  return subItem;
                }
              });

              return { ...item, replies: updatedReplies };
            });
            useComment.setCommentListUpdate(updateList);
            useComment.setCommentAddUpdate(false);
          } else {
            console.log(res.data.at(0));
            const commentList = useComment.commentList.map(item => {
              if (item.comment_info_id === res.data.at(0).comment_info_id) {
                console.log(item);
                res.data.at(0).replies = [...item.replies];
                return res.data.at(0);
              } else {
                return item;
              }
            });
            // console.log('=>(commentAddVue.vue:70) commentList', commentList);
            useComment.setCommentListUpdate(commentList);
            useComment.setCommentAddUpdate(false);
          }
          useComment.reset();
          // console.log('=>(commentAddVue.vue:78) res', res.data.at(0));
          emit('update');
        });
      }
    }
    if (useComment.commentType === 'ADD') {
      console.log('=>(commentAddVue.vue:38) useComment.communityType', useComment.communityInfo);
      if (!state.commentContent) {
        tostAlert('댓글 내용을 입력해주세요.');
        return false;
      }
      // console.log(useComment.commentList);
      // console.log(useComment.communityInfo);

      if (useComment.communityType === 'OPEN') {
        const requestData = {
          oc_info_id: useComment.communityInfo.comm_info_id, // 오픈 커뮤니티 고유번호
          oc_post_info_id: useComment.communityInfo.post_info_id, // 오픈 커뮤니티 게시글 고유번호
          parent_id: 0, // 댓글일 경우 0, 답글일 경우 답글 달아놓을 해당 댓글 고유번호 입력 (oc_comment_info_id)
          content: state.commentContent, // 댓글 내용
        };
        CommunityApiRequest.addCommentRequestApi(requestData).then(res => {
          console.log('=>(commentAddVue.vue:44) res', res);
          if (res.code === 200 && res.success) {
            useComment.setCommentListUpdate([res.data.at(0), ...useComment.commentList]);
            useComment.setCommentAddUpdate(false);
            useComment.reset();
            useComment.setUpdateCommentCount(useComment.commentCount + 1);
          } else {
            tostAlert(res.message);
          }
        });
      } else if (useComment.communityType === 'PRIVATE') {
        const requestData = {
          pc_info_id: useComment.communityInfo.comm_info_id, // 프라이빗 커뮤니티 고유번호
          pc_post_info_id: useComment.communityInfo.post_info_id, // 프라이빗 커뮤니티 게시글 고유번호
          parent_id: 0, // 댓글일 경우 0, 답글일 경우 답글 달아놓을 해당 댓글 고유번호 입력 (pc_comment_info_id)
          content: state.commentContent, // 댓글 내용
        };
        CommunityApiRequest.privateCommentAddRequest(requestData).then(res => {
          if (res.code === 200 && res.success) {
            useComment.setCommentListUpdate([res.data.at(0), ...useComment.commentList]);
            useComment.setCommentAddUpdate(false);
            useComment.reset();
            useComment.setUpdateCommentCount(useComment.commentCount + 1);
          } else {
            tostAlert(res.message);
          }
        });
      }
    } else if (useComment.commentType === 'REPL') {
      if (!state.commentContent) {
        tostAlert('답글 내용을 입력해주세요.');
        return false;
      }
      // TODO 댓글 입력
      console.log(useComment.commentInfo.commentInfo, 'useComment.commentInfo.commentInfo');
      if (useComment.communityType === 'OPEN') {
        const requestData = {
          oc_info_id: useComment.communityInfo.comm_info_id, // 오픈 커뮤니티 고유번호
          oc_post_info_id: useComment.communityInfo.post_info_id, // 오픈 커뮤니티 게시글 고유번호
          parent_id: useComment.commentInfo.commentInfo.comment_info_id, // 댓글일 경우 0, 답글일 경우 답글 달아놓을 해당 댓글 고유번호 입력 (oc_comment_info_id)
          content: state.commentContent, // 댓글 내용
        };
        console.log('=>(commentAddVue.vue:65) useComment.commentList', useComment.commentList);
        console.log('=>(commentAddVue.vue:65) useComment.commentInfo', useComment.commentInfo.commentInfo.replies);
        CommunityApiRequest.addCommentRequestApi(requestData).then(res => {
          let isUpdated = false;
          useComment.commentList.forEach((comment, idx) => {
            if (!isUpdated && comment.comment_info_id === useComment.commentInfo.commentInfo.comment_info_id) {
              console.log('=>(commentAddVue.vue:69) comment', comment);
              comment.replies = [...comment.replies, res.data.at(0)];
              useComment.setCommentAddUpdate(false);
              useComment.reset();
              isUpdated = true;
            }
          });
        });
      } else if (useComment.communityType === 'PRIVATE') {
        const requestData = {
          pc_info_id: useComment.communityInfo.pc_info_id, // 프라이빗 커뮤니티 고유번호
          pc_post_info_id: useComment.communityInfo.pc_post_info_id, // 프라이빗 커뮤니티 게시글 고유번호
          parent_id: useComment.commentInfo.commentInfo.pc_comment_info_id, // 댓글일 경우 0, 답글일 경우 답글 달아놓을 해당 댓글 고유번호 입력 (pc_comment_info_id)
          content: state.commentContent, // 댓글 내용
        };
        CommunityApiRequest.privateCommentAddRequest(requestData).then(res => {
          let isUpdated = false;
          useComment.commentList.forEach((comment, idx) => {
            if (!isUpdated && comment.pc_comment_info_id === useComment.commentInfo.commentInfo.pc_comment_info_id) {
              console.log('=>(commentAddVue.vue:69) comment', comment);
              comment.replies = [...comment.replies, res.data.at(0)];
              useComment.setCommentAddUpdate(false);
              useComment.reset();
              isUpdated = true;
            }
          });
        });
      }
    }
  };
  onMounted(() => {
    console.log('=>(commentAddVue.vue:115) useComment.commentEdit', useComment.commentEdit);
    console.log('=>(commentAddVue.vue:117) useComment.commentInfo', useComment.commentInfo);
    if (useComment.commentEdit) {
      state.commentContent = useComment.commentInfo.commentInfo.content;
      state.isCommentAdd = true;
    }
  });
  onUnmounted(() => {
    useComment.setUpdateCommentEdit('');
    useComment.setUpdateCommentEditType('');
  });
</script>

<template>
  <commentAddStyled>
    <TopHeaderVue :title="state.title" fuc="true" @close="handleClose" />
    <div class="comment_add_wrap">
      <div class="top_inner q-mt">
        <textarea v-model="state.commentContent" class="comment_inputs" maxlength="1500" placeholder="내용을 입력해주세요. (1500자 이내)" @input="handleCommnetAdd"></textarea>
      </div>
    </div>
    <div class="submit_wrap">
      <button :class="`${state.isCommentAdd ? 'add' : ''} submit_btn`" type="button" @click="handleSubMit">완료</button>
    </div>
  </commentAddStyled>
</template>
