<script setup></script>

<template>
  <div class="user_info_content">
    <div class="left_box">
      <div class="user_profile_con">
        <img alt="" src="@/assets/img/user_default_icon.svg" />
      </div>
      <div class="user_info">
        <span class="user_info_text"><q-skeleton animation="none" type="text" width="100px" /></span>
        <q-skeleton animation="none" type="text" width="100px" />
      </div>
    </div>
    <div class="right_box">
      <span class="point_tag"><q-skeleton animation="none" type="text" width="100px" /></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .user_info_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .user_profile_con {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 6px;
        img {
          width: 100%;
        }
      }
      .user_info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .user_info_text {
          color: #fff;
          align-items: center;
          font-size: 16px;
          font-family: 'Spoqa Han Sans Neo';
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.48px;
        }
        .certified_member_gubun {
          padding: 0 4px;
          height: 16px;
          display: inline-block;
          border-radius: 4px;
          background: #f7f3ff;
          color: var(--purple, #6728ff);
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.27px;
          line-height: 16px;
          margin-left: 4px;
        }
      }
    }
    .right_box {
      .point_tag {
        color: #fff;
        text-align: right;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.48px;
      }
    }
  }
</style>
