import { createRouter, createWebHistory } from 'vue-router';
import { setupLayouts } from 'layouts-generated';
import generatedRoutes from 'virtual:generated-pages';
import VoteComponent from '@/pages/vote/index.vue';
import AddCommunity from '@/pages/add/AddCommunity.vue';
import { locationCommActivity } from '@/utill/common';
import axios from '@/axios';
import { useUserInfoStore } from '@/stores/useUserInfoStore';
import { voteApiRequest } from '@/api/active/voteApiRequest';
import { usePopupStore } from '@/stores/usePopupStore';
import { useVoteStore } from '@/stores/useVoteStore';
import { useLoadingStore } from '@/stores/useLoadingStore';
import liveShotApi from '@/api/active/liveShotApi';

const routes = setupLayouts(generatedRoutes);
routes.push(
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/index.vue'),
  },
  {
    path: '/vote',
    name: 'vote',
    component: VoteComponent,
    children: [
      {
        path: 'step_one',
        name: 'step_one',
        component: () => import('@/components/StepOne.vue'),
      },
      {
        path: 'step_two',
        name: 'step_two',
        component: () => import('@/components/StepTwo.vue'),
      },
      {
        path: 'addVote',
        name: 'addVote',
        component: () => import('@/components/AddVoteVue.vue'),
      },
    ],
  },
  {
    path: '/add/AddressVue/live',
    name: 'AddressVue',
    component: AddCommunity,
    children: [
      {
        path: 'area',
        name: 'area',
        component: () => import('@/components/LiveShotVue.vue'),
      },
      {
        path: 'addLiveShot',
        name: 'addLiveShot',
        component: () => import('@/components/addLiveShot.vue'),
      },
      {
        path: 'detailAddress',
        name: 'detailAddress',
        component: () => import('@/components/detailAddress.vue'),
      },
      {
        path: 'DOMESTIC',
        name: 'DOMESTIC',
        component: () => import('@/components/[...area].vue'),
      },
      {
        path: 'OVERSEAS',
        name: 'OVERSEAS',
        component: () => import('@/components/[...area].vue'),
      },
    ],
  },
);
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  meta: {
    loading: true, // Add the loading meta property
  },
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

// 글로벌 네비게이션 가드 추가
router.afterEach((to, from) => {
  console.log(to, 'router.afterEach');
  locationCommActivity();
  if (!to.path.includes('auth')) {
    locationCommActivity();
  }
});
// 에러 핸들러 함수
const errorHandler = error => {
  console.error('Routing error:', error);
  if (error.message.includes('Failed to fetch dynamically imported module') && error.message.includes('Failed to load module script')) {
    window.location.reload();
  }
};

// Vue Router에 에러 핸들러 등록
router.onError(errorHandler);
export default router;
