<script setup>
  import { Sheet } from 'bottom-sheet-vue3';
  import { reactive, watch } from 'vue';
  import { useBottomSheetStore } from '@/stores/useBottomSheetStore';

  const isBottom = useBottomSheetStore();
  const props = defineProps({
    isVisible: Boolean,
    maxHeight: Number,
    minHeight: Number,
  });
  const emit = defineEmits(['changeVisible']);
  const state = reactive({
    visible: false,
  });
  const handleOpen = () => {
    state.visible = true;
  };
  const handleClose = () => {
    state.visible = false;
  };
  watch(
    () => state.visible,
    newValue => {
      if (!newValue) {
        emit('changeVisible', false);
      }
    },
  );
  defineExpose({
    handleOpen,
    handleClose,
  });

  watch(
    () => state.visible,
    isVisble => {
      if (isVisble) {
        $('.picture_list_con iframe').css({ pointerEvents: 'none' });
      } else {
        $('.picture_list_con iframe').css({ pointerEvents: 'initial' });
      }
    },
  );
</script>
<template>
  <Sheet v-model:visible="state.visible" class="custom-bottom-sheet">
    <div class="bottom_sheet_wrap">
      <div class="bottom_sheet_inner">
        <slot></slot>
      </div>
    </div>
  </Sheet>
</template>

<style lang="scss" scoped>
  .bottom_sheet_wrap {
    .bottom_sheet_inner {
    }
  }
</style>
