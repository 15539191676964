import styled from 'vue3-styled-components';

const showPopProps = { showPop: Boolean };
const declarationListStyled = styled('div', showPopProps)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 500px;
  width: 100%;
  background: #fff;
  z-index: 9999;
  height: 100%;
  display: ${props => (props.showPopProps.showPop ? 'block' : 'none')};
  .pop_head {
    position: fixed;
    height: 60px;
    width: 100%;
    max-width: 500px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    .close_btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .sub_title {
    padding-left: 16px;
    color: #000;
    font-family: Spoqa Han Sans Neo, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.18px;
  }
  .declaration_list {
    margin-top: 24px;
    .declaration_item {
      height: 56px;
      border-radius: 10px;
      border: solid 1px #f3f4f7;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .declaration_item_btn {
        padding: 0 16px;
        display: block;
        width: 100%;
        height: 100%;
        color: #000;
        font-family: Spoqa Han Sans Neo, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.48px;
        text-align: left;
        &.select {
          color: #6728ff;
          background: #f7f3ff;
        }
      }
    }
  }
  .submit_btn_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px;
    width: 100%;
    .submit_btn {
      height: 50px;
      display: block;
      width: 100%;
      border-radius: 10px;
      background: #6728ff;
      color: #fff;
      text-align: center;
      font-family: Spoqa Han Sans Neo, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
    }
  }
`;

export default declarationListStyled;
