<script setup>
  import { onMounted, reactive } from 'vue';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import LoadingUserDataInfo from '@Components/loadingComponent/LoadingUserDataInfo.vue';
  import { useScoinStore } from '@/stores/useScoinStore';

  const state = reactive({
    pushId: '',
  });
  const userInfo = useUserInfoStore();
  const useScoin = useScoinStore();
  const isLoadData = useLoadingStore();
  console.log('-> userInfo123', userInfo.memberInfo);

  /**
   * isLoadData.setUpdateIsLoadData true 이면 로딩 화면 이 사라짐
   */
  setTimeout(() => {
    isLoadData.setUpdateIsLoadData(true);
  }, 3000);
  onMounted(() => {
    useScoin.setAvailableCoins();
  });
</script>

<template>
  <div v-if="isLoadData.isLoadData" class="user_info_content">
    <div class="left_box">
      <div class="user_profile_con">
        <img v-if="!userInfo.memberInfo.img_path" alt="" src="@/assets/img/user_default_icon.svg" />
        <img v-else :alt="userInfo.memberInfo.user_info_id" :src="userInfo.memberInfo.img_path" />
      </div>
      <div class="user_info">
        <span class="user_info_text">{{ userInfo.memberInfo ? userInfo.memberInfo.nickname : '이름없음' }}</span>
        <span v-if="userInfo.memberInfo.certified === '인증회원'" class="certified_member_gubun">인증회원</span>
      </div>
    </div>
    <div class="right_box">
      <span class="point_tag hidden">{{ Number(userInfo.memberInfo.point).toLocaleString() }} Scoin</span>
    </div>
  </div>
  <LoadingUserDataInfo v-else />
</template>

<style lang="scss" scoped>
  .user_info_content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 4px;
    .left_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .user_profile_con {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 6px;
        border: solid 1px #7740ff;
        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .user_info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .user_info_text {
          color: #fff;
          align-items: center;
          font-size: 16px;
          font-family: 'Spoqa Han Sans Neo';
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.48px;
        }
        .certified_member_gubun {
          padding: 0 4px;
          height: 16px;
          display: inline-block;
          border-radius: 4px;
          background: #f7f3ff;
          color: var(--purple, #6728ff);
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.27px;
          line-height: 16px;
          margin-left: 4px;
        }
      }
    }
    .right_box {
      .point_tag {
        color: #fff;
        text-align: right;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.48px;
      }
    }
  }
</style>
