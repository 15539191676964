import { acceptHMRUpdate, defineStore } from 'pinia';

export const usePopupStore = defineStore('usePopup', {
  state: () => {
    return {
      popups: {
        isCommunitySelectPopup: false,
        isSharePopup: false,
        isSuspendPopup: false,
        isManagePopup: false,
        isFilterPopup: false,
        isMeetingSelectPopup: false,
        isDatePicker: false,
        isTimePicker: false,
        isCommonPopup: false,
        isChargePopup: false,
        isVotePopup: false,
        isMeetingPopup: false,
        isLiveShotPopup: false,
        isConfirmPopup: false,
        isPostPopup: false,
        isNegativePopup: false,
        isInquiryPopup: false,
        isInvitationSms: false,
        isWordPopup: false,
        isPermissionPopup: true,
        isInvite: false,
        isTerms: false,
        isTermsOfUse: false,
        isLocationTerms: false,
      },

      snsData: {},
    };
  },
  getters: {
    isAnyPopupOpen(state) {
      return Object.values(state.popups).some(popupState => popupState);
    },
  },
  actions: {
    setSnsDate(data) {
      this.snsData = data;
    },
    resetSnsDate() {
      this.snsData = {};
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: window.localStorage,
        paths: ['popups.isPermissionPopup'],
      },
    ],
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(usePopupStore, import.meta.hot));
