<!--
광고 에대 한 데이터는 전해 받지 못함 현재는 하드코딩으로 디자인대로만 나오게끔 만 하였음
-->
<script setup>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Autoplay } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/autoplay';
  import 'swiper/scss/navigation';
  import 'swiper/scss/pagination';
  import { onMounted, reactive } from 'vue';
  import testImage from '@/assets/img/test.png';
  import operatorApi from '@/api/operator/operatorApi';
  import { useLoadingStore } from '@/stores/useLoadingStore';

  const loading = useLoadingStore();

  const props = defineProps({
    banner_kind: String,
  });

  const state = reactive({
    swiperOption: {
      slidesPerView: '1',
      spaceBetween: '5',
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      loop: true,
    },
    modules: [Autoplay],
    swiperObj: null,
    slideItem: [],
    slides: null,
    totalCount: 0,
    activeCount: 1,
    dataLoaded: false, // 데이터 로드 상태 추가
  });
  const handleOnSwiper = async swiper => {
    await loadData();
    console.log('-> swiper', swiper);
    state.swiperObj = swiper;
    state.totalCount = state.slideItem.length;
  };

  // 배너 리스트
  const loadData = async () => {
    try {
      const res = await operatorApi.getBannerList({ banner_kind: props.banner_kind }); // 데이터
      if (res.code === 200) {
        state.slideItem = res.data;
        state.dataLoaded = true; // 데이터 로드 완료
        console.log(state.dataLoaded, 'state.dataLoaded');
        state.totalCount = state.slideItem.length;
        console.log(state.totalCount, 'state.totalCount');
      }
    } catch (error) {
      console.error('Data loading failed:', error);
    } finally {
      loading.setUpdateIsLoadData(true);
    }
  };

  onMounted(() => {
    loadData();
  });
</script>

<template>
  <div v-if="state.dataLoaded && state.totalCount > 0" :class="[{ vote: props.banner_kind === 'vote' }, 'advertisement_wrap']">
    <div class="advertisement_inner">
      <Swiper :modules="state.modules" class="advertisement_list" v-bind="state.swiperOption" @swiper="handleOnSwiper">
        <swiper-slide v-for="(item, idx) in state.slideItem" :key="idx" v-slot="{ isActive }" class="advertisement_list_item">
          <p style="display: none">
            {{ isActive ? (state.activeCount = idx + 1) : '' }}
          </p>
          <a :href="item.img_url" :target="item.img_url_target"><img :src="item.img_path_url" alt="" /></a>
        </swiper-slide>
        <div class="count_info_tag">
          <span class="active_tag">{{ state.activeCount }} /</span><span class="total_count">{{ state.totalCount }}</span>
        </div>
      </Swiper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .advertisement_wrap {
    position: relative;
    z-index: 10;
    border-radius: 0 20px 0 0;
    background: #fff;
    padding: 16px;
    &.vote {
      height: 164px;
      .advertisement_inner {
        height: 100%;
        .advertisement_list {
          height: 100%;
        }
      }
    }
    .advertisement_inner {
      .advertisement_list {
        position: relative;
        width: 100%;
        height: 100px;
        .count_info_tag {
          position: absolute;
          bottom: 13px;
          right: 13px;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.3px;
          z-index: 10;
          .active_tag {
            color: #fff;
            text-align: right;
          }
          .total_count {
            color: #7d8bb7;
          }
        }
        .advertisement_list_item {
          width: 100%;
          height: 100%;
          border-radius: 18px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
</style>
