<script setup>
  import { reactive } from 'vue';

  const state = reactive({
    isPop: false,
  });
  const handlePopClose = () => {
    state.isPop = false;
  };
  const handlePopOpen = () => {
    // console.log('들어옴');
    state.isPop = true;
  };
  defineExpose({
    handlePopClose,
    handlePopOpen,
  });
</script>

<template>
  <div v-if="state.isPop" class="pop_wrap">
    <div class="pop_inner">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .pop_wrap {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    max-width: 500px;
    .pop_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 16px;
      background: #fff;
      width: calc(100% - 56px);
      overflow: hidden;
    }
  }
</style>
