<script setup>
  import { onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
  import CommunityTagVue from '@Components/CommunityTagVue.vue';
  import CommunityUserInfoVue from '@Components/CommunityUserInfoVue.vue';
  import FunctionComponetVue from '@Components/FunctionComponetVue.vue';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { useRoute, useRouter } from 'vue-router';
  import AiInsertVue from '@Components/AiInsertVue.vue';
  import CommunityPictureVue from '@Components/CommunityPictureVue.vue';
  import PointInfoVue from '@Components/PointInfoVue.vue';
  import { useCommunitySearchFilterStore } from '@/stores/useCommunitySearchFilterStore';
  import SharePopup from '@Components/popup/SharePopup.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import MeetingSelection from '@Components/MeetingSelection.vue';
  import RequestLiveShotPopup from '@Components/popup/RequestLiveShotPopup.vue';
  import liveShotApi from '@/api/active/liveShotApi';

  const LayOutLoading = ref();
  const router = useRouter();
  const route = useRoute();
  const useCommunitySearchFilter = useCommunitySearchFilterStore();
  const usePopup = usePopupStore();
  const emit = defineEmits(['handleListData']);
  const state = reactive({
    communityListALL: [],
    communityInfo: [],
    param: {
      offset: 1,
      kind: '',
      order_by: 'all',
    },
    detailData: {},
  });
  const props = defineProps(['communityList']);
  const loading = useLoadingStore();

  /**
   * handleSubSearchRequest
   * 자세한 검색필터 에서 나온값을 param 으로 넘기는 함수
   * @param data
   */
  const handleSubSearchRequest = data => {
    useCommunitySearchFilter.mainFilter.order_by = data;
    console.log(useCommunitySearchFilter.subFilterKind, 'subFilterKind');
    useCommunitySearchFilter.setMainFilter(useCommunitySearchFilter.mainFilter);
  };
  /**
   * handleSearchRequest
   * 게시물 타입에 따라 변경 되는 값
   * @param data
   */
  const handleSearchRequest = data => {
    useCommunitySearchFilter.mainFilter.kind = data;
    useCommunitySearchFilter.setMainFilter(useCommunitySearchFilter.mainFilter);
  };

  /**
   * mainLoadCommunityListRequest 메인에 나오는 전체 게시물 리스트 api
   * state.param 은 부모 컴포넌트에서 가져오는 검색필터 값을 param 으로 넣어주고 있음
   */
  const mainLoadCommunityListRequest = $state => {
    let param = { ...useCommunitySearchFilter.mainFilter };
    let apiRequest = CommunityApiRequest.mainCommunityListRequest;
    let type;

    // 커뮤니티 내 리스트
    if (route.params.all) {
      const [idStr, typeParam] = route.params.all.split('&');
      const id = Number(idStr);
      type = typeParam;

      param = {
        ...param,
        ...(type === 'open' ? { oc_info_id: id } : { comm_info_id: id, nick_name: '' }),
      };

      apiRequest = type === 'open' ? CommunityApiRequest.openCommunityReferenceRequest : CommunityApiRequest.privateCommunityReferenceRequest;
    }
    // 메인 리스트
    apiRequest(param)
      .then(res => {
        if (res.code === -200) {
          router.go(-1);
          return tostAlert(res.message);
        }
        if (res.data.community_info) {
          state.communityInfo = res.data.community_info.at(0);
          state.communityInfo.type = type;
        }

        state.communityListALL = [...state.communityListALL, ...res.data.community_post_list];
        if (res.data.community_post_list.length < 5) {
          $state.complete();
        }
      })
      .finally(() => {
        useCommunitySearchFilter.mainFilter.offset++;
        emit('handleListData', state.communityListALL, state.communityInfo);
      });
  };
  const handleDeleteCommunity = newList => {
    console.log('deleteCommunity', newList);
    state.communityListALL = newList;
  };

  /**
   * handleRouterPushDetailContent 게시물 상세 페이지 가는 함수
   * private 와 open  으로 나눠어 있고
   * 각 게시물 고유번호 와 커뮤니티 고유번호를 가지고 감
   * @param getData
   */
  const handleRouterPushDetailContent = getData => {
    console.log('=>(CommunityListVue.vue:57) getData.type', getData);
    if (getData.post_kind === 'liveshot') {
      if (getData.meeting_close > 0) {
        return tostAlert('종료된 라이브샷 입니다.');
      } else {
        const param = {
          post_info_id: getData.post_info_id,
        };
        liveShotApi.getLiveShotDetails(param).then(res => {
          if (res.data.code === -200) {
          }
          state.detailData = res.data.at(0);
          if (res.data.length > 0) {
            return (usePopup.popups.isLiveShotPopup = true);
          } else {
            return tostAlert('이미 수락하신 미팅입니다.');
          }
        });
      }
    } else if (getData.type === 'priv') {
      loading.setUpdateIsLoadData(false);
      router.push(`/detail/privateContent/${getData.comm_info_id}/${getData.post_info_id}`);
    } else if (getData.type === 'open') {
      loading.setUpdateIsLoadData(false);
      router.push(`/detail/openContent/${getData.comm_info_id}/${getData.post_info_id}`);
    }
    console.log('게시글 상세 이동');
  };

  //차딘
  const handleInterceptionSubmit = block => {
    state.communityListALL = state.communityListALL.filter(items => items.user_info_id !== block);
  };

  const setTypeState = type => {
    switch (type.post_kind) {
      case 'meeting':
        return type.meeting_close === 0 ? '[미팅]' : '[미팅종료]';

      case 'liveshot':
        return type.meeting_close === 0 ? '[라이브샷]' : '[라이브샷 종료]';

      default:
        return ''; // 기본값 반환
    }
  };
  // 메인으로 이동시 필터 초기화
  watch(
    () => useCommunitySearchFilter.mainFilter,
    newValue => {
      console.log(newValue, ' 메인리스트 새로운 필터');
      if (newValue) mainLoadCommunityListRequest();
    },
  );

  // 메인으로 이동시 필터 초기화
  watch(
    () => useCommunitySearchFilter.mainFilterKind,
    (newValue, oldValue) => {
      if (newValue !== oldValue && useCommunitySearchFilter.mainFilterKind) {
        useCommunitySearchFilter.mainFilter.offset = 1;
        state.communityListALL = [];
        mainLoadCommunityListRequest();
      }
    },
  );

  watch(
    () => useCommunitySearchFilter.subFilterKind,
    (newSelected, oldSelected) => {
      if (newSelected !== oldSelected) {
        useCommunitySearchFilter.mainFilter.offset = 1;
        state.communityListALL = [];
        mainLoadCommunityListRequest();
      }
    },
  );

  watch(
    () => props.communityList,
    data => {
      state.communityListALL = data;
    },
  );
  //DOM 로드후
  onMounted(() => {
    console.log(useCommunitySearchFilter.mainFilter, 'onMounted');
    useCommunitySearchFilter.mainFilter.offset = 1;
    mainLoadCommunityListRequest();
    usePopup.popups.isLiveShotPopup = false;
  });

  //DOM 로드전
  onBeforeUnmount(() => {
    console.log(useCommunitySearchFilter.mainFilter, 'onBeforeUnmount');
    useCommunitySearchFilter.resetMainFilter();
  });

  defineExpose({
    handleSearchRequest,
    handleSubSearchRequest,
  });
</script>

<template>
  <div v-if="state.communityListALL.length > 0" id="main_list_view" class="list_tag">
    <div v-for="(community, idx) in state.communityListALL" :key="idx" class="list_item">
      <CommunityTagVue :data="community" @click="handleRouterPushDetailContent(community)" />
      <!--회원 정보 컴포넌트-->
      <CommunityUserInfoVue :data="community" :list="state.communityListALL" @deleteCommunity="handleDeleteCommunity" @handleInterceptionSubmit="handleInterceptionSubmit" />
      <div class="community_content_con_title" @click="handleRouterPushDetailContent(community)">
        <span :class="{ 'txt-g30': community.meeting_close !== 0 }">{{ setTypeState(community) }} </span>

        {{ community.title }}
      </div>
      <div class="community_content_con ellipsis2" @click="handleRouterPushDetailContent(community)">
        {{ community.content }}
      </div>
      <!--      희망인원 및 포인트 컴포넌트;-->
      <!--  list 에서 넘겨준 값 meeting post_kind meeting 일경우 미팅글       -->
      <PointInfoVue v-if="community.post_kind === 'meeting' || community.post_kind === 'liveshot'" :community="community" />
      <div v-if="community.keywords.length > 0" :class="[{ live: community.post_kind === 'liveshot' }, 'keyword_list']">
        <span v-for="(keyword, idx) in community.keywords" :key="idx" class="keyword">#{{ keyword }}</span>
      </div>
      <!--사진 정보 동영상 컴포넌트-->
      <CommunityPictureVue v-if="community.post_kind !== 'liveshot'" :data="community" />
      <!--ai -->
      <AiInsertVue :community="community" />
      <FunctionComponetVue v-if="community.post_kind !== 'liveshot'" :data="community" @locationDetail="handleRouterPushDetailContent(community)" />
    </div>
    <infinite-loading @infinite="mainLoadCommunityListRequest">
      <template #complete>
        <span></span>
      </template>
      <template #loading>
        <span></span>
      </template>
    </infinite-loading>
  </div>
  <div v-else class="emty_tag">
    <img alt="" src="@/assets/img/community/no_list.png" />
  </div>
  <RequestLiveShotPopup v-if="usePopup.popups.isLiveShotPopup" :detail="state.detailData" @close="usePopup.popups.isLiveShotPopup = false" />
</template>

<style lang="scss" scoped>
  .emty_tag {
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;
    border-top: solid 1px #f3f4f7;
  }

  .list_tag {
    padding: 0 16px;

    .list_item {
      padding-top: 16px;
      border-bottom: solid 1px #f3f4f7;
      padding-bottom: 16px;

      .community_content_con_title {
        margin: 16px 0 0 0;
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.48px;
        display: -webkit-box; /* 브라우저 호환성을 위해 필요한 설정 */
        -webkit-line-clamp: 1; /* 표시할 줄 수 */
        -webkit-box-orient: vertical; /* 브라우저 호환성을 위해 필요한 설정 */
        overflow: hidden; /* 넘치는 부분을 숨김 */
        text-overflow: ellipsis; /* 넘치는 부분을 "..."으로 표시 */
      }

      .community_content_con {
        margin-top: 8px;
        margin-bottom: 16px;
        color: #2a2e37;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.42px;
        white-space: pre-wrap;
        word-break: break-all;
      }

      .keyword_list {
        margin-bottom: 16px;
        display: -webkit-box; /* 브라우저 호환성을 위해 필요한 설정 */
        -webkit-line-clamp: 1; /* 표시할 줄 수 */
        -webkit-box-orient: vertical; /* 브라우저 호환성을 위해 필요한 설정 */
        overflow: hidden; /* 넘치는 부분을 숨김 */
        text-overflow: ellipsis; /* 넘치는 부분을 "..."으로 표시 */
        &.live {
          margin-bottom: 0px;
        }
        .keyword {
          display: inline-block;
          color: #848fb1;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.36px;

          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }
    }
  }
</style>
