<script setup>
  import { reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';

  const selectTab = useAddCommunityStore();
  const state = reactive({
    isTab: null,
  });
  const router = useRouter();
  const handleTabClick = gubun => {
    if (gubun === 'YES') {
      state.isTab = gubun;
    } else if (gubun === 'NO') {
      state.isTab = gubun;
    }
  };
  const handleNext = () => {
    if (!state.isTab) {
      tostAlert('미팅 대상 여부를 선택 해주세요');
      return false;
    }
    if (state.isTab === 'YES') {
      // router.push('/meeting/info');
      selectTab.setUpdateGubun('ADD_COMMUNITY');
      router.push({
        path: '/community/yes/select',
        query: { type: 'YES' },
      });
    } else if (state.isTab === 'NO') {
      selectTab.setUpdateGubun('ADD_COMMUNITY');
      router.push({
        path: '/community/no/select',
        query: { type: 'NO' },
      });
    }
  };
</script>

<template>
  <div class="meeting_add_wrap">
    <div class="meeting_add_inner">
      <h3 class="title">미팅 대상이 지정되어 있나요?</h3>
      <div class="meeting_gubun_list">
        <button :class="{ select: state.isTab === 'YES' }" class="meeting_gubun_btn" type="button" @click="handleTabClick('YES')">
          <span class="value_text">YES</span>
          <span class="notice_text">미팅 대상이 지정되어 있어요</span>
        </button>
        <button :class="{ select: state.isTab === 'NO' }" class="meeting_gubun_btn" type="button" @click="handleTabClick('NO')">
          <span class="value_text">NO</span>
          <span class="notice_text">미팅 대상을 지정해야 해요</span>
        </button>
      </div>
      <div class="submit_con">
        <button class="submit_btn" type="button" @click="handleNext">완료</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .meeting_add_wrap {
    .meeting_add_inner {
      padding: 0 16px;
      .title {
        color: #000;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.18px;
        margin-bottom: 24px;
      }
      .meeting_gubun_list {
        .meeting_gubun_btn {
          height: 82px;
          width: 100%;
          border: solid 1px #f3f4f7;
          border-radius: 10px;
          padding: 16px;
          &.select {
            border: none;
            background: #f7f3ff;
            .value_text {
              color: #6728ff;
            }
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          .value_text {
            display: inline-block;
            width: 100%;
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.48px;
            text-align: left;
          }

          .notice_text {
            display: inline-block;
            width: 100%;
            height: 82px;
            color: #515561;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
            text-align: left;
          }
        }
      }
      .submit {
        // .submit_con
        &_con {
          position: fixed;
          bottom: 16px;
          left: 50%;
          transform: translateX(-50%);
          max-width: 500px;
          width: 100%;
          padding: 0 16px;
        }

        // .submit_btn
        &_btn {
          width: 100%;
          height: 50px;
          background: #6728ff;
          border-radius: 10px;
          color: #fff;
          text-align: center;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.42px;
        }
      }
    }
  }
</style>
