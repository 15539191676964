import styled from 'vue3-styled-components';

const showProps = { show: Boolean };

const confrimStyled = styled('div', showProps)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  display: ${props => (props.showProps.show ? 'block' : 'none')};
  .confrim_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 16px;
    padding: 32px 16px 92px 16px;
    width: calc(100% - 56px);
    min-width: 304px;
    min-height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      color: #000;
      text-align: center;
      font-family: Spoqa Han Sans Neo, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.48px;
    }
    .control_box {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
      .control_btn {
        width: calc(100% / 2);
        height: 46px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        font-family: Spoqa Han Sans Neo, serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.36px;
        &.cancel {
          margin-right: 8px;
          background: #f3f4f7;
          color: #727683;
          text-align: center;
          font-family: Spoqa Han Sans Neo, serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.36px;
        }
        &.accept {
          background: #6728ff;
        }
      }
    }
  }
`;
export default confrimStyled;
