import axios from '@/axios/index';

export default {
  updateUserInfo() {
    return axios.get('/user/scoin');
  },
  checkAvailablePoints() {
    return axios.get('/user/scoin');
  },
  checkToken() {
    return axios.get('/auth/check');
  },
  testLoginApi(data) {
    return axios.post('/login');
  },
  kakaoLoginRequest(data) {
    return axios.post('https://kapi.kakao.com/v2/user/me', data);
  },
  userInfoRequest(data) {
    return axios.post('/user/info', data);
  },
  googleLoginRequest(data) {
    return axios.post('/auth/google/login', data);
  },
  nicknameDuplicateCheck(data) {
    return axios.post('/user/chk/nickname', data);
  },
  googleRegister(data) {
    return axios.post('/auth/google/register', data);
  },
  kakaoRegister(data) {
    return axios.post('/auth/kakao/login', data);
  },
  kakaoRegisterApi(data) {
    return axios.post('/auth/kakao/register', data);
  },
  appleLoginRequest(data) {
    return axios.post('/auth/apple/login', data);
  },
  naverCertificationCode(data) {
    return axios.post('/auth/naver', data);
  },
  appleRegisterRequest(data) {
    return axios.post('/auth/apple/register', data);
  },
  naverLoginRequest(data) {
    return axios.post('/auth/naver/login', data);
  },
  naverRegister(data) {
    return axios.post('/auth/naver/register', data);
  },
};
