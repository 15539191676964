import auth from '@/api/auth';

export class AuthApiRequest {
  static async checkAvailablePoints() {
    try {
      const data = await auth.checkAvailablePoints();
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:<your_line_number>) err', err);
      throw err;
    }
  }
  static async updateUserInfoRequest() {
    try {
      const data = await auth.updateUserInfo();
      return data;
    } catch (err) {
      throw err;
    }
  }
  static async checkToken() {
    try {
      const data = await auth.checkToken();
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:9) err', err);
      throw errs;
    }
  }
  static async testLoginAPI() {
    try {
      const data = await auth.testLoginApi();
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:9) err', err);
      throw err;
    }
  }
  static async kakaoLoginApiRequest(param) {
    try {
      const data = await auth.kakaoRegister(param);
      return data;
    } catch (error) {
      console.log('-> error', error);
      throw error;
    }
  }
  static async userInfoApiRequest(param) {
    try {
      const data = await auth.userInfoRequest(param);
      return data;
    } catch (error) {
      console.log('=>(authApiRequest.js:17) error', error);
      throw error;
    }
  }
  static async googleLoginRequest(param) {
    try {
      const data = await auth.googleLoginRequest(param);
      console.log('=>(authApiRequest.js:25) data', data);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:27) err', err);
      throw err;
    }
  }
  static async nickNameDuplicateCheckRequest(param) {
    try {
      const data = await auth.nicknameDuplicateCheck(param);
      console.log('=>(authApiRequest.js:35) data', data);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:36) err', err);
      throw err;
    }
  }

  static async googleRegisterRequest(param) {
    try {
      const data = await auth.googleRegister(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:47) err', err);
      throw err;
    }
  }

  static async kakaoRegisterRequest(param) {
    try {
      const data = await auth.kakaoRegister(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:57) err', err);
      throw err;
    }
  }
  static async kakaoRegisterRequestApi(param) {
    try {
      const data = await auth.kakaoRegisterApi(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:66) err', err);
      throw err;
    }
  }
  static async appleLoginRequest(param) {
    try {
      const data = await auth.appleLoginRequest(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:75) err', err);
      throw err;
    }
  }
  static async naverCodeCertificationCode(param) {
    try {
      const data = await auth.naverCertificationCode(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:84) err', err);
      throw err;
    }
  }
  static async appleRegisterRequest(param) {
    try {
      const data = await auth.appleRegisterRequest(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:93) err', err);
      throw err;
    }
  }
  static async naverLoginRequestApi(param) {
    try {
      const data = await auth.naverLoginRequest(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:102) err', err);
      throw err;
    }
  }
  static async naverRegisterApi(param) {
    try {
      const data = await auth.naverRegister(param);
      return data;
    } catch (err) {
      console.log('=>(authApiRequest.js:111) err', err);
      throw err;
    }
  }
}
