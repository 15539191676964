import styled from 'vue3-styled-components';

const showProps = { show: Boolean, length: Number };

const ImagePopVueStyled = styled('div', showProps)`
  overflow: hidden;
  display: ${props => (props.showProps.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99999999999;
  padding: 60px 0;
  .close_pop_btn {
    position: absolute;
    right: 16px;
    top: 18px;
  }
  .image_con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    max-width: 500px;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    .container {
      display: flex;
    }
    .image_array {
      height: calc(100% - 120px);
      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
`;
export default ImagePopVueStyled;
