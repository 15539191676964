<script>
  import confrimStyled from '@/styled/confrimStyled';

  export default {
    components: {
      confrimStyled,
    },
  };
</script>
<script setup>
  import { reactive, watch } from 'vue';

  const state = reactive({
    isOpen: false,
  });
  const props = defineProps(['content']);
  const emit = defineEmits(['result']);

  const handleResult = result => {
    emit('result', result);
  };
  const handleOpenPop = () => {
    state.isOpen = !state.isOpen;
  };
  defineExpose({
    handleOpenPop,
  });
  watch(
    () => state.isOpen,
    isOpen => {
      if (isOpen) {
        $('.picture_list_con iframe').css({ pointerEvents: 'none' });
      } else {
        $('.picture_list_con iframe').css({ pointerEvents: 'initial' });
      }
    },
  );
</script>

<template>
  <confrimStyled :showProps="{ show: state.isOpen }">
    <div class="confrim_wrap">
      <p class="title">
        {{ props.content }}
      </p>
      <div class="control_box">
        <button @click="handleResult(false)" type="button" class="control_btn cancel">취소</button>
        <button @click="handleResult(true)" type="button" class="control_btn accept">확인</button>
      </div>
    </div>
  </confrimStyled>
</template>
