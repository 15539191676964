<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAlarmList } from '@/api/alarm/alarmApi';
  import { useAlarmStore } from '@/stores/useAlarmStore';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';

  const useAlarm = useAlarmStore();
  const memberInfo = useUserInfoStore();
  const router = useRouter();
  const state = reactive({
    isAlarm: false,
  });
  const handleSearch = () => {
    router.push('/search/Searchview');
  };
  watch(
    () => memberInfo.latlng,
    newValue => {
      console.log(memberInfo.latlng);
      if (newValue) {
        useAlarm.setAlarmCount();
        console.log('newValue');
      }
    },
  );
</script>

<template>
  <div class="justify-between row">
    <h2 class="title" @click="router.push('/')">
      <img alt="" src="@/assets/img/header/logo.svg" />
    </h2>
    <div class="option_con d-flex">
      <button class="option_btn search_icon" type="button" @click="handleSearch">
        <img alt="" src="@/assets/img/header/ic_24_search.svg" />
      </button>
      <button class="alarm_btn" @click="router.push('/alarm')">
        <span v-if="useAlarm.alarmCount > 0">{{ useAlarm.alarmCount > 99 ? '99+' : useAlarm.alarmCount }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .justify-between {
    padding-right: 16px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #fff;
      font-family: Lemon;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.36px;
    }

    .option_con {
      .alarm_btn {
        width: 24px;
        height: 24px;
        display: block;
        position: relative;
        background: url(@/assets/img/header/ic_24_notifications.svg) no-repeat center;

        span {
          position: absolute;
          top: -3px;
          left: 10px;
          font: $text-tiny9-700;
          line-height: 14px;
          letter-spacing: -0.27px;
          color: $white;
          background-color: $point;
          padding: 0 4px;
          border-radius: 10px;
        }
      }

      .option_btn {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
        }

        &.search_icon {
          margin-right: 16px;
        }
      }
    }
  }
</style>
