import styled from 'vue3-styled-components';

const bottomeSheetStyled = styled.div`
    .edit_wrap {
        padding: 0 24px ;
        margin-bottom: 24px;
        max-height: 414px;
        overflow: auto;

        &.scrolly {
            padding-right: 18px;
            margin-right:6px;
            &::-webkit-scrollbar {
                width: 4px;
                display: block;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #D3D5DD;
                border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
                margin-right: 4px;
                background-color: transparent;
            }
        }

        &.v2 {
            padding: 0 0 28px;
        }

        .edit_list {
            .edit_item {
                button {
                    color: #000;
                    font-family: Spoqa Han Sans Neo, serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: -0.42px;
                    padding: 12px 0;
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
`;
export default bottomeSheetStyled;
