<script setup>
  import { useRouter } from 'vue-router';
  import TermsPopup from '@Components/popup/TermsPopup.vue';
  import { usePopupStore } from '@/stores/usePopupStore';

  const router = useRouter();
  const usePopup = usePopupStore();

  const goToExternalSite = () => {
    window.location.href = 'https://www.seemple.site';
  };
</script>

<template>
  <div class="my_footer_wrap">
    <div class="my_footer_inner">
      <ul class="etc_list">
        <li class="etc_list_item" @click="router.push('/auth/privacyPolicy')"><b>개인정보취급방침</b></li>
        <li class="etc_list_item" @click="usePopup.popups.isTermsOfUse = true">이용약관</li>
        <li class="etc_list_item" @click="goToExternalSite">회사소개</li>
        <li class="etc_list_item" @click="router.push('/my/service/faq')">고객센터</li>
      </ul>
      <div class="info_con">
        <p class="info_text">주식회사 클로토</p>
        <p class="info_text">대표이사 : 이현석 | 개인정보 보호책임자 : 이현석</p>
        <p class="info_text">본사 : 경기도 수원시 영통구 광교로 107 창업 보육동 404호</p>
        <p class="info_text">통신판매업신고번호 : 제 2023-수원영통-1355호</p>
        <p class="info_text">고객센터 : seempleapp@gmail.com</p>
        <p class="info_text">연락처 : 0507-1378-9904</p>
      </div>
      <div class="copy_con">ⓒ Seemple Corp All rights reserved</div>
    </div>
    <TermsPopup v-if="usePopup.popups.isTermsOfUse" />
  </div>
</template>

<style lang="scss" scoped>
  .my_footer_wrap {
    position: relative;
    background: #f3f4f7;
    padding: 32px 20px 128px 20px;
    .my_footer_inner {
      .etc_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
        .etc_list_item {
          cursor: pointer;
          color: #9fa7c0;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          letter-spacing: -0.36px;
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
      .info_con {
        margin-bottom: 20px;
        .info_text {
          color: #9fa7c0;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.36px;
          &:not(:last-child) {
            margin-bottom: 2px;
          }
        }
      }
      .copy_con {
        color: #9fa7c0;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.36px;
      }
    }
  }
</style>
