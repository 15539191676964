import { acceptHMRUpdate, defineStore } from 'pinia';

export const useChatCommunitySelectStore = defineStore('useChatCommunitySelect', {
  state: () => {
    return {
      communityValue: null, //탭 네임
      isChannelUpdate: false, //채널 업뎃 유무
      isUpdateChannel: null,
      roomType: '',
    };
  },
  actions: {
    updateCommunityValue(data) {
      this.communityValue = data;
    },
    updateRoomType(data) {
      this.roomType = data;
    },
    updateChannelUpdate(data) {
      this.isChannelUpdate = data;
    },
    annel(data) {
      this.isUpdateChannel = data;
    },
    resetChannelUpdate() {
      this.isChannelUpdate = false;
    },
    reset() {
      this.communityValue = null;
    },
    resetRoomType() {
      this.roomType = '';
    },
  },
  getters: {},
  persist: false,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useChatCommunitySelectStore, import.meta.hot));
