import communityApi from "@/api/community/communityApi";
import paymentApi from "@/api/paymentApi";

export class paymentApiRequest {
    // static async cardPaymentRequest(param) {
    //     try {
    //         return await paymentApi.cardPaymentRequest(param)
    //     } catch (err) {
    //         throw err;
    //     }
    // }
    static async cardPaymentCheck(param) {
        try {
            return await paymentApi.cardPaymentCheck(param)
        } catch (err) {
            throw err;
        }
    }
}