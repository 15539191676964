<script setup>
  import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';
  import { useMeetingStore } from '@/stores/useMeetingStore';
  import dayjs from 'dayjs';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { useRoute, useRouter } from 'vue-router';
  import { AuthApiRequest } from '@/api/authApiRequest';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import { removeNonNumeric } from '@/utill/RegEx';
  import DateRangePopup from '@Components/popup/DateRangePopup.vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import { formatDate } from '@/utill/common';
  import ChargingPointPopup from '@Components/popup/ChargingPointPopup.vue';
  import { useScoinStore } from '@/stores/useScoinStore';
  import PopUpVue from '@Components/common/PopUpVue.vue';
  import CoinsAvailable from '@Components/common/CoinsAvailable.vue';
  import ForbiddenWordPopup from '@Components/popup/ForbiddenWordPopup.vue';

  dayjs.extend(customParseFormat);
  const imageFileInputs = ref();
  const memberInfo = useUserInfoStore();
  const selectTab = useAddCommunityStore();
  const meetingData = useMeetingStore();
  const useScoin = useScoinStore();
  const usePopup = usePopupStore();

  const router = useRouter();
  const route = useRoute();
  const confirmPopup = ref();

  const state = reactive({
    isSubmit: false,
    availablePoints: 0,
    forbiddenWord: '',
    myScoin: 0,
    isInfoText: false,
    hashTagValue: null,
    myPoint: 0,
    hashTag: '',
    isSelectCommunity: '',
    formData: {
      selectArea: null,
      title: null,
      content: '',
      youTubeRrl: null,
      hashTag: [],
      pointValue: null,
      personnelCount: '1',
      imageArray: [],
      isCheck_1: false,
      isCheck_2: false,
    },
    hashTagArra: [],
    datePick: [],
    isSelectData: '',
    isSuccess: false, // 게시글등록 성공 / 코인충전
    isRange: false,
    dateRange: null,
    isInvalid: false,
  });

  const handleCategory = type => {
    if (type === 'comm') {
      router.push('/community/no/select?type=NO');
    } else {
      selectTab.setSelectTab('MEETING_ADD');
      selectTab.resetArea();
      meetingData.isComplete = false;
      meetingData.reset();
    }
    const setData = {
      ...state,
    };
    meetingData.setMeetingFormData(setData);
  };
  const handleSetHashTag = () => {
    if (!state.hashTag.includes('#')) {
      tostAlert('# 필수 입력입니다.');
      state.hashTag = state.hashTag.replace(state.hashTag, '');
      return;
    }
    if (state.hashTagArra.length > 5) {
      tostAlert('최대 5개 까지 입력 가능 합니다');
      state.hashTagArra.pop();
      return;
    }
    const hashtags = state.hashTag.split('#').filter(tag => tag !== '');
    state.hashTagArra = hashtags;
    console.log('=>(CommuniyuAddVue.vue:34) state.hashTagArra', state.hashTagArra.length);
  };
  // 금액입력
  const executorPoint = () => {
    state.formData.pointValue = removeNonNumeric(state.formData.pointValue);
  };
  // 금액 10 단위
  const formatPointValue = () => {
    state.formData.pointValue = String(state.formData.pointValue).replace(/\D/g, '');
    let valueAsNumber = Number(state.formData.pointValue);
    valueAsNumber = Math.floor(valueAsNumber / 10) * 10;
    state.formData.pointValue = valueAsNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    console.log(state.formData.pointValue);
  };
  const handleGetImageFile = () => {
    imageFileInputs.value.click();
  };
  const handleGetFile = () => {
    const files = imageFileInputs.value.files;
    console.log('=>(MeetingAddCommunityVue.vue:44) files', files);

    const readImageFile = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => resolve(event.target.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const displayImageUrls = async () => {
      try {
        for (const file of files) {
          const imageUrl = await readImageFile(file);
          console.log('=>(MeetingAddCommunityVue.vue:59) state.formData.imageArray.length', state.formData.imageArray.length);
          state.formData.imageArray.push({
            files: file,
            imageUrl: imageUrl,
          });
          console.log('=>(MeetingAddCommunityVue.vue:64) state.imageArray', state.formData.imageArray);
        }
      } catch (error) {
        console.error('Error reading image file:', error);
      }
    };

    displayImageUrls();
  };

  const handleDelImage = idx => {
    state.formData.imageArray.splice(idx, 1);
  };
  const handleCheck = idx => {
    if (idx === 0) {
      state.isCheck_1 = !state.isCheck_1;
    } else if (idx === 1) {
      state.isCheck_2 = !state.isCheck_2;
    }
  };
  watch(
    () => state.formData.imageArray.length,
    newValue => {
      if (newValue > 10) {
        state.formData.imageArray.pop();
        tostAlert('이미지는 최대 10개 까지 업로드 가능 합니다');
      }
    },
  );

  function extractVideoID(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch(?:\/|\/?\?(?:\&?v\=))|(?:embed|v|vi|shorts)\/))([^\?&"'>]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const validateForm = () => {
    let coins = state.formData.pointValue.replace(/,/g, '');
    if (!state.formData.title) return '제목을 입력해주세요';
    if (!state.formData.content) return '내용을 입력 해주세요';

    if (state.hashTagArra.length <= 0) return '태그를 1개 이상 입력 해주세요';
    console.log(state.formData.pointValue);
    if (Number(coins) <= 0) return '코인을 입력 해주세요';
    if (Number(state.formData.personnelCount) <= 0) return '미팅 모집 인원을 입력해주세요';
    if (0) return '';
  };
  //유효성체크
  const isFormDataComplete = computed(() => {
    return state.formData.title && state.formData.content && state.formData.pointValue && Object.keys(state.hashTagArra).length >= 1 && state.formData.start_date && state.formData.meeting_date;
  });

  const handleAddMetting = () => {
    let images = [];
    let coins = state.formData.pointValue.replace(/,/g, '');

    const validationError = validateForm();
    if (validationError) {
      tostAlert(validationError);
      return;
    }
    if (state.formData.youTubeRrl) {
      state.formData.youTubeRrl = extractVideoID(state.formData.youTubeRrl);
    }
    state.formData.hashTag = [...state.hashTagArra];
    if (state.formData.imageArray.length > 0) {
      images = state.formData.imageArray.map(item => item.files);
    }

    const requestData = {
      type: selectTab.communitySelect.type === 'private' ? 'priv' : 'open',
      c_info_id: selectTab.communitySelect.type === 'private' ? selectTab.communitySelect.value.c_info_id : selectTab.communitySelect.id.c_info_id,
      country: meetingData.locationData.country,
      city: meetingData.locationData.city,
      title: state.formData.title,
      content: state.formData.content,
      youtube_url: state.formData.youTubeRrl ? state.formData.youTubeRrl : '',
      keywords: [...state.formData.hashTag],
      per_scoin: coins,
      meeting_person_cnt: 1,
      upload_images: images,
      meeting_date: state.formData.meeting_date,
      start_date: state.formData.start_date,
      end_date: state.formData.end_date,
      latitude: meetingData.locationData.lat,
      longitude: meetingData.locationData.lng,
      ai_use: state.isCheck_1 ? 'true' : 'false',
      status: state.isCheck_2 ? 'true' : 'false',
    };
    console.log(state.formData);
    console.log(selectTab.communitySelect);
    console.log(meetingData.selectData);
    console.log(requestData);
    CommunityApiRequest.addMeetingRequest(requestData).then(res => {
      console.log(res);
      if (res.code === 200) {
        meetingData.resetMeetingFormData();
        confirmPopup.value.handlePopOpen();
        state.isSuccess = true;
      }

      if (res.code === 201) {
        useScoin.insufficientCoin = res.data;
        const setData = {
          ...state,
        };
        meetingData.setMeetingFormData(setData);
        confirmPopup.value.handlePopOpen();
        state.isSuccess = false;
      }
      if (res.code === -200) {
        state.forbiddenWord = res.data.at(0).banned_word;
        usePopup.popups.isWordPopup = true;
      }
    });
  };

  const handleCalenderOpen = (value, isInvalid) => {
    if ((!state.formData.start_date || !state.formData.end_date) && value) {
      usePopup.popups.isDatePicker = false;
      return tostAlert('노출날짜를 먼저 설정 해주세요.');
    }
    state.isRange = value;
    usePopup.popups.isDatePicker = true;
    state.isInvalid = isInvalid;
  };
  //날짜 팝업 닫기
  const dateClose = (form, date, noRange) => {
    if (!noRange) {
      if (!form.start_date || !form.end_date) {
        return; // 함수 종료
      }

      state.formData.start_date = form.start_date;
      state.formData.end_date = form.end_date;

      const formattedStartDate = formatDate(form.start_date, 'yyyy.mm.dd');
      const formattedEndDate = formatDate(form.end_date, 'yyyy.mm.dd');

      // 두 날짜를 하나의 문자열로 결합
      state.dateRange = `${formattedStartDate} ~ ${formattedEndDate}`;
    } else {
      const startDate = new Date(state.formData.start_date);
      const endDate = new Date(state.formData.end_date);
      const exposure = new Date(form.start_date);

      // 시작 날짜나 종료 날짜가 설정되지 않은 경우

      // 노출 일자가 startDate와 endDate 사이이거나 startDate 이전인 경우
      if ((exposure >= startDate && exposure <= endDate) || exposure <= startDate) {
        usePopup.popups.isDatePicker = false;
        state.formData.meeting_date = ''; // meeting_date 초기화
        state.isInvalid = true;
        return tostAlert('선택하신 기간 외에 노출 일자를 선택해주세요.');
      }

      // 조건에 맞는 경우 meeting_date 설정
      state.formData.meeting_date = form.start_date ? formatDate(form.start_date, 'yyyy.mm.dd') : '';
    }
    state.isInvalid = false;
    usePopup.popups.isDatePicker = false;
  };
  //코인충전
  const handleCoinCharge = value => {
    //200 등록
    if (state.isSuccess) {
      if (value) {
      }
      selectTab.reset();
      meetingData.reset();
      router.push('/');
      tostAlert('게시글이 등록되었습니다.');
    } else {
      //200 아니면서
      if (value) {
        usePopup.popups.isChargePopup = true;
      }
    }

    confirmPopup.value.handlePopClose();
    usePopup.popups.isCommonPopup = false;
  };

  const formattedMessage = computed(() => {
    let popMsg;
    if (state.isSuccess) {
      popMsg = `${state.isSelectCommunity} <br />
                 회원에게 미팅요청 알림을<br />
                 전송하시겠습니까?`;
    } else {
      popMsg = `미팅을 위한 Scoin이 부족합니다.<br>
                충전하시겠습니까?`;
    }
    return popMsg;
  });

  onMounted(() => {
    if (Object.keys(meetingData.meetingFormData).length > 0) {
      Object.assign(state, meetingData.meetingFormData);
      state.datePick.range = [state.formData.start_date, state.formData.end_date];
      state.datePick.date = state.formData.meeting_date;
    }
    usePopup.popups.isChargePopup = false;
    console.log(route.query, 'route.query.status');
    if (route.query.status && !usePopup.popups.isChargePopup) {
      if (route.query.status === 'true') {
        tostAlert(route.query.message);
      } else {
        tostAlert(route.query.message);
      }
      const newQuery = { ...route.query };
      delete newQuery.status;
      delete newQuery.message;
      router.replace({ query: newQuery });
    }

    state.myPoint = memberInfo.memberInfo.point;
    console.log(meetingData.address);
    console.log(state.myPoint, 'state.myPoint');

    state.formData.selectArea = `${meetingData.locationData.country} / ${meetingData.locationData.city}`;

    if (selectTab.communitySelect.type === 'open') {
      state.isSelectCommunity = selectTab.communitySelect.value;
    } else if (selectTab.communitySelect.type === 'private') {
      state.isSelectCommunity = selectTab.communitySelect.value.community_name;
    }
  });
  onUnmounted(() => {
    meetingData.setRegLocationData();

    meetingData.isComplete = false;
  });
</script>

<template>
  <div class="meeting_select_wrap">
    <div class="meeting_select_inner">
      <div v-if="!state.isInfoText" class="info_con">
        <button class="close_btn" type="button" @click="state.isInfoText = true">
          <img alt="" src="@/assets/img/close_icon_black.svg" />
        </button>
        <h3 class="info_text">글 작성하기 전에 알려드려요.</h3>
        <p class="info_text_2">거래, 명예훼손, 광고/홍보 글은 올리실 수 없어요.</p>
      </div>
      <div class="content">
        <div class="community_con" @click="handleCategory('comm')">
          <img alt="" class="icons" src="@/assets/img/gray_right_iocn.svg" />
          <span class="select_community_value">{{ state.isSelectCommunity }}</span>
        </div>
        <div class="select_area" @click="handleCategory('location')">
          <img alt="" class="icons" src="@/assets/img/gray_right_iocn.svg" />
          <span class="text">{{ state.formData.selectArea }}</span>
        </div>
        <div class="default_input">
          <input v-model="state.formData.title" class="title_inputs" maxlength="60" placeholder="제목을 작성해 주세요. (최대 60자)" type="text" />
        </div>
        <div class="default_txtAr q-mt-sm">
          <textarea v-model="state.formData.content" class="" maxlength="800" placeholder="내용을 작성해 주세요."></textarea>
          <div class="length_count">
            <span class="count txt-purple">{{ state.formData.content.length }}</span
            ><span class="total_count">/800</span>
          </div>
        </div>
        <!--        <div class="you_tube_input_url">-->
        <!--          <input v-model="state.formData.youTubeRrl" class="you_tube_inputs" placeholder="유튜브 URL를 입력해 주세요.(선택)" type="text" />-->
        <!--        </div>-->
        <div class="hash_tag_con">
          <input v-model="state.hashTag" class="hash_tag_inputs" placeholder="#해시태그 (최대 5개)" type="text" @input="handleSetHashTag" />
        </div>
        <div class="hash_tag_wrap">
          <span v-for="(tag, idx) in state.hashTagArra" :key="idx" class="hash_tag">{{ `#${tag}` }}</span>
        </div>
        <div class="point_wrap q-mb-sm">
          <div class="default_input coin">
            <input v-model="state.formData.pointValue" class="bordered" placeholder="0" type="text" @change="formatPointValue" @input="executorPoint" />
          </div>
        </div>
        <CoinsAvailable />
        <div class="image_con">
          <input ref="imageFileInputs" accept=".webp,jpeg,.jpg,.png" multiple style="display: none" type="file" @change="handleGetFile" />
          <button class="image_add_btn" type="button" @click="handleGetImageFile">
            <img alt="" src="@/assets/img/add_image_plus.svg" />
            <span class="image_count_tag"
              ><span class="image_count">{{ state.formData.imageArray.length }}</span
              >/10</span
            >
          </button>
          <div class="image_array_con">
            <div v-for="(imageFile, idx) in state.formData.imageArray" :key="idx" class="image_con">
              <button class="del_btn" type="button" @click="handleDelImage(idx)">
                <img alt="" class="icon" src="@/assets/img/del_icons.svg" />
              </button>
              <img :src="imageFile.imageUrl" alt="" class="add_image" />
            </div>
          </div>
        </div>
        <div class="meeting_date">
          <h3 class="title">노출 기간</h3>
          <div class="select_date" @click="handleCalenderOpen(false, false)">
            <img alt="" src="@/assets/img/select_bottom_arrow_icon.svg" />
            <span :class="[{ select: state.dateRange }, 'label']">{{ state.dateRange ? state.dateRange : 'YYYY.MM.DD ~ YYYY.MM.DD' }}</span>
          </div>
        </div>
        <div class="meeting_date">
          <h3 class="title">선정 날짜</h3>
          <div class="select_date" @click="handleCalenderOpen(true, state.isInvalid)">
            <img alt="" src="@/assets/img/select_bottom_arrow_icon.svg" />
            <span :class="[{ select: state.formData.meeting_date }, 'label']">{{ state.formData.meeting_date ? state.formData.meeting_date : 'YYYY.MM.DD' }}</span>
          </div>
        </div>
        <div class="check_list_con">
          <ul class="check_list">
            <li class="check_list_item" style="display: none" @click="handleCheck(0)">
              <span class="check_icon">
                <img v-if="!state.isCheck_1" alt="" src="@/assets/img/check_icon_off.svg" />
                <img v-if="state.isCheck_1" alt="" src="@/assets/img/check_icon_on.svg" />
              </span>
              <span class="check_text">Seemple AI 답변 받기</span>
            </li>
            <li class="check_list_item" style="display: none" @click="handleCheck(1)">
              <span class="check_icon">
                <img v-if="!state.isCheck_2" alt="" src="@/assets/img/check_icon_off.svg" />
                <img v-if="state.isCheck_2" alt="" src="@/assets/img/check_icon_on.svg" />
              </span>
              <span class="check_text">나만 보기</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="btm_btn fixed_btn">
    <button :disabled="!isFormDataComplete" class="default_btn" type="button" @click="handleAddMetting">완료</button>
  </div>
  <!-- 달력 팝업 -->
  <DateRangePopup :dateSet="state.datePick" :isInvalid="state.isInvalid" :noRange="state.isRange" dateType="startDate" @dateClose="dateClose" />
  <!-- 코인 충전 팜 확인 팝업 -->
  <PopUpVue ref="confirmPopup">
    <div class="pop_alert_con v2">
      <h2 class="title" v-html="formattedMessage"></h2>
    </div>
    <div class="btm_btn">
      <button class="default_btn medium gray" type="button" @click="handleCoinCharge(false)">취소</button>
      <button class="default_btn medium" type="button" @click="handleCoinCharge(true)">
        {{ state.isSuccess ? '확인' : '충전하러 가기' }}
      </button>
    </div>
  </PopUpVue>
  <!-- 충전 팝업 -->
  <ChargingPointPopup v-if="usePopup.popups.isChargePopup" type="meeting_register" @close="usePopup.popups.isChargePopup = false" />

  <!-- 금칙어 팝업 -->
  <ForbiddenWordPopup v-if="usePopup.popups.isWordPopup" :forbiddenWord="state.forbiddenWord" @close="usePopup.popups.isWordPopup = false" />
</template>

<style lang="scss" scoped>
  .submit_con {
    padding: 16px 16px;
    margin-top: 12px;

    .submit_btn {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      background: #6728ff;
      color: #fff;
      text-align: center;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
    }
  }

  .meeting_select_wrap {
    .meeting_select_inner {
      padding: 0 16px 100px;

      .info_con {
        padding: 16px;
        position: relative;
        height: 82px;
        border-radius: 10px;
        background: #f7f3ff;

        .close_btn {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 16px;
          height: 16px;

          img {
            width: 100%;
          }
        }

        .info_text {
          color: #6728ff;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.48px;
          margin-bottom: 4px;
        }

        .info_text_2 {
          color: #515561;
          font-family: 'Spoqa Han Sans Neo';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.42px;
        }
      }

      .content {
        .meeting_date {
          margin-top: 32px;
          margin-bottom: 56px;

          .title {
            color: #000;
            font-family: Spoqa Han Sans Neo;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: -0.18px;
            margin-bottom: 22px;
          }

          .select_date {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row-reverse;
            width: 100%;
            border-bottom: solid 1px #f3f4f7;
            padding-bottom: 14px;

            .label {
              flex-grow: 1;
              color: #9b9ea9;
              font-family: Spoqa Han Sans Neo;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;

              &.select {
                color: $black;
              }
            }
          }
        }

        margin-top: 16px;

        .community_con {
          height: 50px;
          position: relative;
          border-bottom: solid 1px #f3f4f7;

          .icons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
          }

          .select_community_value {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: -0.42px;
          }
        }

        .select_area {
          height: 50px;
          position: relative;
          border-bottom: solid 1px #f3f4f7;

          .icons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
          }

          .text {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: -0.42px;
          }
        }

        .title_con {
          margin-top: 8px;

          .title_inputs {
            border: none;
            width: 100%;
            appearance: none;
            height: 50px;
            border-bottom: solid 1px #f3f4f7;
            outline: none;
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;

            &::placeholder {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }
          }
        }

        .content_text_con {
          margin-bottom: 52px;

          .content_text_inputs {
            height: 256px;
            width: 100%;
            border: none;
            outline: none;
            appearance: none;
            padding: 14px 0;
            border-bottom: solid 1px #f3f4f7;
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;

            &::placeholder {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }
          }

          .count_con {
            padding-top: 8px;
            text-align: right;

            .count {
              color: #6728ff;
              text-align: right;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.36px;
            }

            .total_count {
              color: #9b9ea9;
              text-align: right;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.36px;
            }
          }
        }

        .you_tube_input_url {
          margin-bottom: 8px;

          .you_tube_inputs {
            border: none;
            width: 100%;
            border-radius: 10px;
            border: solid 1px #f3f4f7;
            height: 50px;
            padding-left: 14px;
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;

            &::placeholder {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }
          }
        }

        .hash_tag_con {
          .hash_tag_inputs {
            padding-left: 14px;
            border: none;
            appearance: none;
            outline: none;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            border: solid 1px #f3f4f7;
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: -0.42px;

            &::placeholder {
              color: #9b9ea9;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.42px;
            }

            &:focus {
              border-color: #6728ff;
            }
          }
        }

        .hash_tag_wrap {
          .hash_tag {
            display: inline-block;
            margin-top: 8px;
            padding: 6px 8px;
            border-radius: 10px;
            background: #f7f3ff;
            color: #6728ff;
            text-align: center;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.36px;
            margin-left: 6px;
          }
        }

        .point_wrap {
          margin-top: 24px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          gap: 8px;

          .point_con {
            display: flex;
            justify-content: space-between;
            align-content: center;
            border-radius: 10px;
            border: solid 1px #f3f4f7;
            height: 100%;
            padding: 0 14px;
            gap: 12px;

            label {
              color: #9b9ea9;
              text-align: right;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 50px;
              letter-spacing: -0.42px;
            }

            .point_inputs {
              border: none;
              outline: none;
              appearance: none;
              height: 100%;
              width: 80%;
              flex-wrap: wrap;
            }
          }

          .personnel_con {
            padding: 0 14px;
            border-radius: 10px;
            border: solid 1px #f3f4f7;
            height: 100%;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            align-content: center;

            .personnel_inputs {
              width: 50%;
              flex-wrap: wrap;
              border: none;
              outline: none;
              appearance: none;
              height: 100%;
              flex-grow: 1;
            }

            label {
              color: #9b9ea9;
              text-align: right;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 50px;
              letter-spacing: -0.42px;
            }
          }
        }

        .my_point_con {
          margin-top: 8px;

          .point_tag {
            .point_icons {
              vertical-align: middle;
            }

            .info_text {
              margin-left: 2px;
              color: #6728ff;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.36px;
            }
          }

          .info_text {
            margin-top: 8px !important;
            color: #9b9ea9;
            font-family: Spoqa Han Sans Neo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.36px;
          }
        }

        .image_con {
          margin-top: 24px;
          display: flex;
          justify-content: flex-start;
          overflow: hidden;
          //align-content: center;
          .image_add_btn {
            width: 58px;
            flex-basis: 58px;
            height: 58px;
            margin-right: 6px;
            border-radius: 16px;
            background: #f3f4f7;

            .image_count_tag {
              display: inline-block;
              width: 100%;
              color: #515561;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.3px;

              .image_count {
                color: #6728ff;
                text-align: center;
                font-family: 'Spoqa Han Sans Neo';
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.3px;
              }
            }
          }

          .image_array_con {
            width: calc(100% - 66px);
            flex-basis: calc(100% - 66px);
            display: flex;
            justify-content: flex-start;
            //align-content: center;
            gap: 6px;
            flex-wrap: wrap;

            .image_con {
              margin-top: 0;
              position: relative;
              width: 58px;
              height: 58px;
              border-radius: 16px;
              border: solid 1px #f3f4f7;

              &:not(:last-child) {
                margin-right: 6px;
              }

              .add_image {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
              }

              .del_btn {
                position: absolute;
                top: 6px;
                right: 6px;

                .icon {
                }
              }
            }
          }
        }

        .check_list_con {
          margin-top: 24px;

          .check_list {
          }

          .check_list_item {
            cursor: pointer;

            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }

          .check_icon {
            margin-right: 8px;
          }

          .check_text {
            color: #000;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.42px;
          }
        }
      }
    }
  }
  .length_count {
    margin-top: 8px;
    margin-bottom: 52px;
  }
</style>
