<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content }}
      <!--            {{ content ? `${content} | ${siteName}` : siteName }}-->
    </template>
  </metainfo>
  <router-view />
</template>

<script setup>
  import { useMeta } from 'vue-meta';
  import { useQuasar } from 'quasar';
  import { onMounted } from 'vue';

  useMeta({
    title: '',
    htmlAttrs: { lang: 'ko', amp: true },
  });

  const $q = useQuasar();
  window.tostAlert = message => {
    if (message) {
      $q.notify({
        message: message,
        timeout: 2000,
        classes: 'my_notice',
      });
    } else {
      $q.notify({
        message: '알림 메세지를 입력 해주세요.',
        timeout: 2000,
        classes: 'my_notice',
      });
    }
  };
  onMounted(() => {});
</script>
<style lang="scss">
  @import '@/assets/scss/main.scss';
  .pac-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    position: unset !important;
    border: none !important;
    box-shadow: none !important;
    z-index: 100 !important;
    .pac-item {
      border: 0 !important;
      padding: 12px 8px;
      &:hover {
        background-color: white !important;
      }
      span {
        color: $blue-g40;
        font: $text-caption-400;
        letter-spacing: -0.36px;
      }
      .pac-icon {
        background: url($icon-g30-location) no-repeat center / contain;
        margin: 6px 0 0 0;
        width: 16px;
        height: 16px;
      }
      .pac-item-query {
        font: $text-body14-400;
        color: $black;
        padding: 0;
        margin: 0 8px;
        .pac-matched {
          font: inherit;
          color: $black;
          font-weight: 700;
        }
      }
    }
  }
</style>
