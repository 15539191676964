import voteApi from '@/api/active/voteApi';

export class voteApiRequest {
  //투표 등록
  static async updateVoteRequest(param) {
    try {
      const data = await voteApi.updateVote(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  //위치기반 투표,라이브샷,미팅
  static async checkRealTimeLocation(param) {
    try {
      const data = await voteApi.checkRealTimeLocation(param);
      return data;
    } catch (err) {
      throw err;
    }
  }

  //투표 참여
  static async registerVote(param) {
    try {
      const data = await voteApi.registerVote(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
  //투표 참여 이후 결과
  static async getVoteResult(param) {
    try {
      const data = await voteApi.getVoteResult(param);
      return data;
    } catch (err) {
      throw err;
    }
  }
}
