import { acceptHMRUpdate, defineStore } from 'pinia';

export const useTranstionStore = defineStore('useTranstion', {
  state: () => {
    return {
      transitionName: null,
    };
  },
  actions: {
    setTransitionName(data) {
      this.transitionName = data;
    },
  },
  getters: {},
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useTranstionStore, import.meta.hot));
