<script setup>
  import { Sheet } from 'bottom-sheet-vue3';
  import bottomeSheetStyled from '@/styled/bottomeSheetStyled';
  import { nextTick, onMounted, reactive, watch } from 'vue';
  import { usePopupStore } from '@/stores/usePopupStore';
  import useClipboard from 'vue-clipboard3';

  import { useMeta } from 'vue-meta';
  import InvitationMsgPopup from '@Components/popup/InvitationMsgPopup.vue';
  import { useMyOperateStore } from '@/stores/useMyOperateStore';
  import operatorApi from '@/api/operator/operatorApi';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';

  useMeta({
    meta: [
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: 'wlwlwlwl',
      },
      {
        property: 'og:description',
        content: '나의 어쩌구 컵뮤',
      },
      {
        property: 'og:image',
        content: 'https://seemple.s3.ap-northeast-2.amazonaws.com/open_community/travel.jpg',
      },
    ],
  });
  const { toClipboard } = useClipboard();
  const usePopup = usePopupStore();
  const useMyOperate = useMyOperateStore();
  const useAddCommunity = useAddCommunityStore();
  const props = defineProps({
    id: String,
    type: String,
    shareData: Object,
  });

  const emit = defineEmits(['close']);
  const state = reactive({
    visible: false,
    snsData: {},
    snsType: '',
    code: '',
  });
  const getInvitationCode = async () => {
    const res = await operatorApi.sendInvitationCode({ comm_info_id: useAddCommunity.commId });
    if (res.code === 200) {
      state.code = res.data.private_code;
    }
  };
  const handleKakaoShare = async type => {
    let requestData;
    if (type === 'invite') {
      await getInvitationCode();
      requestData = {
        title: '프라이빗 커뮤니티 입장코드',
        sharetxt: ` Seemple ${useMyOperate.myCommInfo.name}에 초대합니다. 심플에 가입하셔서 프라이빗 입장코드 '${state.code}' 를 입력해주세요`,
        url: `${import.meta.env.VITE_PUBLIC_BASE_URL}/add/AddCommunityVue?kind=app`,
      };
      usePopup.setSnsDate(requestData);
    } else {
      requestData = {
        title: `${usePopup.snsData.title}`,
        url: `${usePopup.snsData.url}`,
      };
    }
    if (navigator.userAgent.toLowerCase().indexOf('androidapp') !== -1 || navigator.userAgent.toLowerCase().indexOf('iosapp') !== -1) {
      //앱으로 사용중인경우
      setTimeout(function () {
        webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            action: 'share_up',
            sharetitle: `${requestData.title}`, //옵션으로 공유대상 어플에 따라 표시 안될수도 있음.
            url: `${requestData.url ? requestData.url : ''}`, //옵션으로 공유대상 어플에 따라 사용안될 수도 있음.(아래 sharetxt에 url을 넣는것이 좋음, 사용안할시 값을 비우거나 url변수를 제거함)
            sharetxt: `${requestData.sharetxt ? requestData.sharetxt : ''}`,
          }),
        );
      }, 100);
    } else {
      console.log(requestData, 'title');
      //웹으로 접속한경우
      Kakao.Share.sendDefault({
        objectType: 'text',
        text: requestData.sharetxt ? requestData.title + requestData.sharetxt : requestData.title,
        link: {
          mobileWebUrl: requestData.url ? requestData.url : '',
          webUrl: requestData.url ? requestData.url : '',
        },
      });
    }

    close();
  };
  const handleFacebookShare = () => {
    window.open(`${usePopup.snsData.url}`);
  };
  const handleUrlCopy = () => {
    toClipboard(`${usePopup.snsData.url}`);
    close();
    tostAlert('URL이 복사되었습니다.');
  };
  const handlePostMessage = () => {
    usePopup.popups.isInvitationSms = true;
    usePopup.popups.isInvite = false;
  };
  const close = () => {
    emit('close');
  };

  const native_share = () => {};

  // 카카오톡 공유, URL복사하기 팝업 호출하지 않고 바로 앱공유하기 호출처리
  watch(
    () => usePopup.popups.isSharePopup,
    (newVal, oldVal) => {
      if (newVal === true) {
        handleKakaoShare(props.type);
      }
    },
  );
</script>

<template>
  <Sheet v-model:visible="usePopup.popups.isInvite" class="custom-bottom-sheet">
    <div class="bottom_sheet_wrap">
      <div class="bottom_sheet_inner">
        <bottomeSheetStyled>
          <div class="edit_wrap v2">
            <h5 class="pop_title">{{ props.type === 'invite' ? '초대하기' : '공유하기' }}</h5>
            <ul class="share_list">
              <li>
                <button id="kakaotalk-sharing-btn" class="kakao" type="button" @click="handleKakaoShare(props.type)">카카오톡</button>
              </li>
              <!--              <li v-if="props.type !=='invite'">-->
              <!--                <button type="button" class="facebook" @click="handleFacebookShare">페이스북</button>-->
              <!--              </li>-->
              <li v-if="props.type !== 'invite'">
                <button class="urlCopy" type="button" @click="handleUrlCopy">URL 복사</button>
              </li>
              <li v-if="props.type === 'invite'">
                <button class="msg" type="button" @click="handlePostMessage">메세지</button>
              </li>
            </ul>
          </div>
        </bottomeSheetStyled>
      </div>
    </div>
  </Sheet>

  <!-- 메세지 전송 팝업 -->
  <InvitationMsgPopup v-if="usePopup.popups.isInvitationSms" />
</template>

<style lang="scss" scoped></style>
