import { acceptHMRUpdate, defineStore } from 'pinia';
import { deleteAlarmList, getAlarmList } from '@/api/alarm/alarmApi';

export const useMyOperateStore = defineStore('useMyOperate', {
  state: () => {
    return {
      myCommInfo: [],
      commStatus: '',
    };
  },

  getters: {},
  actions: {
    setMyCommInfo(data) {
      this.myCommInfo = data;
    },
    setCommStatus(type) {
      this.myCommInfo.status = type;
    },
  },
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useMyOperateStore, import.meta.hot));
