import { acceptHMRUpdate, defineStore } from 'pinia';

export const useVoteStore = defineStore('useVote', {
  state: () => {
    return {
      stepVote: false,
      pageTitle: '투표할 대상자 지역을 설정하세요.',
      voteFormData: {}, // 등록 페이지 데이터
      votePostData: {}, //상세 페이지 데이터
      vote_item: [],
      isParticipation: false, //투표 참여 / 미참여
      voteRequestData: {
        address: {
          value: null,
          siValue: null,
          gunValue: null,
          guValue: null,
        },
      },
    };
  },
  actions: {
    setVoteFormData(data) {
      this.voteFormData = data;
    },
    setVotePostData(data, participation) {
      this.votePostData = data;
      this.isParticipation = participation;
      console.log(participation, 'participation');
    },
    setVoteItems(items) {
      this.votePostData = {
        ...this.votePostData,
        vote_items: items,
      };
    },
    setUpdateOneVote(data) {
      this.stepVote = data;
    },
    setUpdateTitle(data) {
      this.pageTitle = data;
    },
    setVoteRequestData(type, value) {
      if (type === 'address') {
        this.voteRequestData.address.value = value;
      } else if (type === 'si') {
        this.voteRequestData.address.siValue = value;
      } else if (type === 'gun') {
        this.voteRequestData.address.gunValue = value;
      } else if (type === 'gu') {
        this.voteRequestData.address.guValue = value;
      }
    },
    reset() {
      this.stepVote = false;
      this.pageTitle = '미팅 요청 할 대상자 지역을 설정하세요.';
      this.voteRequestData.address.value = null;
    },
    clearLocalstorage() {
      this.voteFormData = {};
      localStorage.removeItem('useVote');
    },
  },
  getters: {},
  persist: true,
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useVoteStore, import.meta.hot));
