<script>
  import ImagePopVueStyled from '@/styled/imagePopVueStyled';

  export default {
    components: {
      ImagePopVueStyled,
    },
  };
</script>
<script setup>
  import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { useSendBirdStore } from '@/stores/useSendBirdStore';
  import { sendbirdApi } from '@/api/sendbirdApi';
  import { useUserInfoStore } from '@/stores/useUserInfoStore';
  import { Navigation } from 'swiper/modules';
  const emits = defineEmits(['closePop']);
  const useSendBird = useSendBirdStore();
  const userInfo = useUserInfoStore();
  const props = defineProps({
    imgList: Array,
    activeIdx: Number,
  });
  const state = reactive({
    popOpen: false,
    imageList: [],
    swiperOption: {
      slidesPerView: '1',
      navigation: true,
    },

    swiper: null,
  });
  const modules = [Navigation];
  const handlePopOpenFuc = data => {
    state.imageList = [];
    state.popOpen = true;
    state.imageList = data;
    // state.imageList.reverse();
    console.log('=>(ImagePopVue.vue:30) state.imageList', state.imageList);
  };
  const handleOnSwiper = swiper => {
    state.swiper = swiper;
    swiper.slideTo(props.activeIdx);
  };
  const handleClosPop = () => {
    state.popOpen = false;
    emits('closePop');
  };

  onMounted(() => {
    console.log(props.imgList);
    if (props.imgList && props.imgList.length > 0) {
      state.popOpen = true;
      state.imageList = [...props.imgList];
    }
  });
  onUnmounted(() => {
    console.log('@@@@@@@@@@@@');
    state.imageList = [];
  });

  defineExpose({ handlePopOpenFuc });
</script>

<template>
  <ImagePopVueStyled :showProps="{ show: state.popOpen, length: state.imageList ? state.imageList.length : 1 }">
    <button class="close_pop_btn" type="button" @click="handleClosPop">
      <img alt="" src="@/assets/img/close_image_pop_icon.svg" />
    </button>
    <div class="image_con">
      <Swiper :modules="modules" class="container" v-bind="state.swiperOption" @slideChange="onSlideChange" @swiper="handleOnSwiper">
        <swiper-slide v-for="(image, idx) in state.imageList" :key="idx" class="image_array">
          <img v-if="image.file_path ? image.file_path : image.url" :src="image.file_path ? image.file_path : image.url" alt="" />
        </swiper-slide>
      </Swiper>
    </div>
  </ImagePopVueStyled>
</template>

<style lang="scss" scoped>
  .image_con {
    &:deep {
      .swiper-slide {
        align-self: center;
      }
      .swiper-button-next,
      .swiper-button-prev {
        cursor: pointer;
        width: 24px;
        height: 24px;

        &:after {
          opacity: 0.5;
          @include combIcon($size: 24px, $url: $black-rArrow);
        }
      }
      .swiper-button-next {
        &:after {
          rotate: 180deg;
        }
      }
    }
  }
</style>
