import styled from 'vue3-styled-components';

const commentAddStyled = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  max-width: 500px;
  background: #fff;
  z-index: 1001;
  .comment_add_wrap {
    padding: 0 16px;
    .comment_inputs {
      border: none;
      width: 100%;
      min-height: 300px;
      resize: none;
      appearance: none;
      outline: none;
      color: #000;
      font-family: Spoqa Han Sans Neo, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
      &::placeholder {
        color: #9b9ea9;
        font-family: Spoqa Han Sans Neo, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.42px;
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }
  .submit_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    .submit_btn {
      width: 100%;
      height: 55px;
      border-radius: 10px;
      background: #dcceff;
      text-align: center;
      font-family: Spoqa Han Sans Neo, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
      color: #fff;

      &.add {
        background: #6728ff;
      }
    }
  }
`;

export default commentAddStyled;
