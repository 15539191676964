import styled from 'vue3-styled-components';
const defaultPopupStyles = styled.div`
  position: fixed;
  width: 100%;
  max-width: 500px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 10000;

  .header_wrap {
    z-index: 100000;
    .title {
      .close_btn {
        height: 24px;
      }
    }
    & + div {
      overflow: auto;
      height: 100%;
    }
  }
  [class*='_section'] {
    height: 100%;
    h5 {
      padding-top: 16px;
      font: 700 18px/26px 'Spoqa Han Sans Neo';
      color: #000;
      letter-spacing: -0.18px;
      strong {
        color: #6728ff;
      }
    }
    .inner {
      padding: 68px 16px 142px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      overflow: auto;
    }

    .category_list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        border: 1px solid #f3f4f7;
        background-color: #fff;
        color: #000;
        border-radius: 10px;
        padding: 16px;
        font: 500 16px/24px 'Spoqa Han Sans Neo';
        letter-spacing: -0.48px;
        &.active {
          background-color: #f7f3ff;
          border-color: #f7f3ff;
          color: #6728ff;
        }
      }
    }
  }

  .popup_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    background-color: #fff;
  }
`;

export default defaultPopupStyles;
