<script>
  import declarationListStyled from '@/styled/declarationListStyled';

  export default {
    components: {
      declarationListStyled,
    },
  };
</script>
<script setup>
  import { onMounted, onUnmounted, reactive, watch } from 'vue';
  import { useLoadingStore } from '@/stores/useLoadingStore';
  import { CommunityApiRequest } from '@/api/community/communityApiRequest';
  import { useRoute } from 'vue-router';
  import chatApi from '@/api/chat/chatApi';
  import { useAddCommunityStore } from '@/stores/useAddCommunityStore';

  const route = useRoute();
  const loading = useLoadingStore();
  const selectTab = useAddCommunityStore();
  const state = reactive({
    isPop: false,
    declarationList: [
      { select: false, value: '저작권 침해' },
      { select: false, value: '혐오 사진 또는 발언' },
      { select: false, value: '욕설 또는 비하' },
      { select: false, value: '성희롱' },
      { select: false, value: '괴롭힘' },
      { select: false, value: '스팸' },
      { select: false, value: '기타' },
    ],
    infoData: null,
    getDeclarationVal: null,
    isType: '',
  });
  const emits = defineEmits(['reportPost']);

  //팝업 열기
  const handlePopOpen = data => {
    console.log(data, 'handlePopOpen data');
    state.infoData = data;
    state.isPop = !state.isPop;
  };
  const handleGetDeclaration = (value, idx) => {
    state.getDeclarationVal = value.value;
    state.declarationList.forEach(item => {
      item.select = false;
    });
    state.declarationList.at(idx).select = true;
  };
  const handleSubmitReport = () => {
    //채팅
    if (state.isType === 'CHAT') {
      if (!state.getDeclarationVal) {
        tostAlert('신고 항목을 선택해 주세요.');
        return;
      }

      const requestData = {
        report_user_info_id: state.infoData, // 신고 대상자 회원 고유번호
        report_category: state.getDeclarationVal, // 신고 항목
        comm_info_id: selectTab.communitySelect.id.c_info_id, // 신고 항목
      };
      chatApi.submitChatReport(requestData).then(res => {
        console.log('채팅 신고', res);
        if (res.code === 200) {
          tostAlert('신고가 완료되었습니다.');
        } else {
          tostAlert(res.message);
        }
      });
      state.isPop = false;
      state.infoData = null;
      return;
    }
    let communityType = '';
    console.log('=>(DeclarationListVue.vue:54) route.params', route.params);
    if (route.params.hasOwnProperty('openContent')) {
      communityType = 'open';
    } else if (route.params.hasOwnProperty('postContent')) {
      communityType = 'private';
    }

    //댓글신고
    if (state.isType !== 'POST') {
      if (!state.getDeclarationVal) {
        tostAlert('신고 항목을 선택해 주세요.');
        return;
      }
      console.log('=>(DeclarationListVue.vue:24) state.infoData', state.infoData);
      const requestData = {
        type: communityType, // 오픈, 프라이빗 구분 (open, private)
        c_comment_info_id: state.infoData.commentInfo.commentInfo.comment_info_id, // 댓글 고유번호 (통합 oc_comment_info_id, pc_comment_info_id)
        report_user_info_id: state.infoData.commentInfo.commentInfo.user_info_id, // 신고 대상자 회원 고유번호
        report_category: state.getDeclarationVal, // 신고 항목
      };
      console.log('=>(DeclarationListVue.vue:51) requestData', requestData);
      CommunityApiRequest.privateReportCommentRequest(requestData).then(res => {
        console.log('=>(DeclarationListVue.vue:45) res', res);
        if (res.code === 200) {
          tostAlert('신고처리 되었습니다.');
        } else {
          tostAlert(res.message);
        }
        state.isPop = false;
        state.infoData = null;
      });
      // 게시글 신고
    } else {
      if (!state.getDeclarationVal) {
        tostAlert('신고 항목을 선택해 주세요.');
        return;
      }
      console.log('=>(DeclarationListVue.vue:76) state.infoData', state.infoData);
      emits('reportPost', state.getDeclarationVal);
      state.isPop = false;
      state.infoData = null;
    }
  };
  // 게시글 신고 팝업
  const handlePostPopOpen = data => {
    state.isPop = !state.isPop;
    state.isType = data.type;
    state.infoData = data.data;
  };
  const handlePostPopClose = () => {
    state.isPop = false;
  };
  defineExpose({ handlePopOpen, handlePostPopOpen, handlePostPopClose });
  watch(
    () => state.isPop,
    isPop => {
      if (isPop) {
        const naviEl = document.getElementById('navigator_wrap');
        if (naviEl) {
          naviEl.style.display = 'none';
        }
      } else {
        const naviEl = document.getElementById('navigator_wrap');
        if (naviEl) {
          naviEl.style.display = 'block';
        }
      }
    },
  );
</script>

<template>
  <declarationListStyled :showPopProps="{ showPop: state.isPop }">
    <div class="pop_head">
      <button class="close_btn" type="button" @click="state.isPop = false">
        <img alt="" src="@/assets/img/close_icon_black.svg" />
      </button>
    </div>
    <div class="container_inner">
      <h3 class="sub_title">신고하려는 이유를 알려주세요.</h3>
      <ul class="declaration_list">
        <li v-for="(value, idx) in state.declarationList" :key="idx" class="declaration_item">
          <button :class="{ select: value.select }" class="declaration_item_btn" type="button" @click="handleGetDeclaration(value, idx)">{{ value.value }}</button>
        </li>
      </ul>
    </div>
    <div class="btm_btn fixed_btn">
      <button class="default_btn" type="button" @click="handleSubmitReport">신고 제출</button>
    </div>
  </declarationListStyled>
</template>
