<script setup>
  const props = defineProps({
    title: String,
  });
  const emit = defineEmits(['close']);
  const handleClose = () => {
    console.log('dddd');
    emit('close', false);
  };
</script>

<template>
  <div class="header_wrap">
    <h3 class="title">{{ props.title }}</h3>
    <button class="close_btn" type="button" @click="handleClose">
      <img alt="" src="@/assets/img/close_icon_black.svg" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    // .header_wrap
    &_wrap {
      position: fixed;
      top:0;
      left:50%;
      transform:translateX(-50%);
      z-index: 101;
      width: 100%;
      max-width: 500px;
      height: 60px;
      background: $white;

      display: flex;
      align-items: center;justify-content: space-between;
      padding: 0 16px;

    }
  }

  .title {
    color: #000;
    text-align: center;
    font:$subtitle3;
    line-height:60px;
    letter-spacing: -0.48px;
    position: absolute;
    left:50%;transform:translateX(-50%);
  }

  .close {
    // .close_btn
    &_btn {
width: 24px;
      height: 24px;
      margin-left:auto;
    }
  }
</style>
